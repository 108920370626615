import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import YesNoSwitch from '../YesNoSwitch'
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { NavLink } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewUser extends Component {

    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            open: false,
            username: '',
            password: '',
            is_admin: 0,
            stores_permission: false,
            branches_permission: false,
            products_permission: false,
            sales_permission: false,
            orders_permission: false,
            open: false,
            stores: null,
            store_selected: null,
            someChanges: false,
            message: ''
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeUsername = this.onChangeUsername.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onChangeIsAdmin = this.onChangeIsAdmin.bind(this)
        this.onChangeStoresPermission = this.onChangeStoresPermission.bind(this)
        this.onChangeBranchesPermission = this.onChangeBranchesPermission.bind(this)
        this.onChangeProductsPermission = this.onChangeProductsPermission.bind(this)
        this.onChangeSalesPermission = this.onChangeSalesPermission.bind(this)
        this.onChangeOrdersPermission = this.onChangeOrdersPermission.bind(this)
        this.onChangeStore = this.onChangeStore.bind(this)
    }

    componentWillMount() {
        trackPromise(
            axios.get(`${this.context.hubURL}/stores`, { headers: { 'Access-Control-Allow-Origin': '*', } })
                .then(res => { this.setStores(res.data) })
                .catch(function (error) { console.log(error); })
        );
    }

    setStores(stores) {
        if (this.props.userId !== undefined) {
            trackPromise(
                axios.get(`${this.context.hubURL}/backoffice/users/${this.props.userId}`, {
                    headers: { 'Access-Control-Allow-Origin': '*' }
                })
                .then(res => {
                    this.setState({ 
                        id: res.data.id,
                        username: res.data.user,
                        password: res.data.alias,
                        is_admin: res.data.is_admin,
                        stores_permission: res.data.stores_permission === "1",
                        branches_permission: res.data.branches_permission === "1",
                        products_permission: res.data.products_permission === "1",
                        sales_permission: res.data.sales_permission === "1",
                        orders_permission: res.data.orders_permission === "1",
                        stores: stores,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                })
            );
        } else {
            this.setState({ stores: stores, open: false });
        }
    }

    onChangeUsername(e) {
        this.setState({ username: e.target.value, someChanges: true })
    }

    onChangePassword(e) {
        this.setState({ password: e.target.value, someChanges: true })
    }

    onChangeIsAdmin(e) {
        let v = e ? 1 : 0
        this.setState({ is_admin: v, someChanges: true })
    }

    onChangeStoresPermission(e) {
        let v = e ? 1 : 0
        this.setState({ stores_permission: v, someChanges: true })
    }

    onChangeBranchesPermission(e) {
        let v = e ? 1 : 0
        this.setState({ branches_permission: v, someChanges: true })
    }

    onChangeProductsPermission(e) {
        let v = e ? 1 : 0
        this.setState({ products_permission: v, someChanges: true })
    }

    onChangeSalesPermission(e) {
        let v = e ? 1 : 0
        this.setState({ sales_permission: v, someChanges: true })
    }

    onChangeOrdersPermission(e) {
        let v = e ? 1 : 0
        this.setState({ orders_permission: v, someChanges: true })
    }

    onChangeStore(e) {
        this.setState({ store_selected: e.target.value, someChanges: true })
    }

    onFormSubmitted(e) {
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        } else {

            const obj = {
                username: this.state.username,
                password: this.state.password
            };

            if (this.state.sales_permission === true) {
                obj["sales_permission"] = "1";
            } else if (this.state.sales_permission === false) {
                obj["sales_permission"] = "0";
            } else {
                obj["sales_permission"] = this.state.sales_permission.toString();
            }

            if (this.state.stores_permission === true) {
                obj["stores_permission"] = "1";
            } else if (this.state.stores_permission === false) {
                obj["stores_permission"] = "0";
            } else {
                obj["stores_permission"] = this.state.stores_permission.toString();
            }

            if (this.state.branches_permission === true) {
                obj["branches_permission"] = "1";
            } else if (this.state.branches_permission === false) {
                obj["branches_permission"] = "0";
            } else {
                obj["branches_permission"] = this.state.branches_permission.toString();
            }

            if (this.state.products_permission === true) {
                obj["products_permission"] = "1";
            } else if (this.state.products_permission === false) {
                obj["products_permission"] = "0";
            } else {
                obj["products_permission"] = this.state.products_permission.toString();
            }

            if (this.state.orders_permission === true) {
                obj["orders_permission"] = "1";
            } else if (this.state.orders_permission === false) {
                obj["orders_permission"] = "0";
            } else {
                obj["orders_permission"] = this.state.orders_permission.toString();
            }

            if (this.state.is_admin === true) {
                obj["is_admin"] = "1";
            } else if (this.state.is_admin === false) {
                obj["is_admin"] = "0";
            } else {
                obj["is_admin"] = this.state.is_admin.toString();
            }

            if(this.props.storeId !== undefined) {
                obj["store_id"] = parseInt(this.props.storeId, 10)
            }
    
            if(this.props.branchId !== undefined) {
                obj["branch_id"] = parseInt(this.props.branchId, 10)
            }
    
            if (this.state.store_selected !== null) {
                obj["store_id"] = parseInt(this.state.store_selected, 10)
            }

            var url = "new"
            var msg = "Usuario creado!"
            if (this.props.userId !== undefined) {
                url = parseInt(this.props.userId, 10)
                var msg = "Usuario actualizado!"
            }

            trackPromise(
                axios.post(`${this.context.hubURL}/backoffice/users/${url}`, obj)
                    .then((res) => {
                        this.setState({ open: true, message: msg })
                    }).catch((error) => {
                        console.log(error)
                    })
            );
        }
    }

    render() {
        let admin, stores, branches, orders, products, sales
        if (this.props.admin) {
            admin = "block"
        } else {
            admin = "none"
        }

        if (this.props.stores) {
            stores = "block"
        } else {
            stores = "none"
        }

        if (this.props.branches) {
            branches = "block"
        } else {
            branches = "none"
        }

        if (this.props.orders) {
            orders = "block"
        } else {
            orders = "none"
        }

        if (this.props.products) {
            products = "block"
        } else {
            products = "none"
        }

        if (this.props.sales) {
            sales = "block"
        } else {
            sales = "none"
        }

        let storesSelect
        let classN = "form-group col-md-4"
        if (this.state.stores == null) {
            storesSelect = ""
        } else {
            storesSelect = <div className={classN}>
                <label for="selectStore">Empresa</label>
                <select id="selectStore" className="form-control" value={this.state.store_selected} onChange={this.onChangeStore}>
                    { this.state.stores.map((store) => (
                        <option value={store.id}>{store.name}</option>
                    )) 
                    }
                </select>
            </div>
        }

        console.log(this.props.storeId);
        if(this.props.storeId !== undefined) {
            classN = "form-group col-md-6"
            storesSelect = ""
        }
        console.log(this.props.branchId);
        if(this.props.branchId !== undefined) {
            classN = "form-group col-md-6"
            storesSelect = ""
        }

        
        //const history = useHistory();

        return (
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <h6 className="m-0 font-weight-bold text-primary">Nuevo usuario</h6>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className={classN}>
                                <label htmlFor="inputName">Nombre de usuario</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Nombre de usuario" 
                                    value={this.state.username} onChange={this.onChangeUsername} />
                                </div>

                                <div className={classN}>
                                    <label htmlFor="inputName">Clave</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Clave" 
                                    value={this.state.password} onChange={this.onChangePassword} />
                                </div>

                                {storesSelect}
                            </div>
                            <div className="form-row text-center">
                                <div className="form-group col-md-2 text-center" style={{display: admin}}>
                                    <h6>Administrador</h6>
                                    <YesNoSwitch key="is_admin" onChange={this.onChangeIsAdmin} on={this.state.is_admin === 1} />
                                </div>

                                <div className="form-group col-md-2 text-center" style={{display: stores}}>
                                    <h6>Empresas</h6>
                                    <YesNoSwitch key="stores" onChange={this.onChangeStoresPermission} on={this.state.stores_permission} />
                                </div>

                                <div className="form-group col-md-2 text-center" style={{display: branches}}>
                                    <h6>Sucursales</h6>
                                    <YesNoSwitch key="branches" onChange={this.onChangeBranchesPermission} on={this.state.branches_permission} />
                                </div>
                                <div className="form-group col-md-2 text-center" style={{display: orders}}>
                                    <h6>Pedidos</h6>
                                    <YesNoSwitch key="orders" onChange={this.onChangeOrdersPermission} on={this.state.orders_permission} />
                                </div>

                                <div className="form-group col-md-2 text-center" style={{display: products}}>
                                    <h6>Productos</h6>
                                    <YesNoSwitch key="products" onChange={this.onChangeProductsPermission} on={this.state.products_permission} />
                                </div>

                                <div className="form-group col-md-2 text-center" style={{display: sales}}>
                                    <h6>Promociones</h6>
                                    <YesNoSwitch key="sales" onChange={this.onChangeSalesPermission} on={this.state.sales_permission} />
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Confirmar</button>
                            </div>
                        </form>
                    </div>
                </div>

                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.props.history.go(-1)}>
                    <Alert onClose={() => this.props.history.go(-1)} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }

}

export default NewUser