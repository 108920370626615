import React, { Component } from 'react';
import axios from 'axios'
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import TransactionsContent from '../../common/Transactions/TransactionsContent';

class BranchTransactions extends Component {

    static contextType = SessionContext
    constructor() {
        super()
        this.state = {
            transactions: null,
            clients: null
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.branchId !== undefined) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/transactions`;
        
            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                    .then(res => { 
                        let clientsList = new Set()
                        res.data.map(e => e.user_name !== null & e.user_last_name !== null && clientsList.add(`${e.user_name} ${e.user_last_name}`))
                        this.setState({ transactions: res.data, clients: clientsList }); 
                    })
                    .catch(function (error) { console.log(error); })
            );
        }
    }

    render() {

        let title, transactionContent;

        let branchName = "";
        if(sessionStorage.getItem("branch_name") !== null) {
            branchName = sessionStorage.getItem("branch_name");
        }

        title = branchName + " - Transacciones";

        if(this.state.transactions != null) {
            transactionContent = <TransactionsContent
                transactions={this.state.transactions}
                branches={null}
                clients={Array.from(this.state.clients)}
                title={title}
            />
        } else {
            transactionContent = <p>Esta sucursal aún no tiene transacciones.</p>
        }
        return(
            <div className="container">
                {transactionContent}
            </div>
        )
    }
}

export default BranchTransactions