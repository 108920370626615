import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class StoreFooter extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            file: '',
            base64: '',
            url: '',
            lines: '',
            open: false,
            success: false
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeLines = this.onChangeLines.bind(this)
        this.setOpen = this.setOpen.bind(this)
        this.setSuccess = this.setSuccess.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        let url = `${this.context.hubURL}/stores/${params.storeId}/footer_lines`;

        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*', }})
                .then(res => {
                    this.setState({ lines: res.data["lines"].join('\n'), url: res.data["ticket_logo_url"] });
                })
                .catch(function (error) { console.log(error); })
        );
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        var image = ''
        if (this.state.base64 !== '') {
            image = this.state.base64;
        } else {
            image = this.state.url;
        }

        let linesArray = this.state.lines.split('\n');

        const obj = {
            store_id: params.storeId,
            lines: linesArray,
            ticket_logo_url: image,
        };

        let url = `${this.context.hubURL}/stores/${params.storeId}/footer_lines`;

        trackPromise(
            axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*', } })
                .then((res) => { this.setOpen(true) })
                .catch((error) => { console.log(error) })
        );
    }

    onChangeLines(e) {
        this.setState({ lines: e.target.value })
    }

    setOpen(value) {
        this.setState({ open: value })
    }

    setSuccess(value) {
        this.setState({ success: value })
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: URL.createObjectURL(file),
                    base64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    render() {
        if (this.state.success) {
            return <Redirect to="/stores" />
        }

        let previewDiv, defined_class;
        if (this.state.file) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.file} alt='' /></div>
            defined_class = "col-md-9"
        } else if (this.state.url !== '') {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.url} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }

        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">Configuración Ticket</h1>
                <br />

                <div class="alert alert-info" role="alert">
                    <p>A continuación se podrá configurar el ticket. Su logo y las líneas al imprimir el ticket.</p>
                    <p>Cada línea del footer tiene como límite por línea es de 40 caracteres.</p>
                    <p>Cada ENTER que presiones, será una nueva línea del ticket.</p>
                </div>

                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className={defined_class}>
                                    <label htmlFor="inputFile">Logo (Max. 10MB)</label>
                                    <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                </div>
                                {previewDiv}
                            </div>

                            <div className="form-row align-items-center">
                                <div className="form-group">
                                    <textarea rows="20" cols="36" className="form-control" onChange={this.onChangeLines} defaultValue={this.state.lines} />
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.setState({success: true})}>
                    <Alert onClose={() => this.setState({success: true})} severity="success">
                        Datos guardados correctamente!
                    </Alert>
                </Snackbar>

            </div>
        )
    }

}

export default StoreFooter