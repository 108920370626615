import React, { Component } from 'react';

class Sale extends Component {

    render() {
        return (
            <div className="card">
                <div className="view overlay">
                    <img className="card-img-top" src={`${this.props.sale.image_path}`} alt={`${this.props.sale.title}`} style={{height: "100%", width: "100%", objectFit: "cover"}} />
                </div>

                <div className="card-body">
                    <h4 className="card-title font-weight-bold">{this.props.sale.title}</h4>
                    <p className="card-text">{this.props.sale.description}</p>
                    <hr className="my-4" />
                    <p className="mb-2">Precio normal: $ {this.props.sale.normal_price}</p>
                    <p className="mb-2">Precio promo: $ {this.props.sale.sale_price}</p>
                    <hr className="my-4" />
                    <p className="mb-2">Vigente desde: $ {this.props.sale.date_from}</p>
                    <p className="mb-2">Vigente hasta: $ {this.props.sale.date_to}</p>
                </div>
            </div>
        );
    }
}

export default Sale;