import React, { Component } from 'react';
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class PollQuestions extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            questions: [],
            urlToRedirect: null,
            error: '',
            message: '',
            open: false,
        }
        this.onDeleted = this.onDeleted.bind(this)
    }

    onDeleted(deletedURL) {
        trackPromise(
            axios.post(deletedURL, null, { headers: { 'Access-Control-Allow-Origin': '*', } })
            .then((res) => {
                this.setState({ open: true, message: 'Pregunta eliminada.' });
            }).catch((error) => {
                console.log(error)
            })
        );
    }

    customRedirect(urlToRedirect) {
        this.setState({ urlToRedirect: urlToRedirect });
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.branchId !== undefined) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/polls/${params.pollId}/questions`;
        
            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                    .then(res => { this.setState({ questions: res.data, open: false }); })
                    .catch(function (error) { console.log(error); })
            );
        }
    }

    render() {
        const { match: { params } } = this.props;

        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }
        
        let newURL, editURL
        if (params.branchId !== undefined) {
            newURL = `/stores/${params.storeId}/branches/${params.branchId}/polls/${params.pollId}/questions/new`
            editURL = `/stores/${params.storeId}/branches/${params.branchId}/polls/${params.pollId}/questions`
        }

        let content, title

        let pollName = "";
        if(sessionStorage.getItem("poll_name") !== null) {
            pollName = sessionStorage.getItem("poll_name");
        }

        title = pollName + " - Preguntas";

        if(this.state.questions != null) {
            content = <div className="card-body">
                <div className="row">
                    <table className="table table-hover">
                        <thead>
                            <tr><th scope="col">Pregunta</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.questions.map((q) => (
                            <tr>
                                <th scope="row">{q.title}</th>
                                <td>{q.type}</td>
                                <td>
                                    <a onClick={this.customRedirect.bind(this, `${editURL}/${q.id}/edit`)} className="btn btn-sm btn-success"><span className="icon text-white-50"><i className="fas fa-pencil-alt"></i></span></a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a onClick={this.onDeleted.bind(this, `${editURL}/${q.id}/delete`)} data-tag={q.id} className="btn btn-sm btn-danger"><span className="icon text-white-50"><i className="fas fa-trash"></i></span></a>
                                </td>
                            </tr> ))}
                        </tbody>
                    </table>
                </div>
            </div>    
        } else {
            content = <p>No hay preguntas en esta encuesta.</p>
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-10 text-left">
                        <h3 className="h3 mb-4 text-gray-800">{title}</h3>
                    </div>
                    <div className="col text-right my-auto">
                        <Link to={newURL} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default PollQuestions