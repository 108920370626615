import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sale from './Sale';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

class Sales extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            branch: null,
            store: null,
            sales: null
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        trackPromise(
            axios.get(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/sales`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
            .then(res => {
                this.setState({ branch: res.data["branch"], store: res.data["store"], sales: res.data["sales"]});
            })
            .catch(function (error) {
                console.log(error);
            })
        );
    }

    render() {
        const { match: { params } } = this.props;
        
        let content

        if (this.state.sales == null) {
            return (
                <div className="container-fluid">
                    <br />
                    <div className="row align-items-center">
                        <div className="col">
                            <h1>Promociones</h1>
                        </div>
                        <div className="col-md-auto">
                            <Link to={`/stores/${params.storeId}/branches/${params.branchId}/sales/new`} className="btn btn-success" style={{ margin: "0 auto"}}><i className="fas fa-plus" /></Link>
                        </div>
                    </div>
                    
                    <br />

                    <p>No hay promociones vigentes.</p>
                </div>
            )
        } else {
            content = <div className="card-columns">
            { this.state.sales.map((sale) => (<Sale sale={sale} />)) }
            </div>
        }

        return(
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">Promociones</h6>
                            </div>
                            <div className="col text-right my-auto">
                                <Link to={`/stores/${params.storeId}/branches/${params.branchId}/sales/new`} className="btn btn-success btn-icon-split"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {content}
                    </div>
                </div>
            </div>
        )
    }
}

export default Sales