import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

class ExcelUtils {
  exportData(title, data) {
    if (data == null) {
        return
    }
    var wb = XLSX.utils.book_new();
    wb.Props = {
            Title: title,
            Subject: "Fidely Backoffice",
            Author: "Fidely",
            CreatedDate: new Date()
    };
    
    wb.SheetNames.push("Transacciones");
    var ws_data = [['ID', 'Titulo', 'Monto', 'Fecha', 'POS', 'Sucursal', 'Nombre', 'Apellido', 'Documento', 'Nro. Tarjeta', 'Ticket ID', 'Medio de Pago', 'Descuento', 'Monto Descuento', 'Monto Original']];

    data.map((t) => (
        ws_data.push([t.id, t.reason, t.final_amount, t.date, t.serial_number, t.branch_name, t.user_name, t.user_last_name, t.user_identification, t.card_number, t.ticket_id, t.payment_method, t.discount, t.discount_amount, t.amount])
    ))

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Transacciones"] = ws;

    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
    }
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'transacciones.xlsx');
  }
}

export default ExcelUtils