import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import AccordionItem from '../AccordionItem';
import Accordion from '../Accordion';
import QuestionsAnswers from './QuestionsAnswers';

class PollAnswers extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            response: null,
            error: '',
            message: '',
            open: false,
        }

    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.branchId !== undefined) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/polls/${params.pollId}/answers`;
        
            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                    .then(res => { this.setState({ response: res.data, open: false }); })
                    .catch(function (error) { console.log(error); })
            );
        }
    }

    render() {
        let content, title

        let pollName = "";
        if(sessionStorage.getItem("poll_name") !== null) {
            pollName = sessionStorage.getItem("poll_name");
        }

        title = pollName + " - Respuestas";

        if(this.state.response != null) {
            content = <div className="card-body">
                <div className="row">
                    <QuestionsAnswers id="prueba" items={this.state.response.questions_answers} />
                </div>
            </div>    
        } else {
            content = <p>No hay preguntas en esta encuesta.</p>
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-left">
                        <h3 className="h3 mb-4 text-gray-800">{title}</h3>
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
            </div>
        )
    }
}

export default PollAnswers