import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import YesNoSwitch from '../YesNoSwitch';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewClientType extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            name: '',
            description: '',
            points_1: 0,
            points_2: 0,
            points_3: 0,
            points_4: 0,
            points_5: 0,
            points_6: 0,
            points_7: 0,
            discount_1: 0,
            discount_2: 0,
            discount_3: 0,
            discount_4: 0,
            discount_5: 0,
            discount_6: 0,
            discount_7: 0,
            min_allowed_points: 0,
            is_default: 0,
            open: false,
            someChanges: false,
            urlToRedirect: null,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeDescription = this.onChangeDescription.bind(this)
        this.onChangePoints1 = this.onChangePoints1.bind(this)
        this.onChangeDiscount1 = this.onChangeDiscount1.bind(this)
        this.onChangePoints2 = this.onChangePoints2.bind(this)
        this.onChangeDiscount2 = this.onChangeDiscount2.bind(this)
        this.onChangePoints3 = this.onChangePoints3.bind(this)
        this.onChangeDiscount3 = this.onChangeDiscount3.bind(this)
        this.onChangePoints4 = this.onChangePoints4.bind(this)
        this.onChangeDiscount4 = this.onChangeDiscount4.bind(this)
        this.onChangePoints5 = this.onChangePoints5.bind(this)
        this.onChangeDiscount5 = this.onChangeDiscount5.bind(this)
        this.onChangePoints6 = this.onChangePoints6.bind(this)
        this.onChangeDiscount6 = this.onChangeDiscount6.bind(this)
        this.onChangePoints7 = this.onChangePoints7.bind(this)
        this.onChangeDiscount7 = this.onChangeDiscount7.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        this.onChangeMinAllowedPoints = this.onChangeMinAllowedPoints.bind(this)
        this.onChangeIsDefault = this.onChangeIsDefault.bind(this)
        this.setOpen = this.setOpen.bind(this)
        this.onPoints1Blur = this.onPoints1Blur.bind(this)
        this.onDiscount1Blur = this.onDiscount1Blur.bind(this)
        this.onPoints2Blur = this.onPoints2Blur.bind(this)
        this.onDiscount2Blur = this.onDiscount2Blur.bind(this)
        this.onPoints3Blur = this.onPoints3Blur.bind(this)
        this.onDiscount3Blur = this.onDiscount3Blur.bind(this)
        this.onPoints4Blur = this.onPoints4Blur.bind(this)
        this.onDiscount4Blur = this.onDiscount4Blur.bind(this)
        this.onPoints5Blur = this.onPoints5Blur.bind(this)
        this.onDiscount5Blur = this.onDiscount5Blur.bind(this)
        this.onPoints6Blur = this.onPoints6Blur.bind(this)
        this.onDiscount6Blur = this.onDiscount6Blur.bind(this)
        this.onPoints7Blur = this.onPoints7Blur.bind(this)
        this.onDiscount7Blur = this.onDiscount7Blur.bind(this)
        this.goToList = this.goToList.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        var url = `${this.context.hubURL}/stores/${params.storeId}`
        if (params.branchId != undefined) {
            url += `/branches/${params.branchId}`
        }

        if (params.clientTypeId != null) {
            url += `/client_types/${params.clientTypeId}`

            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' }})
                    .then(res => {
                        this.setState({ name: res.data["name"], description: res.data["description"], 
                        points_1: res.data["points_1"], points_2: res.data["points_2"], points_3: res.data["points_3"],
                        points_4: res.data["points_4"], points_5: res.data["points_5"], points_6: res.data["points_6"],
                        points_7: res.data["points_7"], discount_1: res.data["discount_1"], discount_2: res.data["discount_2"],
                        discount_3: res.data["discount_3"], discount_4: res.data["discount_4"], discount_5: res.data["discount_5"],
                        discount_6: res.data["discount_6"], discount_7: res.data["discount_7"], min_allowed_points: res.data["min_allowed_points"], is_default: res.data["is_default"] });
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            );
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        }

        const obj = {
            name: this.state.name,
            description: this.state.description,
            points_1: this.state.points_1,
            points_2: this.state.points_2,
            points_3: this.state.points_3,
            points_4: this.state.points_4,
            points_5: this.state.points_5,
            points_6: this.state.points_6,
            points_7: this.state.points_7,
            discount_1: this.state.discount_1,
            discount_2: this.state.discount_2,
            discount_3: this.state.discount_3,
            discount_4: this.state.discount_4,
            discount_5: this.state.discount_5,
            discount_6: this.state.discount_6,
            discount_7: this.state.discount_7,
            min_allowed_points: this.state.min_allowed_points,
            is_default: this.state.is_default,
        };

        var url = `${this.context.hubURL}/stores/${params.storeId}`
        if (params.branchId != undefined) {
            url += `/branches/${params.branchId}`
        }
        url += `/client_types`

        var message = 'Tipo de cliente agregado!';
        if (params.clientTypeId != null) {
            url = url + `/${params.clientTypeId}`
            message = 'Tipo de cliente editado!'
        }

        trackPromise(
            axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*' }})
            .then((res) => { this.setState({ open: true, message: message }) })
            .catch((error) => { console.log(error) })
        );
    }

    onChangeDescription(e) {
        this.setState({ description: e.target.value, someChanges: true })
    }

    onChangeName(e) {
        this.setState({ name: e.target.value, someChanges: true })
    }

    onPoints1Blur(e) {
        if (this.state.points_1 === '') {
            this.setState({ points_1: 0 })
        }
    }

    onDiscount1Blur(e) {
        if (this.state.discount_1 === '') {
            this.setState({ discount_1: 0 })
        }
    }

    onPoints2Blur(e) {
        if (this.state.points_2 === '') {
            this.setState({ points_2: 0 })
        }
    }

    onDiscount2Blur(e) {
        if (this.state.discount_2 === '') {
            this.setState({ discount_2: 0 })
        }
    }

    onPoints3Blur(e) {
        if (this.state.points_3 === '') {
            this.setState({ points_3: 0 })
        }
    }

    onDiscount3Blur(e) {
        if (this.state.discount_3 === '') {
            this.setState({ discount_3: 0 })
        }
    }

    onPoints4Blur(e) {
        if (this.state.points_4 === '') {
            this.setState({ points_4: 0 })
        }
    }

    onDiscount4Blur(e) {
        if (this.state.discount_4 === '') {
            this.setState({ discount_4: 0 })
        }
    }

    onPoints5Blur(e) {
        if (this.state.points_5 === '') {
            this.setState({ points_5: 0 })
        }
    }

    onDiscount5Blur(e) {
        if (this.state.discount_5 === '') {
            this.setState({ discount_5: 0 })
        }
    }

    onPoints6Blur(e) {
        if (this.state.points_6 === '') {
            this.setState({ points_6: 0 })
        }
    }

    onDiscount6Blur(e) {
        if (this.state.discount_6 === '') {
            this.setState({ discount_6: 0 })
        }
    }

    onPoints7Blur(e) {
        if (this.state.points_7 === '') {
            this.setState({ points_7: 0 })
        }
    }

    onDiscount7Blur(e) {
        if (this.state.discount_7 === '') {
            this.setState({ discount_7: 0 })
        }
    }

    onChangePoints1(e) {
        this.setState({ points_1: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeDiscount1(e) {
        this.setState({ discount_1: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangePoints2(e) {
        this.setState({ points_2: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeDiscount2(e) {
        this.setState({ discount_2: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangePoints3(e) {
        this.setState({ points_3: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeDiscount3(e) {
        this.setState({ discount_3: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangePoints4(e) {
        this.setState({ points_4: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeDiscount4(e) {
        this.setState({ discount_4: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangePoints5(e) {
        this.setState({ points_5: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeDiscount5(e) {
        this.setState({ discount_5: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangePoints6(e) {
        this.setState({ points_6: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeDiscount6(e) {
        this.setState({ discount_6: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangePoints7(e) {
        this.setState({ points_7: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeDiscount7(e) {
        this.setState({ discount_7: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeMinAllowedPoints(e) {
        this.setState({ min_allowed_points: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeIsDefault(e) {
        let v = e ? 1 : 0
        this.setState({ is_default: v, someChanges: true })
    }

    setOpen(value) {
        this.setState({ open: value })
    }

    render() {
        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }

        return (
            <div className="container">
                <form onSubmit={this.onFormSubmitted}>
                    <h3 className="h3 mb-4 text-gray-800">Nuevo Tipo de Cliente</h3>
                    <div className="row">
                        <div className="form-group col-md-8">
                            <label htmlFor="inputName">Nombre</label>
                            <input className="form-control" type="text" id="inputName" placeholder="VIP" value={this.state.name} onChange={this.onChangeName} />
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <label htmlFor="inputName">Puntos mínimos para nivel</label>
                            <input className="form-control" type="text" id="inputName" placeholder="0" value={this.state.min_allowed_points} onChange={this.onChangeMinAllowedPoints} />
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <h6>Tipo de Cliente Default</h6>
                            <YesNoSwitch key="is_default_client_type" onChange={this.onChangeIsDefault} on={this.state.is_default === 1} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputName">Descripción</label>
                            <input className="form-control" type="text" id="inputName" placeholder="Clientes con más de 3 años de antiguedad" value={this.state.description} onChange={this.onChangeDescription} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md"></div>
                        <div className="col-md">Lunes</div>
                        <div className="col-md">Martes</div>
                        <div className="col-md">Miércoles</div>
                        <div className="col-md">Jueves</div>
                        <div className="col-md">Viernes</div>
                        <div className="col-md">Sábado</div>
                        <div className="col-md">Domingo</div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md"><b>Puntos</b></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="points_1" value={this.state.points_1} onChange={this.onChangePoints1} onBlur={this.onPoints1Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="points_2" value={this.state.points_2} onChange={this.onChangePoints2} onBlur={this.onPoints2Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="points_3" value={this.state.points_3} onChange={this.onChangePoints3} onBlur={this.onPoints3Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="points_4" value={this.state.points_4} onChange={this.onChangePoints4} onBlur={this.onPoints4Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="points_5" value={this.state.points_5} onChange={this.onChangePoints5} onBlur={this.onPoints5Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="points_6" value={this.state.points_6} onChange={this.onChangePoints6} onBlur={this.onPoints6Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="points_7" value={this.state.points_7} onChange={this.onChangePoints7} onBlur={this.onPoints7Blur} /></div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md"><b>Descuentos</b></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="discount_1" value={this.state.discount_1} onChange={this.onChangeDiscount1} onBlur={this.onDiscount1Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="discount_2" value={this.state.discount_2} onChange={this.onChangeDiscount2} onBlur={this.onDiscount2Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="discount_3" value={this.state.discount_3} onChange={this.onChangeDiscount3} onBlur={this.onDiscount3Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="discount_4" value={this.state.discount_4} onChange={this.onChangeDiscount4} onBlur={this.onDiscount4Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="discount_5" value={this.state.discount_5} onChange={this.onChangeDiscount5} onBlur={this.onDiscount5Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="discount_6" value={this.state.discount_6} onChange={this.onChangeDiscount6} onBlur={this.onDiscount6Blur} /></div>
                        <div className="col-md"><input type="text" className="form-control" placeholder="" id="discount_7" value={this.state.discount_7} onChange={this.onChangeDiscount7} onBlur={this.onDiscount7Blur} /></div>
                    </div>
                    <br />
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Confirmar</button>
                    </div>
                </form>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.goToList()}>
                    <Alert onClose={() => this.goToList()} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        )
    }

    goToList() {
        const { match: { params } } = this.props;
        var url = `/stores/${params.storeId}`
        if (params.branchId != undefined) {
            url += `/branches/${params.branchId}`
        }
        this.setState({ urlToRedirect: url + `/client_types` })
    }

}

export default NewClientType