import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import SessionContext from '../../SessionContext';

class Users extends Component {

    static contextType = SessionContext

    constructor() {
        super()

        this.state = {
            users: [],
        }

    }

    onDeletedUser(userId) {
        trackPromise(
            axios.post(`${this.context.hubURL}/backoffice/users/${userId}/delete`)
                .then(res => {
                    this.getUsers();
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers() {
        const { match: { params } } = this.props;

        let userObject = { };

        if(params.storeId !== undefined) {
            userObject["store_id"] = parseInt(params.storeId, 10)
        }

        if(params.branchId !== undefined) {
            userObject["branch_id"] = parseInt(params.branchId, 10)
        }

        trackPromise(
            axios.post(`${this.context.hubURL}/backoffice/users`, userObject)
                .then(res => {
                    this.setState({ users: res.data });
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    getUserType(user) {
        if (user.is_admin === "1") {
            return "Admin"
        } else if (user.branch_id === null) {
            if (user.store_id !== null) {
                return "Empresa"
            } else {
                var permissions = ""
                if (user.stores_permission == "1") {
                    permissions += "Empresas "
                }
                if (user.branches_permission == "1") {
                    permissions += "Sucursales "
                }
                if (user.products_permission == "1") {
                    permissions += "Catalogo "
                }
                if (user.sales_permission == "1") {
                    permissions += "Promociones "
                }
                if (user.orders_permission == "1") {
                    permissions += "Pedidos "
                }
                return permissions
            }
        } else {
            return "Sucursal"
        }
    }

    getEditURL(user) {
        const { match: { params } } = this.props;

        if (params.storeId != null) {
            if (params.branchId != null) {
                return `/stores/${params.storeId}/branches/${params.branchId}/users/${user.id}`
            } else {
                return `/stores/${params.storeId}/users/${user.id}`
            }
        } else {
            return `/users/${user.id}`
        }
    }

    render() {
        const { match: { params } } = this.props;
        
        let url = ""
        let content = ""

        if(this.state.users != null) {
            content = <div className="card-body">
                <div className="row">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre Usuario</th>
                                <th scope="col">Clave</th>
                                <th scope="col">Tipo de Usuario</th>
                                <th scope="col">Editar</th>
                                <th scope="col">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>{this.state.users.map((user) => ( 
                            <tr>
                                <th scope="row">{user.id}</th>
                                <td>{user.user}</td>
                                <td>{user.alias}</td>
                                <td>{this.getUserType(user)}</td>
                                <td><Link to={this.getEditURL(user)} className="btn btn-sm btn-block btn-primary btn-icon-split"><span className="icon text-white-50"><i className="fas fa-pencil-alt"></i></span><span className="text">Editar</span></Link></td>
                                <td><a onClick={this.onDeletedUser.bind(this, user.id)} className="btn btn-sm btn-block btn-danger btn-icon-split"><span className="icon text-white-50"><i className="fas fa-trash"></i></span><span className="text" style={{color: "#FFFFFF"}}>Eliminar</span></a></td>
                            </tr> ))}
                        </tbody>
                    </table>
                </div>
            </div>    
        } else {
            content = <p>No hay usuarios creados.</p>
        }

        if (params.storeId != null) {
            if (params.branchId != null) {
                url = `/stores/${params.storeId}/branches/${params.branchId}/users/new`
            } else {
                url = `/stores/${params.storeId}/users/new`
            }
        } else {
            url = "/users/new"
        }

        return(
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">Usuarios</h6>
                            </div>
                            <div className="col text-right my-auto">
                                <Link to={url} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <div className="row">
                                    <div className="col text-left my-auto">
                                        <h6 className="m-0 font-weight-bold text-primary">Listado de usuarios</h6>
                                    </div>
                                </div>
                            </div>
                            
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Users