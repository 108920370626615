
class CustomFieldsUtils {
  
  CustomFieldTypes = {
    TEXT: 'text',
    MULTIPLE_CHOICE: 'multiple_choice',
    DROPDOWN: "dropdown",
  };

  CapitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  ValidateMultipleChoiceOrDropdownField(input) {
    var regex = /^[\w\s]+(?:,[\w\s]+)*$/;
    return regex.test(input)
  }

}

export default CustomFieldsUtils;