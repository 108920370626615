import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { read, utils } from 'xlsx';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class UploadGiftcards extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            file: '',
            base64: '',
            open: false,
            headers: null,
            xlsx: null,
            colCardNumberSelected: -1,
            colGiftcardSelected: -1,
            lollame: false,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
        this.processExcel = this.processExcel.bind(this)
        this.onColCardNumberChange = this.onColCardNumberChange.bind(this)
        this.onColGiftcardChange = this.onColGiftcardChange.bind(this)
    }
    
    sumCols() {
        return this.state.colCardNumberSelected !== -1 && this.state.colGiftcardSelected !== -1
    }

    getValue(worksheet, key, row, isNumber) {
        var k = key + row.toString();
        if (worksheet[k] != undefined && worksheet[k] != null && worksheet[k].v != null) {
            return worksheet[k].v;
        }   

        if(isNumber) return 0;
        return '';
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault();
        const items = [];
        
        if (this.state.xlsx !== null && this.state.headers !== null) {
            const workbook = read(this.state.xlsx, {type: 'binary'});
            const firstSheet = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[firstSheet];
            const excelRows = utils.sheet_to_row_object_array(worksheet);

            for(var j = 2; j <= excelRows.length + 1; j++) {
                var item = {
                    "card_number": this.getValue(worksheet, this.state.colCardNumberSelected, j, false).toString(),
                    "balance": this.getValue(worksheet, this.state.colGiftcardSelected, j, true),
                    "store_id": parseInt(params.storeId, 10),
                };
                items.push(item);
            }
        }
        console.log(items);
        var url = `${this.context.hubURL}/stores/${params.storeId}/giftcards/upload`;
        trackPromise(
            axios.post(url, items, { headers: { 'Access-Control-Allow-Origin': '*' } })
            .then((res) => { 
                var message = "Se agregaron " + res.data["created_giftcards"] + " giftcards nuevas."
                this.setState({ open: true, message: message, cards: null, cardsSize: 0 }) 
            })
            .catch((error) => { console.log(error) })
        );
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
            reader.onload = (e) => {
                this.processExcel(reader.result);
            };
            reader.readAsBinaryString(file);
        }
    }

    processExcel(data) {
        const workbook = read(data, {type: 'binary'});
        const firstSheet = workbook.SheetNames[0];
        
        var colValues = [];
        var worksheet = workbook.Sheets[firstSheet];
        var cells = Object.keys(worksheet);
        
        let regEx = new RegExp("^\(\[A-Za-z\]+\)\(1\)$");
        var colCounter = 0;
        for (var i = 0; i < Object.keys(cells).length; i++) {
            if (regEx.test(cells[i]) === true) {
                colValues[colCounter] = { key: worksheet[cells[i]].v, value: cells[i].substr(0, cells[i].length-1) };
                colCounter++;
            }
        }

        if (colValues.length == 0) {

        } else {
            this.setState({ headers: colValues, xlsx: data });
        }
    }

    onColGiftcardChange(e) {
        this.setState({colGiftcardSelected: e.target.value});
    }

    onColCardNumberChange(e) {
        this.setState({colCardNumberSelected: e.target.value});
    }

    llamalo() {
        this.setState({ lollame: true })
    }

    render() {
        var divColA = ""
        var button = ""
        if(this.state.headers !== null) {
            divColA = <div className="form-row">
                <div className="form-group col-md-6">
                    <label for="selectColumnName">Columna - Nro. Tarjeta</label>
                    <select id="selectColumnName" className="form-control" value={this.state.colCardNumberSelected} onChange={this.onColCardNumberChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
                <div className="form-group col-md-6">
                    <label for="selectColumnSurname">Columna - Saldo</label>
                    <select id="selectColumnSurname" className="form-control" value={this.state.colGiftcardSelected} onChange={this.onColGiftcardChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
            </div>
        }
        if(this.sumCols()) {
            if(this.state.lollame) {
                button = <button type="submit" className="btn btn-primary">Confirmar</button>
            } else {
                this.llamalo()
            }
            
        }
        return (
            <div className="container-fluid">
                <h1 className="mt-4">Carga masiva de Giftcards</h1>
                <br />
    
                <form onSubmit={this.onFormSubmitted}>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputFile">CSV (Max. 10MB)</label>
                            <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                        </div>
                    </div>
                    {divColA}
                    <div className="form-row">
                        <div className="form-group">
                            {button}
                        </div>
                    </div>
                </form>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.props.history.go(-1)}>
                    <Alert onClose={() => this.props.history.go(-1)} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }

}
export default UploadGiftcards