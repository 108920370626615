import React, { Component } from 'react';
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { TableHeader } from 'semantic-ui-react';

class PointOfSaleTransactions extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            transactions: null,
            filtered_transactions: null,
            transaction_type: '',
            lookupClient: null,
            error: '',
            message: '',
            page: 0,
            rowsPerPage: 5,
        }

        this.onChangeTransactionTypeSearch = this.onChangeTransactionTypeSearch.bind(this)
        this.onChangeLookupClient = this.onChangeLookupClient.bind(this)
        this.onFilterFormSubmitted = this.onFilterFormSubmitted.bind(this)
        this.exportData = this.exportData.bind(this)
    }

    exportData() {
        var wb = XLSX.utils.book_new();
        wb.Props = {
                Title: "Transacciones",
                Subject: "Fidely Backoffice",
                Author: "Fidely",
                CreatedDate: new Date()
        };
        
        wb.SheetNames.push("Transacciones");
        var ws_data = [['ID', 'Titulo', 'Monto', 'Fecha', 'POS', 'Sucursal', 'Nombre', 'Apellido', 'Documento', 'Nro. Tarjeta', 'Ticket ID', 'Medio de Pago', 'Descuento', 'Monto Descuento', 'Monto Original']];
    
        this.state.filtered_transactions.map((t) => (
            ws_data.push([t.id, t.reason, t.final_amount, t.date, t.serial_number, t.branch_name, t.user_name, t.user_last_name, t.user_identification, t.card_number, t.ticket_id, t.payment_method, t.discount, t.discount_amount, t.amount])
        ))

        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        wb.Sheets["Transacciones"] = ws;

        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
        }
        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'transacciones.xlsx');
    }

    onChangeBranchSearch(e) { 
        this.setState({
            branch_selected: e.target.value,
        }) 
    }
    
    onChangeTransactionTypeSearch(e) { 
        this.setState({
            transaction_type: e.target.value
        }) 
    }

    onChangeLookupClient(e) {
        this.setState({ lookupClient: e.target.value })
    }

    onFilterFormSubmitted(e) {
        e.preventDefault();
        var tx = this.state.transactions;

        if (this.state.transaction_type !== '') {
            tx = tx.filter(t => t.type == this.state.transaction_type);
        }

        if (this.state.lookupClient !== null) {
            tx = tx.filter(t => t.user_identification == this.state.lookupClient || t.user_card_number == this.state.lookupClient);
        }

        this.setState({ filtered_transactions: tx })
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.branchId !== undefined) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/point_of_sales/${params.posId}/transactions`;
        
            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                    .then(res => { this.setState({ transactions: res.data, filtered_transactions: res.data }); })
                    .catch(function (error) { console.log(error); })
            );
        }
    }

    handleChangePage(event, newPage) {
        this.setState({ page: newPage });
      }
      
      handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0});
      }


    render() {
        const { match: { params } } = this.props;

        let content, title, buscador;

        let posName = "";
        if(sessionStorage.getItem("pos_name") !== null) {
            posName = sessionStorage.getItem("pos_name");
        }

        title = posName + " - Transacciones";

        if(this.state.transactions != null) {
            buscador = <div className="card shadow mb-4" style={{padding: "0"}}>
                <div className="card-header py-3">
                    <div className="row">
                        <h6 className="m-0 font-weight-bold text-primary">Filtrar Transacciones</h6>
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={this.onFilterFormSubmitted}>
                        <div className="form-row align-items-center">
                            <div className="form-group col-md-3">
                                <select id="selectTransactionType" className="form-control" value={this.state.transaction_type} onChange={this.onChangeTransactionTypeSearch}>
                                    <option value="">--- Tipo Transacción ---</option>
                                    <option value="PURCHASE">Venta</option>
                                    <option value="GIFTCARD_DOWNLOAD">Descarga</option>
                                    <option value="GIFTCARD_LOAD">Carga</option>
                                    <option value="SWAP">Canje</option>
                                    <option value="ANNULMENT">Anulación</option>
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <input className="form-control" type="text" id="inputLookupClient" 
                                    placeholder="Nro. Documento o Tarjeta" onChange={this.onChangeLookupClient} />
                            </div>
                            <div className="form-group col-md-3 text-center">
                                <button type="submit" className="btn btn-primary">Buscar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            content = <div className="row">
                {buscador}
                <div className="row">
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader>
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Fecha y hora</TableCell>
                        <TableCell align="center">Comercio</TableCell>
                        <TableCell align="center">Título</TableCell>
                        <TableCell align="center">Monto Final</TableCell>
                        <TableCell align="center">Monto Original</TableCell>
                        <TableCell align="center">Descuento</TableCell>
                        <TableCell align="center">Nro. Ticket</TableCell>
                        <TableCell align="center">Cliente</TableCell>
                        <TableCell align="center">Tarjeta</TableCell>
                    </TableHeader>
                    <TableBody>
                    {(this.state.rowsPerPage > 0 ? this.state.filtered_transactions.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage) : this.state.filtered_transactions).map((t, index) => (
                        <TableRow hover key={t.id}>
                            <TableCell align="center">{t.id}</TableCell>
                            <TableCell align="center">{new Date(t.date).toLocaleString('es')}</TableCell>
                            <TableCell align="center">{t.branch_name}</TableCell>
                            <TableCell align="center">{t.reason}</TableCell>
                            <TableCell align="center">{t.final_amount}</TableCell>
                            <TableCell align="center">{t.amount}</TableCell>
                            <TableCell align="center">{t.discount}</TableCell>
                            <TableCell align="center">{t.ticket_id}</TableCell>
                            <TableCell align="center">{t.user_name} {t.user_last_name} ({t.user_identification})</TableCell>
                            <TableCell align="center">{t.card_number}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination count={this.state.filtered_transactions.length} rowsPerPage={this.state.rowsPerPage} labelRowsPerPage="Items por página"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]} onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                            onChangePage={this.handleChangePage.bind(this)} page={this.state.page} />
                        </TableRow>
                    </TableFooter>
                </Table>
                </div> 
            </div>
        } else {
            content = <p>Este punto de venta aún no tiene transacciones.</p>
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-11 text-left">
                        <h3 className="h3 mb-4 text-gray-800">{title}</h3>
                    </div>
                    <div className="col-md-1 text-right">
                        <a onClick={this.exportData} className="btn btn-secondary"><span className="icon text-white-50"><i className="fas fa-cloud-download-alt"></i></span></a>
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
            </div>
        )
    }
}

export default PointOfSaleTransactions