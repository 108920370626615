import React, { Component } from 'react';

class TransactionsTitle extends Component {

  render() {
    const {title, exportDataFunc} = this.props
    return(
      <div className="row">
        <div className="col-md-11 text-left">
          <h3 className="h3 mb-4 text-gray-800">{title}</h3>
        </div>
        <div className="col-md-1 text-right">
          <a 
            onClick={exportDataFunc} 
            className="btn btn-secondary"
          >
            <span className="icon text-white-50">
              <i className="fas fa-cloud-download-alt"></i>
            </span>
          </a>
        </div>
      </div>
    )
  }
}

export default TransactionsTitle