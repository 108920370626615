import React, { Component } from 'react';

class AccordionItem extends Component {

    // id, title, body, parentId

    htmlDecode(input){
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
      }

    render() {
        var h2Id = "heading" + this.props.id;
        var ariaControls = "collapse" + this.props.id;
        var dataBsTarget = "#" + ariaControls;
        
        return (<div class="accordion-item">
            <h2 class="accordion-header" id={h2Id}>
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={dataBsTarget} aria-expanded="false" aria-controls={ariaControls}>
                {this.props.title}
            </button>
            </h2>
            <div id={ariaControls} class="accordion-collapse collapse" aria-labelledby={h2Id} data-bs-parent={this.props.parentId}>
            <div class="accordion-body">
                <div dangerouslySetInnerHTML={{__html: this.props.body}} />
            </div>
            </div>
        </div>);
    }

}

export default AccordionItem;