import React, { Component } from 'react';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

class Order extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            order: null,
            user: null
        }

        //this.reloadData = this.reloadData.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        trackPromise(
            axios.get(`${this.context.hubURL}/order/${params.orderId}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then(res => {
                    this.setState({ order: res.data["order"], user: res.data["user"] });
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    render() {
        const { match: { params } } = this.props;
        
        if (this.state.order == null) {
            return "Obteniendo información..."
        }

        return(
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">Detalle de pedido #{params.orderId}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div class="col-3">
                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Pedido por</div>
                                <div class="h6 mb-0 font-weight-bold text-gray-800">{this.state.user.name} {this.state.user.last_name}</div>
                            </div>
                            <div class="col-3">
                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Contacto</div>
                                <div class="h6 mb-0 font-weight-bold text-gray-800">{this.state.user.phone}</div>
                            </div>
                            <div class="col-3">
                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Monto total</div>
                                <div class="h6 mb-0 font-weight-bold text-gray-800">$ {this.state.order.total_price}</div>
                            </div>
                            <div class="col-3">
                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Pago</div>
                                <div class="h6 mb-0 font-weight-bold text-gray-800">{ this.state.order.payment_method === "CASH" ? ( "Efectivo" ) : ( "Tarjeta" ) }</div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div class="col-6">
                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Domicilio</div>
                                <div class="h6 mb-0 font-weight-bold text-gray-800">{this.state.order.type === "TakeAway" ? ( "Retira por sucursal" ) : ( this.state.order.address.street + " " + this.state.order.address.number + ", " + this.state.order.address.city + " - " + this.state.order.address.comments )}</div>
                            </div>
                            <div class="col-6">
                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Notas</div>
                                <div class="h6 mb-0 font-weight-bold text-gray-800">{(this.state.order.note === "" || this.state.order.note === null) ? ( "-" ) : ( this.state.order.note )}</div>
                            </div>
                        </div>
                        <hr />
                        <div className="card-columns">
                            { this.state.order.items.map((item) => (
                                <div className="card mb-3">
                                    <div class="row no-gutters">
                                        <div class="col-5">
                                            <img src={`${item.product != null ? item.product.image_path : item.sale.image_path}`} className="card-img-top h-100" alt={item.product != null ? item.product.name : item.sale.title} />
                                        </div>
                                        <div class="col-7">
                                            <div class="card-body">
                                                <h6 class="card-title">{item.product != null ? item.product.name : item.sale.title}</h6>
                                                <small>Unidades: {item.quantity}</small>
                                                <br />
                                                <small>Precio unitario: $ {item.price}</small>
                                                <p class="card-text font-weight-bold text-gray-800">Precio total: $ {item.quantity * item.price}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Order