import React, { Component } from 'react';
import axios from 'axios';
import OrdersTable from './OrdersTable';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext'
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class BranchHome extends Component {

    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            open: false,
            message: '',
            branch: null,
            store: null,
            orders: null,
            delivery_price: 0,
            delivery_time: 0,
            totalDay: 0,
            totalDayOrders: 0,
            pendingOrders: 0,
        }

        this.onOrderSelected = this.onOrderSelected.bind(this)
        this.onStatusClicked = this.onStatusClicked.bind(this)
        this.onDeliveryTimeChanged = this.onDeliveryTimeChanged.bind(this)
        this.onDeliveryPriceChanged = this.onDeliveryPriceChanged.bind(this)
        this.onDeliveryTimeSubmitted = this.onDeliveryTimeSubmitted.bind(this)
        this.onDeliveryPriceSubmitted = this.onDeliveryPriceSubmitted.bind(this)
        this.showMessage = this.showMessage.bind(this)
    }

    showMessage(message) {
        this.setState({ open: true, message: message })
    }

    onOrderSelected(order) {
        this.setState({orderSelected: order})
    }

    onDeliveryTimeChanged(e) {
        this.setState({delivery_time: e.target.value})
    }

    onDeliveryTimeSubmitted(e) {
        const { match: { params } } = this.props;

        e.preventDefault()

        const obj = {
            delivery_time: parseInt(this.state.delivery_time, 10)
        };

        trackPromise(
        axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/delivery`, obj, {
            headers: {
              'Access-Control-Allow-Origin': '*',
            }
        })
            .then((res) => {
                let b = this.state.branch
                b.deliveryTime = this.state.delivery_time
                this.setState({ branch: b, open: true, message: 'Demora de pedido actualizada correctamente!' })
            }).catch((error) => {
                console.log(error)
            })
        );
    }

    onDeliveryPriceChanged(e) {
        this.setState({delivery_price: e.target.value})
    }

    onDeliveryPriceSubmitted(e) {
        const { match: { params } } = this.props;

        e.preventDefault()

        const obj = {
            delivery_price: parseInt(this.state.delivery_price, 10),
        };

        trackPromise(
        axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/delivery`, obj, {
            headers: {
              'Access-Control-Allow-Origin': '*',
            }
        })
            .then((res) => {
                let b = this.state.branch
                b.deliveryPrice = this.state.delivery_price
                this.setState({ branch: b, open: true, message: 'Precio Delivery actualizado correctamente!' })
            }).catch((error) => {
                console.log(error)
            })
        );
    }

    onStatusClicked(e) {
        const { match: { params } } = this.props;

        if (this.state.branch == null) {
            return
        }

        let isOpen
        if(e.target.value === 'open' && this.state.branch.isOpen === 0) {
            isOpen = true
        } else if(e.target.value === 'close' && this.state.branch.isOpen === 1) {
            isOpen = false
        } else {
            return
        }

        const obj = {
            is_open: isOpen,
        };

        trackPromise(
            axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/status`, obj, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    let b = this.state.branch
                    let msg = ''
                    if (isOpen) {
                        msg = 'Ahora tu local está abierto!'
                        b.isOpen = 1
                    } else {
                        msg = 'Hasta la próxima!'
                        b.isOpen = 0
                    }
                    this.setState({ branch: b, open: true, message: msg })
                    // TODO: Feedback
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        
        let branchId = params.branchId
        let storeId = params.storeId

        if (branchId === undefined) {
            branchId = this.context.branch_id
        }

        if (storeId === undefined) {
            storeId = this.context.store_id
        }

        if (branchId !== undefined && storeId !== undefined) {
            axios.get(`${this.context.hubURL}/stores/${storeId}/branches/${branchId}/orders`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }
            })
                .then(res => {
                    let orders = res.data["orders"]
                    const totalDay = orders.reduce((totalPrice, order) => totalPrice + order.total_price, 0);
                    const pendingOrders = orders.filter(o => o.status === 'Created').length
                    this.setState({ branch: res.data["branch"], store: res.data["store"], orders: orders, delivery_price: res.data["branch"].deliveryPrice, delivery_time: res.data["branch"].deliveryTime, totalDay: totalDay, totalDayOrders: orders.length, pendingOrders: pendingOrders});
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    render() {
        let content, openClass, closedClass
        if (this.state.orders == null) {
            content = ""
        } else {
            content = <OrdersTable orders={this.state.orders} showMessage={this.showMessage} />
        }

        if (this.state.branch == null) {
            openClass = ""
            closedClass = ""
        } else if (this.state.branch.isOpen === 1) {
            openClass = "active"
            closedClass = ""
        } else {
            openClass = ""
            closedClass = "active"
        }

        return(
        <div className="container">
            
            <br />

            <div className="row text-center" style={{marginBottom: 8}}>
                <div className="col-sm">
                    <a className="btn btn-success" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Abierto / Cerrado</a>
                </div>
                <div className="col-sm">
                    <a className="btn btn-warning" data-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2">Precio Delivery</a>
                </div>
                <div className="col-sm">
                    <a className="btn btn-primary" data-toggle="collapse" href="#multiCollapseExample3" role="button" aria-expanded="false" aria-controls="multiCollapseExample3">Tiempo Delivery</a>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="collapse multi-collapse" id="multiCollapseExample1">
                        <div className="card card-body">
                            <div className="btn-group btn-group-toggle mb-2" date-toggle="buttons">
                                <label className={`btn btn-outline-success ${openClass}`}>
                                    <input type="radio" id="openBranch" name="a" value="open" checked={openClass === 'active'} onChange={this.onStatusClicked} /> Abierto
                                </label>
                                <label className={`btn btn-outline-success ${closedClass}`}>
                                    <input type="radio" id="closeBranch" name="a" value="close" checked={closedClass === 'active'} onChange={this.onStatusClicked} /> Cerrado
                                </label>
                            </div>
                            <small className="text-muted">Seleccioná "Abierto" para recibir pedidos.</small>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="collapse multi-collapse" id="multiCollapseExample2">
                        <div className="card card-body">
                            <form className="form-inline" onSubmit={this.onDeliveryPriceSubmitted}>
                                <input type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName3" placeholder="Precio Delivery" value={this.state.delivery_price} onChange={this.onDeliveryPriceChanged} aria-describedby="delivery_info" />
                                <button type="submit" className="btn btn-warning mb-2 mr-sm-2">Guardar</button>
                                <small id="delivery_info" className="text-muted">Ingresá el valor que tiene el envío de los pedidos.</small>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="collapse multi-collapse" id="multiCollapseExample3">
                        <div className="card card-body">
                        <form className="form-inline" onSubmit={this.onDeliveryTimeSubmitted}>
                                <input type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Tiempo Demora" value={this.state.delivery_time} onChange={this.onDeliveryTimeChanged} aria-describedby="delay_info" />
                                <button type="submit" className="btn btn-primary mb-2">Guardar</button>
                                <small id="delay_info" className="text-muted">Ingresá el tiempo en promedio que demoran los pedidos.</small>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <br />

            <div className="row justify-content-center">

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-danger shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">Pedidos pendientes</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.pendingOrders}</div>
                                </div>
                                <div className="col-auto">
                                <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Pedidos del día</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.totalDayOrders}</div>
                                </div>
                                <div className="col-auto">
                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Total Facturado</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">$ {this.state.totalDay}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <br />

            {content}

            <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.setState({open: false, message: ''})}>
                <Alert onClose={() => this.setState({open: false, message: ''})} severity="success">
                    {this.state.message}
                </Alert>
            </Snackbar>
        </div>
        )
    }
}

export default BranchHome