import { Link, Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';

class CardStore extends Component {
  static contextType = SessionContext
  
  constructor() {
        super()

        this.state = {
          urlToRedirect: null
        };

        this.onDeleted = this.onDeleted.bind(this)
    }

  onDeleted() {
    trackPromise(
        axios.post(`${this.context.hubURL}/stores/${this.props.store.id}/delete`, null, {
            headers: {
              'Access-Control-Allow-Origin': '*',
            }
        })
        .then((res) => {
            this.props.onDeleted()
        }).catch((error) => {
            console.log(error)
        })
    );
  }

  customRedirect(urlToRedirect) {
    sessionStorage.setItem("store_name", this.props.store.name);
    this.setState({ urlToRedirect: urlToRedirect });
  }

  render() {
    if (this.state.urlToRedirect !== null) {
      return <Redirect to={this.state.urlToRedirect} />
    }

    return (
      <div className="card">
          <div className="view overlay">
              <img className="card-img-top" src={`${this.props.store.logo_url}`} alt={this.props.store.name} style={{height: "100%", width: "100%", objectFit: "cover"}} />
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-7">
                <h4 className="card-title font-weight-bold text-gray-800">{this.props.store.name}</h4>
              </div>
              <div className="col-5" style={{textAlign: "center"}}>
                <Dropdown className="btn btn-sm">
                  <Dropdown.Toggle variant="primary" className="btn-sm">
                    <i className="fas fa-pencil-alt" style={{color: "white"}}/>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/edit`)}>Editar</Dropdown.Item>
                    <Dropdown.Item onClick={this.onDeleted}>Eliminar</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="btn btn-sm">
                  <Dropdown.Toggle variant="success" className="btn-sm">
                    <i className="fas fa-cog" style={{color: "white"}}/>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/about`)}>Sobre Nosotros</Dropdown.Item>
                    <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/ticket_footer`)}>Configurar Ticket</Dropdown.Item>
                    <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/payment_gateways`)}>Medios de Pago</Dropdown.Item>
                    <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/users`)}>Usuarios</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <hr className="my-4" />
            <div className="row" style={{textAlign: "center"}}>
                <div className="col-6">
                  <Button className="btn btn-block btn-sm" variant="warning" onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/branches`)}>
                      <span style={{color: "white"}}><i className="fas fa-building" style={{marginRight: "10px"}}/>Sucursales</span>
                  </Button>
                </div>
                <div className="col-6">
                  <Dropdown>
                    <Dropdown.Toggle variant="dark" className="btn btn-block btn-sm">
                      <span style={{color: "white"}}><i className="fas fa-users" style={{marginRight: "10px"}}/>Clientes</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/clients`)}>Listado de Clientes</Dropdown.Item>
                      <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/clients/upload`)}>Importar Clientes</Dropdown.Item>
                      <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/client_types`)}>Tipos de Clientes</Dropdown.Item>
                      <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/client_types_updates`)}>Cambios de Categoría</Dropdown.Item>
                      <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/card_numbers/upload`)}>Importar Tarjetas</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
            </div>
            <div style={{paddingBottom: "16px"}} />
            <div className="row" style={{textAlign: "center"}}>
              <div className="col-6">
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" className="btn btn-block btn-sm">
                    <span style={{color: "white"}}><i className="fas fa-globe-americas" style={{marginRight: "10px"}}/>Sitios</span>
                  </Dropdown.Toggle>
                  
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/newclientpage`)}>Sitio Alta de Clientes</Dropdown.Item>
                    <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/checkpage`)}>Sitio Consulta Saldo</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
                
              <div className="col-6">
                <Button className="btn btn-block btn-sm" variant="info" onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/categories`)}>
                  <span style={{color: "white"}}><i className="fas fa-shopping-cart" style={{marginRight: "10px"}}/>Catálogo</span>
                </Button>
              </div>
            </div>
          <div style={{paddingBottom: "16px"}} />
          <div className="row" style={{textAlign: "center"}}>
            <div className="col-6">
              <Dropdown>
                <Dropdown.Toggle variant="danger" className="btn btn-block btn-sm">
                  <span style={{color: "white"}}><i className="fas fa-gift" style={{marginRight: "10px"}}/>Giftcard</span>
                </Dropdown.Toggle>
                
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/giftcards`)}>Listado Giftcards</Dropdown.Item>
                  <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/giftcards/upload`)}>Importar Giftcards</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-6">
            <Dropdown>
                    <Dropdown.Toggle variant="primary" className="btn btn-block btn-sm">
                      <span style={{color: "white"}}><i className="fas fa-credit-card" style={{marginRight: "10px"}}/>Transacciones</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/transactions`)}>Listado de Transacciones</Dropdown.Item>
                      <Dropdown.Item onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/transactions/upload`)}>Importar Transacciones</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
            </div>
            </div>
            <div style={{paddingBottom: "16px"}} />
            <div className="row" style={{textAlign: "center"}}>
              <div className="col-6">
                <Button className="btn btn-block btn-sm" variant="success" onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/report`)}>
                  <span style={{color: "white"}}><i className="fas fa-chart-line" style={{marginRight: "10px"}}/>Reportes</span>
                </Button>
              </div>
              <div className="col-6">
                <Button className="btn btn-block btn-sm" variant="warning" onClick={this.customRedirect.bind(this, `/stores/${this.props.store.id}/communications`)}>
                  <span style={{color: "white"}}><i className="fas fa-chart-line" style={{marginRight: "10px"}}/>Comunicación</span>
                </Button>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
export default CardStore;