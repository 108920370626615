import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SessionContext from '../SessionContext';

class Error extends Component {

    static contextType = SessionContext

    render() {

        let msg = "Página no encontrada."
        let code = "404"
        if (this.props.message !== undefined) {
            code = "400"
            msg = this.props.message
        }

        let bu = '/stores'
        if (this.context !== undefined && this.context.user !== undefined) {
            if (this.context.user.store_id !== null && this.context.user.store_id !== 0) {
                if (this.context.user.branch_id !== null && this.context.user.branch_id !== 0) {
                bu = `/stores/${this.context.user.store_id}/branches/${this.context.user.branch_id}`
                } else {
                    bu = `/stores/${this.context.user.store_id}`
                }
            }
        }

        return (
            <div className="row justify-content-center">
                <div class="d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                    <h1 class="mr-3 pr-3 align-middle border-right inline-block align-content-center">{code}</h1>
                    <div class="inline-block align-middle">
                        <h2 class="font-weight-normal lead" id="desc">{msg}</h2>
                        <br />
                        <a href={bu} className="btn btn-sm btn-block btn-primary btn-icon-split"><span className="icon text-white-50"><i className="fas fa-home"></i></span><span className="text">Volver al inicio</span></a>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}
export default Error;