import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

class CardClientType extends Component {
  static contextType = SessionContext
  
  constructor() {
        super()

        this.onDeleted = this.onDeleted.bind(this)
        this.getDeleteURL = this.getDeleteURL.bind(this)
        this.getEditURL = this.getEditURL.bind(this)
    }

  onDeleted() {
    trackPromise(
      axios.post(this.getDeleteURL(), null, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          }
      })
      .then((res) => {
          this.props.onDeleted()
      }).catch((error) => {
          console.log(error)
      })
    );
  }

  getEditURL() {
    var url = `/stores/${this.props.clientType.store_id}`
    if (this.props.isBranch) {
      url += `/branches/${this.props.clientType.branch_id}`  
    }
    url += `/client_types/${this.props.clientType.id}/edit`
    return url
  }

  getDeleteURL() {
    var url = `${this.context.hubURL}/stores/${this.props.clientType.store_id}`
    if (this.props.isBranch) {
      url += `/branches/${this.props.clientType.branch_id}`  
    }
    url += `/client_types/${this.props.clientType.id}/delete`
    return url
  }

  render() {
      let isDefault = "No"
      if(this.props.clientType.is_default === 1) {
          isDefault = "Si"
      }
    return (
        <div class="row no-gutters">
            <div className="card shadow" style={{marginBottom: "16px", width: "100%"}}>
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-10 text-left my-auto">
                            <h6 className="m-0 font-weight-bold text-primary">{this.props.clientType.name}</h6>
                        </div>
                        <div className="col-1 text-right">
                            <Link to={this.getEditURL()} className="btn btn-sm btn-primary"><span className="icon text-white-50"><i className="fas fa-pencil-alt"></i></span></Link>
                        </div>
                        <div className="col-1 text-left">
                            <a onClick={this.onDeleted} className="btn btn-sm btn-danger"><span className="icon text-white-50"><i className="fas fa-trash"></i></span></a>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <p className="mb-1"><small>{this.props.clientType.description}</small></p>
                    <div className="row">
                        <div class="col-6 text-left"><p className="mb-1"><small>Puntos requeridos: {this.props.clientType.min_allowed_points}</small></p></div>
                        <div class="col-6 text-left"><p className="mb-1"><small>Tipo de cliente default: {isDefault}</small></p></div>
                    </div>
                    <br />
                    <div className="row">
                        <div class="col-md text-center"><p className="mb-1"><small><b></b></small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small><b>Lunes</b></small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small><b>Martes</b></small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small><b>Miércoles</b></small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small><b>Jueves</b></small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small><b>Viernes</b></small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small><b>Sábado</b></small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small><b>Domingo</b></small></p></div>
                    </div>
                    <hr className="my-4" />
                    <div className="row">
                        <div class="col-md text-center"><p className="mb-1"><small><b>Puntos</b></small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.points_1}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.points_2}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.points_3}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.points_4}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.points_5}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.points_6}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.points_7}</small></p></div>
                    </div>
                    <hr className="my-4" />
                    <div className="row">
                        <div class="col-md text-center"><p className="mb-1"><small><b>Descuentos</b></small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.discount_1}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.discount_2}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.discount_3}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.discount_4}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.discount_5}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.discount_6}</small></p></div>
                        <div class="col-md text-center"><p className="mb-1"><small>{this.props.clientType.discount_7}</small></p></div>
                    </div>
                    <hr className="my-4" />
                
                </div>
            </div>
        </div>
    );
  }
}
export default CardClientType;