import React, { Component } from 'react';

class YesNoSwitch extends Component {

    constructor() {
        super()
        this.state = {
            yes: false,
            no: false,
        }

        this.onChange = this.onChange.bind(this)
    }

    componentWillMount() {
        this.setState({ yes: this.props.on, no: this.props.on === false })
    }

    onChange(e) {
        if(e.target.value === 'yes' && this.state.yes === false) {
            this.setState({ yes: true, no: false})
            this.props.onChange(true)
        } else if(e.target.value === 'no' && this.state.no === false) {
            this.setState({ yes: false, no: true})
            this.props.onChange(false)
        }
    }

    render() {
        if(this.state.yes && !this.props.on) {
            this.setState({ yes: false, no: true})
        } else if (this.state.no && this.props.on) {
            this.setState({ yes: true, no: false})
        }

        let yesClass, noClass
        if (this.state.yes) {
            yesClass = "active"
            noClass = ""
        } else {
            yesClass = ""
            noClass = "active"
        }
        return (
            <div className="btn-group btn-group-toggle mb-2" date-toggle={this.props.key}>
                <label className={`btn btn-outline-secondary ${yesClass}`}>
                    <input type="radio" id="yes" name="a" value="yes" checked={yesClass === 'active'} onChange={this.onChange} /> Si
                </label>
                <label className={`btn btn-outline-secondary ${noClass}`}>
                    <input type="radio" id="no" name="a" value="no" checked={noClass === 'active'} onChange={this.onChange} /> No
                </label>
            </div>
        )
    }

}

export default YesNoSwitch