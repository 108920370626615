import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AboutUs extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            content: '',
            open: false,
            success: false
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeContent = this.onChangeContent.bind(this)
        this.setOpen = this.setOpen.bind(this)
        this.setSuccess = this.setSuccess.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        trackPromise(
            axios.get(`${this.context.hubURL}/stores/${params.storeId}/about`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then(res => {
                    this.setState({ content: res.data["content"] });
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        const obj = {
            content: this.state.content,
        };

        trackPromise(
            axios.post(`${this.context.hubURL}/stores/${params.storeId}/about`, obj, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    this.setOpen(true)
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    onChangeContent(e) {
        this.setState({ content: e.target.value })
    }

    setOpen(value) {
        this.setState({ open: value })
    }

    setSuccess(value) {
        this.setState({ success: value })
    }

    render() {
        if (this.state.success) {
            return <Redirect to="/stores" />
        }

        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">Sobre nosotros</h1>
                <br />
    
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className="form-group">
                                    <textarea rows="10" cols="100" onChange={this.onChangeContent} defaultValue={this.state.content} />
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.setState({success: true})}>
                    <Alert onClose={() => this.setState({success: true})} severity="success">
                        Datos guardados correctamente!
                    </Alert>
                </Snackbar>

            </div>
        )
    }

}

export default AboutUs