import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { read, utils } from 'xlsx';
import { ContactSupportOutlined } from '@material-ui/icons';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class UploadClients extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            file: '',
            base64: '',
            open: false,
            headers: null,
            xlsx: null,
            clientTypes: null,
            client_type_id: -1,
            colNameSelected: -1,
            colSurnameSelected: -1,
            colDocSelected: -1,
            colBornDateSelected: -1,
            colGenderSelected: -1,
            colEmailSelected: -1,
            colPhoneSelected: -1,
            colCardNumberSelected: -1,
            colPointsSelected: -1,
            colCashSelected: -1,
            lollame: false,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
        this.processExcel = this.processExcel.bind(this)
        this.onColNameChange = this.onColNameChange.bind(this)
        this.onColSurnameChange = this.onColSurnameChange.bind(this)
        this.onColDocChange = this.onColDocChange.bind(this)
        this.onColBornDateChange = this.onColBornDateChange.bind(this)
        this.onColGenderChange = this.onColGenderChange.bind(this)
        this.onColEmailChange = this.onColEmailChange.bind(this)
        this.onColPhoneChange = this.onColPhoneChange.bind(this)
        this.onColCardNumberChange = this.onColCardNumberChange.bind(this)
        this.onColPointsChange = this.onColPointsChange.bind(this)
        this.onColCashChange = this.onColCashChange.bind(this)
        this.onChangeClientTypeID = this.onChangeClientTypeID.bind(this)
    }
    
    componentDidMount() {
        const { match: { params } } = this.props;

        var url = `${this.context.hubURL}/stores/${params.storeId}/client_types`;
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' }})
                .then(res => {
                    let ct = res.data.filter(ct => ct.deleted === 0)
                    if (ct instanceof Array) {
                        if (ct.length == 1) {
                            this.setState({ clientTypes: ct, client_type_id: ct[0].id });
                        } else {
                            this.setState({ clientTypes: ct });
                        }
                    } else {
                        this.setState({ clientTypes: ct, client_type_id: ct.id });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    sumCols() {
        return this.state.colBornDateSelected !== -1 && this.state.colNameSelected !== -1 && this.state.colSurnameSelected !== -1 &&
        this.state.colEmailSelected !== -1 && this.state.colPhoneSelected !== -1 && this.state.colDocSelected !== -1 &&
        this.state.colPointsSelected !== -1 && this.state.colGenderSelected !== -1 && this.state.colCardNumberSelected !== -1 &&
        this.state.colCashSelected !== -1 && this.state.client_type_id !== -1
    }

    onChangeClientTypeID(e) {
        this.setState({ client_type_id: e.target.value });
    }

    getValue(worksheet, key, row, isNumber) {
        var k = key + row.toString();
        if (worksheet[k] != undefined && worksheet[k] != null && worksheet[k].v != null && worksheet[k].v != undefined) {
            return worksheet[k].v;
        }   

        if(isNumber) return 0;
        return '';
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault();
        const items = [];
        
        if (this.state.xlsx !== null && this.state.headers !== null) {
            const workbook = read(this.state.xlsx, {type: 'binary'});
            const firstSheet = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[firstSheet];
            const excelRows = utils.sheet_to_row_object_array(worksheet);

            for(var j = 2; j <= excelRows.length + 1; j++) {
                var name =  this.getValue(worksheet, this.state.colNameSelected, j, false);
                var lastName = this.getValue(worksheet, this.state.colSurnameSelected, j, false);
                var item = {
                    "name": name[0].toUpperCase() + name.slice(1).toLowerCase(),
                    "last_name": lastName[0].toUpperCase() + lastName.slice(1).toLowerCase(),
                    "identification": this.getValue(worksheet, this.state.colDocSelected, j, false).toString(),
                    "born_date": this.getValue(worksheet, this.state.colBornDateSelected, j, false).toString(),
                    "sex": this.getValue(worksheet, this.state.colGenderSelected, j, false).toString(),
                    "email": this.getValue(worksheet, this.state.colEmailSelected, j, false).toLowerCase(),
                    "phone": this.getValue(worksheet, this.state.colPhoneSelected, j, false).toString(),
                    "card_number": this.getValue(worksheet, this.state.colCardNumberSelected, j, false).toString(),
                    "points": this.getValue(worksheet, this.state.colPointsSelected, j, true),
                    "cash": this.getValue(worksheet, this.state.colCashSelected, j, true),
                    "client_type_id": parseInt(this.state.client_type_id, 10),
                    "store_id": parseInt(params.storeId, 10),
                };
                items.push(item);
            }
        }

        var url = `${this.context.hubURL}/stores/${params.storeId}/clients/upload`;
        trackPromise(
            axios.post(url, items, { headers: { 'Access-Control-Allow-Origin': '*' } })
            .then((res) => { 
                var message = "Se agregaron " + res.data["created_users"] + " nuevos usuarios y " + res.data["created_subscriptions"] + " subscripciones nuevas."
                this.setState({ open: true, message: message }) 
            })
            .catch((error) => { console.log(error) })
        );
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
            reader.onload = (e) => {
                this.processExcel(reader.result);
            };
            reader.readAsBinaryString(file);
        }
    }

    processExcel(data) {
        const workbook = read(data, {type: 'binary'});
        const firstSheet = workbook.SheetNames[0];
        
        var colValues = [];
        var worksheet = workbook.Sheets[firstSheet];
        var cells = Object.keys(worksheet);
        
        let regEx = new RegExp("^\(\[A-Za-z\]+\)\(1\)$");
        var colCounter = 0;
        for (var i = 0; i < Object.keys(cells).length; i++) {
            if (regEx.test(cells[i]) === true) {
                colValues[colCounter] = { key: worksheet[cells[i]].v, value: cells[i].substr(0, cells[i].length-1) };
                colCounter++;
            }
        }

        if (colValues.length == 0) {

        } else {
            this.setState({ headers: colValues, xlsx: data });
        }
    }

    onColNameChange(e) {
        this.setState({colNameSelected: e.target.value});
    }
    
    onColSurnameChange(e) {
        this.setState({colSurnameSelected: e.target.value});
    }

    onColDocChange(e) {
        this.setState({colDocSelected: e.target.value});
    }

    onColBornDateChange(e) {
        this.setState({colBornDateSelected: e.target.value});
    }

    onColGenderChange(e) {
        this.setState({colGenderSelected: e.target.value});
    }

    onColEmailChange(e) {
        this.setState({colEmailSelected: e.target.value});
    }

    onColPhoneChange(e) {
        this.setState({colPhoneSelected: e.target.value});
    }

    onColCardNumberChange(e) {
        this.setState({colCardNumberSelected: e.target.value});
    }
    
    onColPointsChange(e) {
        this.setState({colPointsSelected: e.target.value});
    }

    onColCashChange(e) {
        this.setState({colCashSelected: e.target.value});
    }

    llamalo() {
        this.setState({ lollame: true })
    }

    render() {
        let clientTypeSelect
        let canUpload
        if (this.state.clientTypes == null) {
            clientTypeSelect = ""
            canUpload = "none"
        } else {
            canUpload = "block"
            clientTypeSelect = <div className="form-group col-md-4">
                <label for="selectClientType">Tipo Cliente</label>
                <select id="selectClientType" className="form-control" value={this.state.client_type_id} onChange={this.onChangeClientTypeID}>
                    <option value="-1">Seleccioná una opción</option>
                    { this.state.clientTypes.map((clientType) => (
                        <option value={clientType.id}>{clientType.name}</option>
                    )) 
                    }
                </select>
            </div>
        }

        var divColA = ""
        var divColB = ""
        var divColC = ""
        var button = ""
        var cardsSizeText = ""
        if(this.state.headers !== null) {
            divColA = <div className="form-row">
                <div className="form-group col-md-3">
                    <label for="selectColumnName">Columna - Nombre</label>
                    <select id="selectColumnName" className="form-control" value={this.state.colNameSelected} onChange={this.onColNameChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
                <div className="form-group col-md-3">
                    <label for="selectColumnSurname">Columna - Apellido</label>
                    <select id="selectColumnSurname" className="form-control" value={this.state.colSurnameSelected} onChange={this.onColSurnameChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
                <div className="form-group col-md-3">
                    <label for="selectColumnDoc">Columna - Nro. Documento</label>
                    <select id="selectColumnDoc" className="form-control" value={this.state.colDocSelected} onChange={this.onColDocChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
                <div className="form-group col-md-3">
                    <label for="selectColumnGender">Columna - Sexo</label>
                    <select id="selectColumnGender" className="form-control" value={this.state.colGenderSelected} onChange={this.onColGenderChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
            </div>
            divColB = <div className="form-row">
                <div className="form-group col-md-4">
                    <label for="selectColumnBornDate">Columna - Fecha Nacimiento</label>
                    <select id="selectColumnBornDate" className="form-control" value={this.state.colBornDateSelected} onChange={this.onColBornDateChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label for="selectColumnEmail">Columna - Email</label>
                    <select id="selectColumnEmail" className="form-control" value={this.state.colEmailSelected} onChange={this.onColEmailChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label for="selectColumnPhone">Columna - Teléfono</label>
                    <select id="selectColumnPhone" className="form-control" value={this.state.colPhoneSelected} onChange={this.onColPhoneChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
            </div>
            divColC = <div className="form-row">
                <div className="form-group col-md-4">
                    <label for="selectColumnCardNumber">Columna - Nro. Tarjeta</label>
                    <select id="selectColumnCardNumber" className="form-control" value={this.state.colCardNumberSelected} onChange={this.onColCardNumberChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label for="selectColumnPoints">Columna - Puntos</label>
                    <select id="selectColumnPoints" className="form-control" value={this.state.colPointsSelected} onChange={this.onColPointsChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label for="selectColumnCash">Columna - Puntos Pesos</label>
                    <select id="selectColumnCash" className="form-control" value={this.state.colCashSelected} onChange={this.onColCashChange}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.headers.map((col) => (
                            <option value={col.value}>{col.key}</option>
                        )) 
                        }
                    </select>
                </div>
            </div>
        }
        if(this.sumCols()) {
            if(this.state.lollame) {
                button = <button type="submit" className="btn btn-primary">Confirmar</button>
                //cardsSizeText = <p><b style={{color: "green"}}>Se agregarán {this.state.cardsSize} tarjetas.</b></p>
            } else {
                this.llamalo()
            }
            
        }
        return (
            <div className="container-fluid">
                <h1 className="mt-4">Carga masiva de clientes</h1>
                <br />

                <div class="alert alert-info" role="alert">
                        <p><b>Recordá crear al menos un tipo de cliente.</b></p>
                        <p>Los datos requerido son: Nombre, Apellido, Nro. Documento, Email, Sexo, Fecha de Nacimiento, Telefono, Numero de Tarjeta (Si tiene asignada, sino vacío), Puntos (0 como default), Pesos (0 como default)</p>
                    </div>

                <div style={{display: canUpload}}>
                    <form onSubmit={this.onFormSubmitted}>
                        <div className="form-row">
                            <div className="form-group col-md-8">
                                <label htmlFor="inputFile">CSV (Max. 10MB)</label>
                                <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                            </div>
                            {clientTypeSelect}
                        </div>
                        {divColA}
                        {divColB}
                        {divColC}
                        <div className="form-row">
                            <div className="form-group">
                                {button}
                            </div>
                        </div>
                    </form>
        
                    <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.props.history.go(-1)}>
                        <Alert onClose={() => this.props.history.go(-1)} severity="success">
                            {this.state.message}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        )
    }

}
export default UploadClients