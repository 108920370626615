import React, { Component } from 'react';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

class UserBranchModal extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            data: null,
            username: '',
            password: ''
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeUsername = this.onChangeUsername.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
    }

    componentDidMount() {
        trackPromise(
            axios.get(`${this.context.hubURL}/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/users`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then(res => {
                    this.setState({ data: res.data });
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    onFormSubmitted(e) {
        e.preventDefault()

        const userObject = {
            username: this.state.username,
            password: this.state.password
        };

        trackPromise(
            axios.post(`${this.context.hubURL}/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/users`, userObject, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    this.setState({ 
                        username: '',
                        password: '',
                    })
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    onChangeUsername(e) {
        this.setState({ username: e.target.value })
    }

    onChangePassword(e) {
        this.setState({ password: e.target.value })
    }

  render() {
    let content

    if(this.state.data == null || this.state.data.users == null) {
        content = (<p>Esta sucursal no tiene usuarios asignados.</p>)
    } else {
        content = (
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">Nombre de usuario</th>
                    <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    { this.state.data.users.map((user) => (<tr><td>{user.username}</td><td></td></tr>)) }
                </tbody>
            </table>
        )
    }

    return (
        <div className="modal fade" id="branchUsers" tabindex="-1" role="dialog" aria-labelledby="branchUsersLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="branchUsersLabel">{this.props.branch.name} - Usuarios</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="form-inline" onSubmit={this.onFormSubmitted}>
                            <label className="sr-only" for="inlineFormUsername">Usuario</label>
                            <input type="text" className="form-control mb-2 mr-sm-2" id="inlineFormUsername" onChange={this.onChangeUsername} placeholder="Nombre usuario" />

                            <label className="sr-only" for="inlineFormPassword">Clave</label>
                            <input type="password" className="form-control mb-2 mr-sm-2" id="inlineFormPassword" onChange={this.onChangePassword} placeholder="Clave" />

                            <button type="submit" data-dismiss="modal" className="btn btn-primary mb-2 mr-sm-2">Agregar</button>
                        </form>

                        {content}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.props.onClose} data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default UserBranchModal;