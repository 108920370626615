import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { addMonths, format } from 'date-fns';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import YesNoSwitch from '../YesNoSwitch';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewPoll extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            id: 0,
            title: '',
            date_from: '',
            date_to: '',
            active: 1,
            only_clients: 0,
            open: false,
            someChanges: false,
            urlToRedirect: null,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeTitle = this.onChangeTitle.bind(this)
        this.onChangeDateFrom = this.onChangeDateFrom.bind(this)
        this.onChangeDateTo = this.onChangeDateTo.bind(this)
        this.onChangeActive = this.onChangeActive.bind(this)
        this.onChangeOnlyClients = this.onChangeOnlyClients.bind(this)
        this.setOpen = this.setOpen.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.pollId != null) {
            trackPromise(
                axios.get(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/polls/${params.pollId}`, {
                    headers: {
                    'Access-Control-Allow-Origin': '*',
                    }
                })
                    .then(res => {
                        this.setState({ 
                            id: params.pollId,
                            title: res.data["title"], 
                            date_from: new Date(res.data["date_from"]),
                            date_to: new Date(res.data["date_to"]),
                            active: res.data["active"],
                            only_clients: res.data["only_clients"],
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            );
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.goToPolls();
            return
        }

        const obj = {
            title: this.state.title,
            active: this.state.active,
            only_clients: this.state.only_clients,
            date_from: format(this.state.date_from, "yyyy-MM-dd"),
            date_to: format(this.state.date_to, "yyyy-MM-dd"),
        };

        if (this.state.id !== 0) {
            trackPromise(
                axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/polls/${this.state.id}`, obj, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then((res) => {
                    this.setState({ open: true, message: 'Encuesta actualizada!' })
                }).catch((error) => {
                    console.log(error)
                })
            );
        } else {
            trackPromise(
                axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/polls`, obj, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then((res) => {
                    this.setState({ open: true, message: 'Encuesta agregada!' })
                }).catch((error) => {
                    console.log(error)
                })
            );
        }
    }

    onChangeTitle(e) {
        this.setState({ title: e.target.value, someChanges:true })
    }

    onChangeDateFrom(e) {
        this.setState({ date_from: e, someChanges: true })
    }

    onChangeDateTo(e) {
        this.setState({ date_to: e, someChanges: true })
    }

    onChangeActive(e) {
        this.setState({ active: e.target.value, someChanges: true })
    }

    onChangeOnlyClients(e) {
        let v = e ? 1 : 0
        this.setState({ only_clients: v, someChanges: true })
    }

    setOpen(value) {
        this.setState({ open: value })
    }

    goToPolls() {
        const { match: { params } } = this.props;
        this.setState({ urlToRedirect: `/stores/${params.storeId}/branches/${params.branchId}/polls` });
    }

    render() {
        if (this.state.urlToRedirect !== null) {
            sessionStorage.removeItem("poll_name");
            return <Redirect to={this.state.urlToRedirect} />
        }

        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">Nueva Encuesta</h1>
                <br />
    
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputName">Título</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Título" 
                                    value={this.state.title} onChange={this.onChangeTitle} />
                                </div>

                                <div className="form-group col-md-3">
                                    <label for="inputDateFrom">Fecha Inicio</label>
                                    <br />
                                    <DatePicker id="inputDateFrom" className="form-control" dateFormat="dd/MM/yyyy" selected={this.state.date_from} onChange={this.onChangeDateFrom} maxDate={addMonths(new Date(), 12)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label for="inputDateTo">Fecha Finalización</label>
                                    <br />
                                    <DatePicker id="inputDateTo" className="form-control" dateFormat="dd/MM/yyyy" selected={this.state.date_to} onChange={this.onChangeDateTo} maxDate={addMonths(new Date(), 12)} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6 text-center">
                                    <h6>Sólo disponible para clientes</h6>
                                    <YesNoSwitch key="only_clients" onChange={this.onChangeOnlyClients} on={this.state.only_clients === 1} />
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.goToPolls(this)}>
                    <Alert onClose={() => this.goToPolls(this)} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        )
    }

}

export default NewPoll