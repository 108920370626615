import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { addMonths, format } from 'date-fns';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewCuponCheck extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            title: '',
            messages: '',
            date_from: '',
            date_to: '',
            information_encoded: '',
            type_code: 'qrcode',
            precio_from: 0,
            precio_to: 1000000,
            active: 1,
            file: '',
            base64: '',
            url: '',
            open: false,
            someChanges: false,
            urlToRedirect: null,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeTitle = this.onChangeTitle.bind(this)
        this.onChangeLines = this.onChangeLines.bind(this)
        this.onChangeDateFrom = this.onChangeDateFrom.bind(this)
        this.onChangeDateTo = this.onChangeDateTo.bind(this)
        this.onChangePriceFrom = this.onChangePriceFrom.bind(this)
        this.onChangePriceTo = this.onChangePriceTo.bind(this)
        this.onChangeTypeCode = this.onChangeTypeCode.bind(this)
        this.onChangeInformationEncoded = this.onChangeInformationEncoded.bind(this)
        this.onChangeActive = this.onChangeActive.bind(this)
        this.setOpen = this.setOpen.bind(this)
        this.goToList = this.goToList.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.cuponCheckId != null) {
            trackPromise(
                axios.get(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/cupon_checks/${params.cuponCheckId}`, {
                    headers: {
                    'Access-Control-Allow-Origin': '*',
                    }
                })
                    .then(res => {
                        this.setState({ 
                            title: res.data["title"], 
                            url: res.data["logo_url"],
                            messages: res.data["messages"].join('\n'), 
                            information_encoded: res.data["information_encoded"],
                            type_code: res.data["type_code"],
                            date_from: new Date(res.data["date_from"]),
                            date_to: new Date(res.data["date_to"]),
                            active: res.data["active"],
                            precio_from: res.data["precio_from"],
                            precio_to: res.data["precio_to"],
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            );
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        }

        let linesArray = this.state.messages.split('\n');

        var image = ''
        if (this.state.base64 !== '') {
            image = this.state.base64;
        } else {
            image = this.state.url;
        }

        const obj = {
            title: this.state.title,
            messages: linesArray,
            logo_url: image,
            information_encoded: this.state.information_encoded,
            type_code: this.state.type_code,
            active: this.state.active,
            precio_from: this.state.precio_from,
            precio_to: this.state.precio_to,
            date_from: format(this.state.date_from, "yyyy-MM-dd"),
            date_to: format(this.state.date_to, "yyyy-MM-dd"),
        };

        var url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/cupon_checks`;
        var message = 'Cupon Check agregado!';
        if (params.cuponCheckId != null) {
            url = url + `/${params.cuponCheckId}`
            message = 'Cupon Check editado!';
        }

        trackPromise(
            axios.post(url, obj, {
                headers: { 'Access-Control-Allow-Origin': '*', } })
                .then((res) => {
                    this.setState({ open: true, message: message })
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    onChangeTitle(e) {
        this.setState({ title: e.target.value, someChanges:true })
    }

    onChangePriceFrom(e) {
        if (e.target.value == "") {
            this.setState({ precio_from: null, someChanges:true })   
        } else {
            this.setState({ precio_from: parseInt(e.target.value, 10), someChanges:true })   
        }
    }

    onChangePriceTo(e) {
        if (e.target.value == "") {
            this.setState({ precio_to: null, someChanges:true })   
        } else {
            this.setState({ precio_to: parseInt(e.target.value, 10), someChanges:true })   
        }
    }

    onChangeLines(e) {
        this.setState({ messages: e.target.value, someChanges: true })
    }

    onChangeDateFrom(e) {
        this.setState({ date_from: e, someChanges: true })
    }

    onChangeDateTo(e) {
        this.setState({ date_to: e, someChanges: true })
    }

    onChangeInformationEncoded(e) {
        this.setState({ information_encoded: e.target.value, someChanges: true })
    }

    onChangeTypeCode(e) {
        this.setState({ type_code: e.target.value, someChanges: true })
    }

    onChangeActive(e) {
        this.setState({ active: e.target.value, someChanges: true })
    }

    setOpen(value) {
        this.setState({ open: value })
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: URL.createObjectURL(file),
                    base64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    render() {
        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }

        let previewDiv, defined_class;
        if (this.state.file) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.file} alt='' /></div>
            defined_class = "col-md-9"
        } else if (this.state.url !== '') {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.url} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }

        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">Nuevo Cupon Check</h1>
                <br />
    
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputName">Título</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Título" 
                                    value={this.state.title} onChange={this.onChangeTitle} />
                                </div>

                                <div className="form-group col-md-3">
                                    <label htmlFor="inputTypeCode">Tipo de Codigo</label>
                                    <select id="selectTypeCode" className="form-control" 
                                    value={this.state.type_code} onChange={this.onChangeTypeCode}>
                                        <option value="qrcode">Codigo QR</option>
                                        <option value="barcode">Codigo de Barra</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputLines">Líneas</label>
                                    <textarea class="form-control" id="inputLines" rows="4" cols="120" onChange={this.onChangeLines} defaultValue={this.state.messages} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputInformationEncoded">Información del código</label>
                                    <textarea class="form-control" id="inputInformationEncoded" rows="5" cols="120" onChange={this.onChangeInformationEncoded} defaultValue={this.state.information_encoded} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label for="inputDateFrom">Fecha Inicio</label>
                                    <br />
                                    <DatePicker id="inputDateFrom" className="form-control" dateFormat="dd/MM/yyyy" selected={this.state.date_from} onChange={this.onChangeDateFrom} maxDate={addMonths(new Date(), 12)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label for="inputDateTo">Fecha Finalización</label>
                                    <br />
                                    <DatePicker id="inputDateTo" className="form-control" dateFormat="dd/MM/yyyy" selected={this.state.date_to} onChange={this.onChangeDateTo} maxDate={addMonths(new Date(), 12)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="inputPrecioDesde">Precio Desde</label>
                                    <input className="form-control" type="text" 
                                    id="inputPrecioDesde" placeholder="1" 
                                    value={this.state.precio_from} onChange={this.onChangePriceFrom} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="inputPrecioTo">Precio Hasta</label>
                                    <input className="form-control" type="text" 
                                    id="inputPrecioTo" placeholder="1" 
                                    value={this.state.precio_to} onChange={this.onChangePriceTo} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="row align-items-center">
                                    {previewDiv}
                                    <div className={defined_class}>
                                        <label htmlFor="inputFile">Logo (Max. 10MB)</label>
                                        <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.goToList()}>
                    <Alert onClose={() => this.goToList()} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        )
    }

    goToList() {
        const { match: { params } } = this.props;
        this.setState({ urlToRedirect: `/stores/${params.storeId}/branches/${params.branchId}/cupon_checks` })
    }

}

export default NewCuponCheck