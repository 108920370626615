import React, { Component } from 'react';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

class BranchMenu extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            branch: null,
            store: null,
            menu: null,
            category: null,
            loaded: false
        }

        this.reloadData = this.reloadData.bind(this)
    }

    componentDidMount() {
        this.reloadData()
    }

    reloadData() {
        trackPromise(
            axios.get(`https://fidely20backend.azurewebsites.net/stores/${this.props.storeID}/branches/${this.props.branchID}/menu`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then(res => {
                    this.setState({ branch: res.data["branch"], store: res.data["store"], menu: res.data["menu"], loaded: true });
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }
    
    categoriesContent = () => {
        return (
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col text-left my-auto">
                            <h6 className="m-0 font-weight-bold" style={{fontSize: "1.5rem", color: "#000000"}}>Nuestro catálogo</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-columns">
                        { this.state.menu.map((menu) => (
                            <div className="card" key={menu.category.id} onClick={() => this.setState({category: menu})}>
                                <div className="view overlay">
                                    <img className="card-img-top" src={`${menu.category.image_path}`} alt={menu.category.name} style={{height: "100%", width: "100%", objectFit: "cover"}} />
                                </div>

                                <div className="card-body">
                                    <h4 className="card-title font-weight-bold text-gray-800">{menu.category.name}</h4>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        )
    }

    productsContent = () => {
        return (
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-2">
                            <img alt={`${this.state.category.name}`} src={`${this.state.category.category.image_path}`} className="card-img" style={{height: "100%", width: "100%", objectFit: "cover"}} />
                        </div>
                        <div className="col text-left my-auto">
                            <h6 className="m-0 font-weight-bold" style={{fontSize: "1.5rem", color: "#000000"}}>{this.state.category.category.name}</h6>
                        </div>
                        <div className="col text-right my-auto">
                            <a onClick={() => this.setState({ category: null})}>Volver al catálogo</a>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="card-columns">
                        { this.state.category.products.map((product) => (
                            this.productContent(product)
                        ))
                        }
                    </div>
                    <br />
                    <div className="row justify-content-md-center">
                        <a onClick={() => this.setState({ category: null})}>Volver al catálogo</a>
                    </div>
                </div>
            </div>
        )
    }

    productContent = (product) => {
        let op, featuredText, featuredColor, availableText, availableColor
        if (product.available === 1) {
            op = "100%"
            availableText = ""
            availableColor = ""
        } else {
            op = "50%"
            availableText = "NO DISPONIBLE"
            availableColor = "#FF0000"
        }

        if (product.featured === 1) {
            featuredText = "DESTACADO"
            featuredColor = "#228B22"
        } else {
            featuredText = ""
            featuredColor = ""
        }

        return (
            <div className="card" style={{opacity: op}}>
                <div className="view overlay">
                    <img className="card-img-top" src={`${product.image_path}`} alt={`${product.name}`} style={{height: "100%", width: "100%", objectFit: "cover"}} />
                </div>

                <div className="card-body">
                    <h4 className="card-title font-weight-bold">{product.name}</h4>
                    <p className="mb-2">$ {product.price}</p>
                    <p className="card-text">{product.description}</p>
                    <p className="card-text font-weight-bold" style={{color: `${featuredColor}`}}>{featuredText}</p>
                    <p className="card-text font-weight-bold" style={{color: `${availableColor}`}}>{availableText}</p>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.loaded === false) {
            return <p>Estamos cargando el catálogo...</p>
        }

        let content = ""
        
        if (this.state.category != null) {
            content = this.productsContent();
        } else {
            content = this.categoriesContent();
        }
        
        return(
            <div className="container" style={{paddingLeft: "0rem", paddingRight: "0rem"}}>
                <div className="card shadow mb-4">
                    <div className="card-header py-3" style={{backgroundColor: "#000000"}} >
                        <div className="row">
                            <div className="col-2">
                                <img alt={`${this.state.store.name}`} src={`${this.state.store.logo_url}`} className="card-img" style={{height: "100%", width: "100%", objectFit: "cover"}} />
                            </div>
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold" style={{fontSize: "2rem", color: "#FFFFFF"}}>{this.state.store.name} - {this.state.branch.name}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{padding: "1rem"}}>
                        {content}
                    </div>
                </div>
            </div>
        )
    }
}

export default BranchMenu