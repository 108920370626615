import React, { Component } from 'react';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import CardStore from './CardStore';
import Error from '../Error';


class Store extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            store: null
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.storeId !== undefined) {
            trackPromise(
                axios.get(`${this.context.hubURL}/stores/${params.storeId}`, {
                    headers: { 'Access-Control-Allow-Origin': '*', }
                })
                    .then(res => {
                        this.setState({ store: res.data });
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            );
        }
    }

    render() {
        if (this.state.store === null) {
            return (<div></div>)
        }

        if(this.context.user.is_admin === "0") {
            if (this.context.user.store_id !== this.state.store.id  || this.context.user.branch_id !== null) {
                return <Error message="No tienes acceso a esta sección." />
            }
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col text-left my-auto">
                        <h3 className="h3 mb-4 text-gray-800">{this.state.store.name}</h3>
                    </div>
                </div>
                <div className="row">
                    <CardStore key={this.state.store.id} store={this.state.store} />
                </div>
            </div>
        )
    }
}

export default Store