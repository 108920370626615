import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Pagination } from '@material-ui/lab';
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { TableHeader } from 'semantic-ui-react';
import CustomFieldsUtils from '../../utils/customFieldsUtils';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Clients extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            clients: [],
            open: false,
            clientTransactions: null,
            showModal: false,
            client: null,
            transactions: null,
            filtered_transactions: null,
            branches: null,
            error: '',
            message: '',
            branch_selected: '',
            transaction_type: '',
            lookupClient: null,
            branches: null,
            pageClients: 0,
            rowsPerPageClients: 5,
            page: 0,
            rowsPerPage: 5,
        }

        this.onDeleted = this.onDeleted.bind(this)
        this.onLookupFormSubmitted = this.onLookupFormSubmitted.bind(this)
        this.onChangeLookupClient = this.onChangeLookupClient.bind(this)
        this.onChangeBranchSearch = this.onChangeBranchSearch.bind(this)
        this.onChangeTransactionTypeSearch = this.onChangeTransactionTypeSearch.bind(this)
        this.onChangeLookupClient = this.onChangeLookupClient.bind(this)
        this.onFilterFormSubmitted = this.onFilterFormSubmitted.bind(this)
        this.exportData = this.exportData.bind(this)
    }

    exportData() {
        var wb = XLSX.utils.book_new();
        wb.Props = {
                Title: "Clientes",
                Subject: "Fidely Backoffice",
                Author: "Fidely",
                CreatedDate: new Date()
        };
        
        wb.SheetNames.push("Clientes");
        var ws_data = [['ID' , 'Nombre', 'Apellido', 'Documento', 'Fecha Nacimiento', 'Genero', 'Email', 'Telefono', 'Tipo Cliente', 'Puntos', 'Puntos Pesos', 'Nro. Tarjeta', 'Fecha Alta', 'Más Datos']];

        this.state.clients.map((c) => {
            let customFieldsData, customFieldsToCSV
            if (c.custom_fields !== null && c.custom_fields.length > 0) {
                customFieldsData = c.custom_fields.map(field => ({
                    Name: field.name,
                    TextValue: field.text_field ? field.text_field : null,
                    MultipleChoiceValue: field.multiple_choice_field ? field.multiple_choice_field : null,
                }));      
                customFieldsToCSV = customFieldsData.map( field => {
                    return Object.entries(field)
                    .filter(([key, value]) => value !== null)
                    .map(([key, value]) => (value !== null ? (key === 'name' ? `${value}:` : value) : ''))
                    .join(': ');
                }).join('\n');
            }
  
            return ws_data.push([
                c.id, 
                c.name, 
                c.last_name, 
                c.identification, 
                c.born_date, 
                c.sex, 
                c.email, 
                c.phone, 
                c.client_type_name, 
                c.points, 
                c.cash, 
                c.card_number, 
                c.created_at, 
                customFieldsToCSV,
            ])
        })

        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        wb.Sheets["Clientes"] = ws;

        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
        }
        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'clientes.xlsx');
    }

    onChangeBranchSearch(e) { 
        this.setState({
            branch_selected: e.target.value,
        }) 
    }
    
    onChangeTransactionTypeSearch(e) { 
        this.setState({
            transaction_type: e.target.value
        }) 
    }

    onFilterFormSubmitted(e) {
        e.preventDefault();
        //this.exportTransactions();
        var tx = this.state.clientTransactions;

        if (this.state.branch_selected !== '') {
            tx = tx.filter(t => t.branch_id == this.state.branch_selected);
        }

        if (this.state.transaction_type !== '') {
            tx = tx.filter(t => t.type == this.state.transaction_type);
        }

        this.setState({ filtered_transactions: tx })
    }
    
    onDeleted(deletedURL) {
        trackPromise(
            axios.post(deletedURL, null, { headers: { 'Access-Control-Allow-Origin': '*', } })
            .then((res) => {
                this.setState({ open: true, message: 'Cliente eliminado.' });
            }).catch((error) => {
                console.log(error)
            })
        );
    }

    onChangeLookupClient(e) {
        this.setState({ lookupClient: e.target.value })
    }

    onLookupFormSubmitted(e) {
        e.preventDefault()

        if (this.state.lookupClient !== null) {
            this.searchClients(this.state.lookupClient);
        }
    }

    lookupClientRequests(lookup) {
        const { match: { params } } = this.props;

        var lk = null
        if (lookup !== null) {
            lk = lookup
        } else if (this.state.lookupClient !== null) {
            lk = this.state.lookupClient
        } 

        if(lk != null) {
            let lookup = `${this.context.hubURL}/stores/${params.storeId}/clients/${lk}`
            // let lookup = `http://localhost:8080/stores/${params.storeId}/clients/${lk}`
            let transactions = `${this.context.hubURL}/stores/${params.storeId}/clients/${lk}/transactions`
            let branches = `${this.context.hubURL}/stores/${params.storeId}/branches`;
            const requestOne = axios.get(lookup);
            const requestTwo = axios.get(transactions);
            const requestThree = axios.get(branches);
            axios.all([requestOne, requestTwo, requestThree], { headers: { 'Access-Control-Allow-Origin': '*'} }).then(axios.spread((...responses) => {
                const responseOne = responses[0]
                const responseTwo = responses[1]
                const responseThree = responses[2]
                console.log(`Client: ${responses[0].data.custom_fields}`)
                this.setState({ client: responseOne.data, clientTransactions: responseTwo.data, filtered_transactions: responseTwo.data, branches: responseThree.data["branches"], showModal: true, lookupClient: null })
            })).catch(errors => {
                // react on errors.
            })
        }
    }

    searchClients(searchValue) {
        const { match: { params } } = this.props;

        const obj = {
            search_value: searchValue
        };
        
        let url = `${this.context.hubURL}/stores/${params.storeId}/search_clients`;
        // let url = `http://localhost:8080/stores/${params.storeId}/search_clients`;
        trackPromise(
            axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*' }})
            .then((res) => { 
                if(res !== null) {
                    this.setState({ clients: res.data, open: false });
                } else {
                    alert("La búsqueda no arrojó resultados.");
                }
            })
            .catch((error) => { 
                console.log(error);
                alert("La búsqueda no arrojó resultados.");
            })
        );
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        
        let url = `${this.context.hubURL}/stores/${params.storeId}/clients`
        // let url = `http://localhost:8080/stores/${params.storeId}/clients`
        
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                .then(res => { this.setState({ clients: res.data, open: false }); })
                .catch(function (error) { console.log(error); })
        );
    }

    onSelectedClient(c) {
        console.log(c)
        if (c.identification !== "") {
            this.lookupClientRequests(c.identification)
        } else if (c.card_number !== "") {
            this.lookupClientRequests(c.card_number)
        } else {

        }
    }

    handleChangePageClients(event, newPage) {
        this.setState({ pageClients: newPage });
      }
      
      handleChangeRowsPerPageClients(event) {
        this.setState({ rowsPerPageClients: parseInt(event.target.value, 10), pageClients: 0});
      }

      handleChangePage(event, newPage) {
        this.setState({ page: newPage });
      }
      
      handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0});
      }


    render() {
        const { match: { params } } = this.props;

        let content, modalTitle, modalBody, modalTransactions, branchesSelect, buscador;
        
        if(this.state.client != null) {
            modalTransactions = <div className="row"><p>Este cliente aún no tiene transacciones.</p></div>
            if(this.state.clientTransactions != null) {
                if(this.state.branches != null) {
                    branchesSelect = <div className="form-group col-md-3">
                        <select id="selectBranch" className="form-control" value={this.state.branch_id} onChange={this.onChangeBranchSearch}>
                        <option value="">--- Sucursal ---</option>
                            { this.state.branches.map((branch) => (
                                <option value={branch.id}>{branch.name}</option>
                            )) 
                            }
                        </select>
                    </div>
                }
                buscador = <div className="card shadow mb-4" style={{padding: "0"}}>
                <div className="card-header py-3">
                    <div className="row">
                        <h6 className="m-0 font-weight-bold text-primary">Filtrar Transacciones</h6>
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={this.onFilterFormSubmitted}>
                        <div className="form-row align-items-center">
                            {branchesSelect}
                            <div className="form-group col-md-3">
                                <select id="selectTransactionType" className="form-control" value={this.state.transaction_type} onChange={this.onChangeTransactionTypeSearch}>
                                    <option value="">--- Tipo Transacción ---</option>
                                    <option value="PURCHASE">Venta</option>
                                    <option value="GIFTCARD_DOWNLOAD">Descarga</option>
                                    <option value="GIFTCARD_LOAD">Carga</option>
                                    <option value="SWAP">Canje</option>
                                    <option value="ANNULMENT">Anulación</option>
                                </select>
                            </div>
                            <div className="form-group col-md-3 text-center">
                                <button type="submit" className="btn btn-primary">Buscar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            modalTransactions = <div className="row">
                {buscador}
                <div className="row">
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader>
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Fecha y hora</TableCell>
                        <TableCell align="center">Comercio</TableCell>
                        <TableCell align="center">Título</TableCell>
                        <TableCell align="center">Monto Final</TableCell>
                        <TableCell align="center">Monto Original</TableCell>
                        <TableCell align="center">Descuento</TableCell>
                        <TableCell align="center">Nro. Ticket</TableCell>
                        <TableCell align="center">Cliente</TableCell>
                        <TableCell align="center">Tarjeta</TableCell>
                    </TableHeader>
                    <TableBody>
                    {(this.state.rowsPerPage > 0 ? this.state.filtered_transactions.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage) : this.state.filtered_transactions).map((t, index) => (
                        <TableRow hover key={t.id}>
                            <TableCell align="center">{t.id}</TableCell>
                            <TableCell align="center">{new Date(t.date).toLocaleString('es')}</TableCell>
                            <TableCell align="center">{t.branch_name}</TableCell>
                            <TableCell align="center">{t.reason}</TableCell>
                            <TableCell align="center">{t.final_amount}</TableCell>
                            <TableCell align="center">{t.amount}</TableCell>
                            <TableCell align="center">{t.discount}</TableCell>
                            <TableCell align="center">{t.ticket_id}</TableCell>
                            <TableCell align="center">{t.user_name} {t.user_last_name} ({t.user_identification})</TableCell>
                            <TableCell align="center">{t.card_number}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination count={this.state.filtered_transactions.length} rowsPerPage={this.state.rowsPerPage} labelRowsPerPage="Items por página"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]} onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                            onChangePage={this.handleChangePage.bind(this)} page={this.state.page} />
                        </TableRow>
                    </TableFooter>
                </Table>
                    </div> 
                </div>
            }
            modalTitle = this.state.client.name + " " + this.state.client.last_name
            const customFieldsUtils = new CustomFieldsUtils()
            modalBody = <div className="card-body">
                <div className="row">
                    <div class="col-sm-3">
                        <p className="mb-1"><small><span className="font-weight-bold">DNI:</span> {this.state.client.identification}</small></p>
                        <p className="mb-1"><small><span className="font-weight-bold">Email:</span> {this.state.client.email}</small></p>
                        <p className="mb-1"><small><span className="font-weight-bold">Teléfono:</span> {this.state.client.phone}</small></p>
                    </div>
                    <div class="col-sm-3">
                    <p className="mb-1"><small><span className="font-weight-bold">Fecha Nacimiento:</span> {this.state.client.born_date}</small></p>
                        <p className="mb-1"><small><span className="font-weight-bold">Sexo:</span> {this.state.client.sex == "F" ? "Mujer" : "Hombre"}</small></p>
                        <p className="mb-1"><small><span className="font-weight-bold">Tipo Cliente:</span> {this.state.client.client_type_name}</small></p>
                    </div>
                    <div class="col-sm-3">
                    <p className="mb-1"><small><span className="font-weight-bold">Fecha de Alta:</span> {this.state.client.created_at}</small></p>
                    <p className="mb-1"><small><span className="font-weight-bold">Nro. Tarjeta:</span> {this.state.client.card_number}</small></p>
                        <p className="mb-1"><small><span className="font-weight-bold">Puntos:</span> {new Intl.NumberFormat('es', {minimumFractionDigits: 0, maximumFractionDigits: 2, }).format(this.state.client.points)}</small></p>
                        <p className="mb-1"><small><span className="font-weight-bold">Puntos ($):</span> {new Intl.NumberFormat('es', {minimumFractionDigits: 0, maximumFractionDigits: 2, }).format(this.state.client.cash)}</small></p>
                    </div>
                    { this.state.client.custom_fields?.length > 0 &&
                        this.state.client.custom_fields.map(customField => (
                            <div class="col-sm-3">
                                {
                                    customField.type === customFieldsUtils.CustomFieldTypes.TEXT ?
                                        <p className="mb-1"><small><span className="font-weight-bold">
                                            {customField.name}:</span> {customField.text_field}</small>
                                        </p>
                                    : 
                                        <p className="mb-1"><small><span className="font-weight-bold">
                                            {customField.name}:</span> {customField.multiple_choice_field}</small>
                                        </p>
                                }
                            </div>
                        ))
                    }
                </div>
                {modalTransactions}
            </div>
        }

        if(this.state.clients != null) {
            content = <div className="card-body">
                <div className="row" style={{overflowX: "auto"}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">Nombre y Apellido</TableCell>
                        <TableCell align="center">Tipo Cliente</TableCell>
                        <TableCell align="center">Documento</TableCell>
                        <TableCell align="center">Fecha Nacimiento</TableCell>
                        <TableCell align="center">Género</TableCell>
                        <TableCell align="center">Acciones</TableCell>
                    </TableHeader>
                    <TableBody>
                    {(this.state.rowsPerPageClients > 0 ? this.state.clients.slice(this.state.pageClients * this.state.rowsPerPageClients, this.state.pageClients * this.state.rowsPerPageClients + this.state.rowsPerPageClients) : this.state.clients).map((c, index) => (
                        <TableRow hover key={c.id}>
                            <TableCell align="center">{c.id}</TableCell>
                            <TableCell align="center">{c.name} {c.last_name}</TableCell>
                            <TableCell align="center">{c.client_type_name}</TableCell>
                            <TableCell align="center">{c.identification}</TableCell>
                            <TableCell align="center">{c.born_date}</TableCell>
                            <TableCell align="center">{c.sex == "F" ? "Mujer" : "Hombre"}</TableCell>
                            <TableCell align="center">
                                    <a onClick={this.onSelectedClient.bind(this, c)} className="btn btn-sm btn-primary"><span className="icon text-white-50"><i className="fas fa-info"></i></span></a>
                                    &nbsp;&nbsp;&nbsp;
                                    <Link to={`/stores/${params.storeId}/clients/${c.id}/edit`} className="btn btn-sm btn-success"><span className="icon text-white-50"><i className="fas fa-edit"></i></span></Link>
                                    &nbsp;&nbsp;&nbsp;
                                    <a onClick={this.onDeleted.bind(this, `${this.context.hubURL}/stores/${params.storeId}/clients/${c.id}/delete`)} data-tag={c.id} className="btn btn-sm btn-danger"><span className="icon text-white-50"><i className="fas fa-trash"></i></span></a>
                                    </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination count={this.state.clients.length} rowsPerPage={this.state.rowsPerPageClients} labelRowsPerPage="Items por página"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]} onChangeRowsPerPage={this.handleChangeRowsPerPageClients.bind(this)}
                            onChangePage={this.handleChangePageClients.bind(this)} page={this.state.pageClients} />
                        </TableRow>
                    </TableFooter>
                </Table>
                    
                </div>
            </div>    
        } else {
            content = <p>No hay clientes en esta marca.</p>
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-9 text-left">
                        <h3 className="h3 mb-4 text-gray-800">Clientes</h3>
                    </div>
                    <div className="col-md-1 text-right">
                        <Link to={`/stores/${params.storeId}/clients/new`} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                    </div>
                    <div className="col-md-1 text-right">
                        <Link to={`/stores/${params.storeId}/clients/upload`} className="btn btn-primary"><span className="icon text-white-50"><i className="fas fa-cloud-upload-alt"></i></span></Link>
                    </div>
                    <div className="col-md-1 text-right">
                        <a onClick={this.exportData} className="btn btn-secondary"><span className="icon text-white-50"><i className="fas fa-cloud-download-alt"></i></span></a>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <h6 className="m-0 font-weight-bold text-primary">Buscar cliente</h6>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.onLookupFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className="form-group col-md-10">
                                    <input className="form-control" type="text" id="inputLookupClient" 
                                    placeholder="Nro. Documento, Nro. Tajeta, ID de usuario" onChange={this.onChangeLookupClient} />
                                </div>

                                <div className="form-group col-md-2 text-center">
                                    <button type="submit" className="btn btn-primary">Buscar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false, client: null, clientTransactions: null, lookupClient: null}) } size="xl">
                    <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalBody}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({showModal: false, client: null, clientTransactions: null, lookupClient: null}) }>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default Clients