import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import YesNoSwitch from '../YesNoSwitch';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class StoreCheckpage extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            file: '',
            base64: '',
            url: '',
            show_cash: 0,
            show_points: 0,
            pathname: '',
            open: false,
            success: false
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangePathName = this.onChangePathName.bind(this)
        this.onChangeShowCash = this.onChangeShowCash.bind(this)
        this.onChangeShowPoints = this.onChangeShowPoints.bind(this)
        this.setOpen = this.setOpen.bind(this)
        this.setSuccess = this.setSuccess.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        let url = `${this.context.hubURL}/stores/${params.storeId}/checkpage`;

        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*', }})
                .then(res => {
                    this.setState({ pathname: res.data["pathname"], show_cash: res.data["show_cash"], show_points: res.data["show_points"], url: res.data["background_url"] });
                })
                .catch(function (error) { console.log(error); })
        );
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        var image = ''
        if (this.state.base64 !== '') {
            image = this.state.base64;
        } else {
            image = this.state.url;
        }

        const obj = {
            pathname: this.state.pathname,
            background_url: image,
            show_points: this.state.show_points,
            show_cash: this.state.show_cash,
        };
        
        let url = `${this.context.hubURL}/stores/${params.storeId}/checkpage`;

        trackPromise(
            axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*', } })
                .then((res) => { this.setOpen(true) })
                .catch((error) => { console.log(error) })
        );
    }
    
    onChangePathName(e) {
        this.setState({ pathname: e.target.value })
    }

    onChangeShowCash(e) {
        let v = e ? 1 : 0
        this.setState({ show_cash: v })
    }

    onChangeShowPoints(e) {
        let v = e ? 1 : 0
        this.setState({ show_points: v })
    }
    
    setOpen(value) {
        this.setState({ open: value })
    }

    setSuccess(value) {
        this.setState({ success: value })
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: URL.createObjectURL(file),
                    base64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    render() {
        if (this.state.success) {
            return <Redirect to="/stores" />
        }

        let previewDiv, defined_class;
        if (this.state.file) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.file} alt='' /></div>
            defined_class = "col-md-9"
        } else if (this.state.url !== '') {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.url} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }

        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">Configuración Sitio Consulta Saldo</h1>
                <br />

                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className={defined_class}>
                                    <label htmlFor="inputFile">Imagen de fondo (Max. 10MB)</label>
                                    <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                </div>
                                {previewDiv}
                            </div>

                            <div className="form-group col-md-12">
                                <label for="inputPortalURL">Nombre Web</label>
                                <input className="form-control" type="text" id="inputPathname" 
                                placeholder="nombreempresa"
                                value={this.state.pathname} onChange={this.onChangePathName} />
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4 text-center">
                                    <h6>Mostrar Puntos</h6>
                                    <YesNoSwitch key="show_points" onChange={this.onChangeShowPoints} on={this.state.show_points === 1} />
                                </div>
                                <div className="form-group col-md-4 text-center">
                                    <h6>Mostrar Dinero</h6>
                                    <YesNoSwitch key="show_cash" onChange={this.onChangeShowCash} on={this.state.show_cash === 1} />
                                </div>
                            </div>
                            <div className="form-row">
                                <p>Recordá que la URL para acceder será: <b style={{color: "red"}}>https://{window.location.hostname}/saldo/{this.state.pathname}</b></p>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.setState({success: true})}>
                    <Alert onClose={() => this.setState({success: true})} severity="success">
                        Datos guardados correctamente!
                    </Alert>
                </Snackbar>

            </div>
        )
    }

}

export default StoreCheckpage