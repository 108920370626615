import React, { Component } from 'react';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CuponChecks extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            cupon_checks: null,
            urlToRedirect: null,
            message: '',
            open: false
        }

        this.reloadData = this.reloadData.bind(this)
    }

    onDeleted(deletedURL) {
        trackPromise(
            axios.post(deletedURL, null, { headers: { 'Access-Control-Allow-Origin': '*', } })
            .then((res) => {
                this.setState({ open: true, message: 'Cupon Check eliminado.' });
            }).catch((error) => {
                console.log(error)
            })
        );
    }

    componentDidMount() {
        sessionStorage.removeItem("cupon_check_name");

        this.reloadData()
    }

    customRedirect(urlToRedirect, cuponCheckName) {
        sessionStorage.setItem("cupon_check_name", cuponCheckName);
        this.setState({ urlToRedirect: urlToRedirect });
    }

    reloadData() {
        const { match: { params } } = this.props;

        if (params.branchId !== undefined) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/cupon_checks`;

            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then(res => {
                    this.setState({ cupon_checks: res.data, open: false });
                })
                .catch(function (error) { console.log(error); })
            );
        }
    }
    
    render() {
        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }

        const { match: { params } } = this.props;
        
        let content, newURL
        if (params.branchId !== undefined) {
            newURL = `/stores/${params.storeId}/branches/${params.branchId}/cupon_checks/new`
        }
        
        if(this.state.cupon_checks != null) {
            content = <div className="card-body">
                <div className="row">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Cupon Check</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.cupon_checks.map((cc) => (
                            <tr>
                                <td>{cc.id}</td>
                                <td>{cc.title}</td>
                                <td>
                                    <a onClick={this.customRedirect.bind(this, `/stores/${params.storeId}/branches/${params.branchId}/cupon_checks/${cc.id}/edit`, cc.title)} className="btn btn-sm btn-success"><span className="icon text-white-50"><i className="fas fa-pencil-alt"></i></span></a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a onClick={this.onDeleted.bind(this, `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/cupon_checks/${cc.id}/delete`)} data-tag={cc.id} className="btn btn-sm btn-danger"><span className="icon text-white-50"><i className="fas fa-trash"></i></span></a>
                                </td>
                            </tr> ))}
                        </tbody>
                    </table>
                </div>
            </div>    
        } else {
            content = <p>No hay cupon checks creados para esta sucursal.</p>
        }

        return(
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">Cupon Checks</h6>
                            </div>
                            <div className="col text-right my-auto">
                                <Link to={newURL} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                            </div>
                        </div>
                    </div> 
                    <div className="card-body">
                        {content}
                    </div>
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default CuponChecks