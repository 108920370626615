import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewPointOfSale extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            serial: '',
            user: '',
            password: '',
            open: false,
            someChanges: false
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeSerialNumber = this.onChangeSerialNumber.bind(this)
        this.onChangeUser = this.onChangeUser.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.setOpen = this.setOpen.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.posId != null) {
            trackPromise(
                axios.get(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/point_of_sales/${params.posId}`, {
                    headers: {
                    'Access-Control-Allow-Origin': '*',
                    }
                })
                    .then(res => {
                        this.setState({ serial: res.data["serial_number"], user: res.data["user"] });
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            );
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        }

        const obj = {
            user: this.state.user,
            password: this.state.password,
            serial_number: this.state.serial,
        };

        trackPromise(
            axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/point_of_sales`, obj, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    this.setState({ open: true, message: 'Punto de venta agregado!' })
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    onChangePassword(e) {
        this.setState({ password: e.target.value, someChanges:true })
    }

    onChangeUser(e) {
        this.setState({ user: e.target.value, someChanges: true })
    }

    onChangeSerialNumber(e) {
        this.setState({ serial: e.target.value, someChanges: true })
    }

    setOpen(value) {
        this.setState({ open: value })
    }

    render() {
        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">Nuevo Punto de venta</h1>
                <br />
    
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputName">Número de Serie</label>
                                <input className="form-control" type="text" 
                                id="inputName" placeholder="NX0123456789" 
                                value={this.state.serial} onChange={this.onChangeSerialNumber} />
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputName">Usuario</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Usuario POS" 
                                    value={this.state.user} onChange={this.onChangeUser} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="inputName">Clave</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Clave POS" 
                                    value={this.state.password} onChange={this.onChangePassword} />
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.props.history.go(-1)}>
                    <Alert onClose={() => this.props.history.go(-1)} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        )
    }

}

export default NewPointOfSale