import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { addMonths, format } from 'date-fns';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewPollQuestion extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            title: '',
            type: '',
            min_value: 0,
            max_value: 0,
            values: '',
            open: false,
            someChanges: false
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeTitle = this.onChangeTitle.bind(this)
        this.onChangeType = this.onChangeType.bind(this)
        this.onChangeMinValue = this.onChangeMinValue.bind(this)
        this.onChangeMaxValue = this.onChangeMaxValue.bind(this)
        this.onChangeValues = this.onChangeValues.bind(this)
        this.setOpen = this.setOpen.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.pollQuestionId != null) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/polls/${params.pollId}/questions/${params.pollQuestionId}`;
            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*', }})
                    .then(res => {
                        var v = ""
                        if (res.data["values"] != null) {
                            v = res.data["values"].join('\n')
                        }
                        this.setState({ title: res.data["title"], type: res.data["type"], min_value: res.data["min_value"], max_value: res.data["max_value"], values: v, open: false }); 
                    })
                    .catch(function (error) { console.log(error); })
            );
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        }

        if (this.state.type === '') {
            alert("Por favor seleccioná el tipo de pregunta.");
        } else {
            let valuesArray = this.state.values.split('\n');

            const obj = {
                title: this.state.title,
                type: this.state.type,
                min_value: this.state.min_value,
                max_value: this.state.max_value,
                values: valuesArray,
            };

            let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/polls/${params.pollId}/questions`;
            let msg = 'Pregunta agregada!';

            if (params.pollQuestionId != null) {
                url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/polls/${params.pollId}/questions/${params.pollQuestionId}`
                msg = 'Pregunta editada!';
            }

            trackPromise(
                axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*', }})
                    .then((res) => {
                        this.setState({ open: true, message: msg })
                    }).catch((error) => { console.log(error) })
            );
        }
    }

    onChangeTitle(e) {
        this.setState({ title: e.target.value, someChanges:true })
    }

    onChangeType(e) {
        this.setState({ type: e.target.value, someChanges:true })
    }

    onChangeMinValue(e) {
        if (e.target.value == "") {
            this.setState({ min_value: null, someChanges:true })   
        } else {
            this.setState({ min_value: parseInt(e.target.value, 10), someChanges:true })   
        }
    }

    onChangeMaxValue(e) {
        if (e.target.value == "") {
            this.setState({ max_value: null, someChanges:true })   
        } else {
            this.setState({ max_value: parseInt(e.target.value, 10), someChanges:true })   
        }
    }

    onChangeValues(e) {
        this.setState({ values: e.target.value, someChanges:true })
    }

    setOpen(value) {
        this.setState({ open: value })
    }

    render() {
        let title = "Nueva Pregunta";
        if(sessionStorage.getItem("poll_name") !== null) {
            title = sessionStorage.getItem("poll_name") + " - Nueva Pregunta";
        }

        let rowValues = "";
        if (this.state.type === "options") {
            rowValues = <div className="form-row">
                <div className="form-group col-md-12">
                    <label htmlFor="inputLines">Separá los valores con un enter.</label>
                    <textarea class="form-control" id="inputLines" rows="5" cols="120" onChange={this.onChangeValues} defaultValue={this.state.values} />
                </div>
            </div>
        }

        let rowNumbers = "";
        if (this.state.type === "number") {
            rowValues = <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="inputMinValue">Valor mínimo</label>
                    <input className="form-control" type="text" id="inputMinValue" placeholder="0" 
                    value={this.state.min_value} onChange={this.onChangeMinValue} />
                </div>

                <div className="form-group col-md-3">
                <label htmlFor="inputMaxValue">Valor máximo</label>
                    <input className="form-control" type="text" id="inputMaxValue" placeholder="100" 
                    value={this.state.max_value} onChange={this.onChangeMaxValue} />
                </div>
            </div>
        }

        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">{title}</h1>
                <br />
    
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputName">Título</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Título" 
                                    value={this.state.title} onChange={this.onChangeTitle} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label for="selectType">Tipo de Pregunta</label>
                                    <select id="selectType" className="form-control" 
                                    value={this.state.type} onChange={this.onChangeType}>
                                        <option value="">--- Tipo de Pregunta ---</option>
                                        <option value="string">Texto</option>
                                        <option value="number">Número</option>
                                        <option value="options">Opciones</option>
                                    </select>
                                </div>
                            </div>

                            {rowNumbers}

                            {rowValues}

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.props.history.go(-1)}>
                    <Alert onClose={() => this.props.history.go(-1)} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        )
    }

}

export default NewPollQuestion