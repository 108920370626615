import React, { Component } from 'react';
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom';
import CardStore from './CardStore';
import Error from '../Error';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Stores extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            stores: [],
            message: '',
            open: false,
            lookupStore: null
        }

        this.onDeleted = this.onDeleted.bind(this)
        this.onLookupFormSubmitted = this.onLookupFormSubmitted.bind(this)
        this.onChangeLookupStore = this.onChangeLookupStore.bind(this)
    }
    
    onDeleted() {
        this.setState({ open: true, message: 'Empresa eliminada.' });
    }
    
    componentDidMount() {
        sessionStorage.removeItem("store_name");
        sessionStorage.removeItem("branch_name");
        sessionStorage.removeItem("lot_name");
        sessionStorage.removeItem("cupon_check_name");
        sessionStorage.removeItem("pos_name");
        
        this.getStores();
    }

    getStores() {
        let storeId = this.context.user.store_id
        let url = `${this.context.hubURL}/stores`
        if (storeId != null) {
            url = `${url}/${storeId}`
        }

        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*', } })
                .then(res => { this.setState({ stores: res.data, open: false }); })
                .catch(function (error) { console.log(error); })
        );
    }

    onChangeLookupStore(e) {
        this.setState({ lookupStore: e.target.value })
    }

    onLookupFormSubmitted(e) {
        e.preventDefault()

        if (this.state.lookupStore !== null) {
            this.searchStores(this.state.lookupStore);
        } else {
            this.getStores();
        }
    }

    searchStores(searchValue) {
        const { match: { params } } = this.props;

        const obj = {
            search_value: searchValue
        };
        
        let url = `${this.context.hubURL}/search_stores`;
        trackPromise(
            axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*' }})
            .then((res) => { 
                if(res !== null) {
                    this.setState({ stores: res.data, open: false, lookupStore: null });
                } else {
                    alert("La búsqueda no arrojó resultados.");
                }
            })
            .catch((error) => { 
                alert("La búsqueda no arrojó resultados.");
            })
        );
    }

    render() {
        if(this.context.user.is_admin === "0") {
            if (this.context.user.store_id !== null && this.context.user.store_id !== 0 && this.context.user.branch_id === null) {
                return <Redirect to={`/stores/${this.context.user.store_id}`} />
            } else {
                return <Error message="No tienes acceso a esta sección." />
            }
        }
        
        let content
        let addButton
        let buscador = <div className="card shadow mb-4">
            <div className="card-header py-3">
                <div className="row">
                    <h6 className="m-0 font-weight-bold text-primary">Buscar Empresa</h6>
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={this.onLookupFormSubmitted}>
                    <div className="form-row align-items-center">
                        <div className="form-group col-md-10">
                            <input className="form-control" type="text" id="inputLookupStore" 
                            placeholder="Nombre empresa" onChange={this.onChangeLookupStore} />
                        </div>

                        <div className="form-group col-md-2 text-center">
                            <button type="submit" className="btn btn-primary">Buscar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        if (this.state.stores == null) {
            buscador = ""
            content = <div className="card-body">La lista de comercios está vacía.</div>
        } else if (this.state.stores instanceof Array) {
            content = <div className="card-body">
                <div className="card-columns">
                    { this.state.stores.map((store) => (<CardStore  key={store.id} store={store} onDeleted={this.onDeleted} />)) }
                </div>
            </div>
        } else {
            content = <div className="card-body">
                <div className="card-columns">
                { <CardStore  key={this.state.stores.id} store={this.state.stores} onDeleted={this.onDeleted} /> }
                </div>
            </div>
        }

        if (this.context.user.is_admin === "1") {
            addButton = <Link to={`/stores/new`} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
        } else {
            buscador = ""   
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-10 text-left">
                        <h3 className="h3 mb-4 text-gray-800">Empresas</h3>
                    </div>
                    <div className="col text-right my-auto">
                        {addButton}
                    </div>
                </div>
                {buscador}
                {content}
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default Stores