import React, {Component} from 'react';
import Login from './components/Login';
import Error from './components/Error';
import ConsultaSaldo from './components/ConsultaSaldo';
import CrearCliente from './components/CrearCliente';
import BranchMenu from './components/Branch/BranchMenu';
import { BrowserRouter, useParams } from 'react-router-dom';
import TemplateRouted from "./TemplateRouted";
import { SessionProvider, SessionConsumer } from './SessionContext'
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {

  constructor() {
    super()
    this.state = {
      ctx: null,
      storeLookupData: null,
      showError: false,
      storeID: null,
      branchID: null,
      hubURL: 'https://fidely20backend.azurewebsites.net',
      // hubURL: 'http://localhost:8080'
    };

    this.onLoggedUser = this.onLoggedUser.bind(this)
  }

  componentWillMount() {
    if(window.location.pathname.includes('saldo')) {
      var pathArray = window.location.pathname.split('/');
      if (pathArray.length > 2) {
        let lookupStoreURL = `https://fidely20backend.azurewebsites.net/stores_checkpage/${pathArray[2]}`
        //let lookupStoreURL = `${this.state.hubURL}/stores_checkpage/${pathArray[2]}`
        trackPromise(
          axios.get(lookupStoreURL, { headers: { 'Access-Control-Allow-Origin': '*', } })
          .then((res) => {
              if (res.data != null) {
                  if(res.data["error"] != null) {
                    this.setState({ showError: true, storeLookupData: null })
                  } else {
                    this.setState({ showError: false, storeLookupData: res.data })
                  }
              } else {
                this.setState({ showError: true, storeLookupData: null })
              }
          }).catch((error) => {
            this.setState({ showError: true, storeLookupData: null })
          })
      )
      } else {
        this.setState({ showError: true, storeLookupData: null })
      }
    } else if(window.location.pathname.includes('alta_clientes')) {
      var pathArray = window.location.pathname.split('/');
      if (pathArray.length > 2) {
        // let lookupStoreURL = `${this.state.hubURL}/stores_newclientpage/${pathArray[2]}`
        let lookupStoreURL = `https://fidely20backend.azurewebsites.net/stores_newclientpage/${pathArray[2]}`
        trackPromise(
          axios.get(lookupStoreURL, { headers: { 'Access-Control-Allow-Origin': '*', } })
          .then((res) => {
              if (res.data != null) {
                  if(res.data["error"] != null) {
                    this.setState({ showError: true, storeCreateClientData: null })
                  } else {
                    this.setState({ showError: false, storeCreateClientData: res.data })
                  }
              } else {
                this.setState({ showError: true, storeCreateClientData: null })
              }
          }).catch((error) => {
            this.setState({ showError: true, storeCreateClientData: null })
          }))
      } else {
        this.setState({ showError: true, storeCreateClientData: null })
      } 
    } else if(window.location.href.includes('menu')) {
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('storeID') && urlParams.has('branchID')) {
          var storeID = urlParams.get('storeID');
          var branchID = urlParams.get('branchID');
          this.setState({ storeID: storeID, branchID: branchID })
      }
    } else if(window.location.href.includes('catalogo')) {
      var pathArray = window.location.pathname.split('/');
      if (pathArray.length > 2) {
        //let lookupStoreMenuURL = `${this.state.hubURL}/branches_catalog/${pathArray[2]}`
        let lookupStoreMenuURL = `https://fidely20backend.azurewebsites.net/branches_catalog/${pathArray[2]}`
        trackPromise(
          axios.get(lookupStoreMenuURL, { headers: { 'Access-Control-Allow-Origin': '*', } })
          .then((res) => {
              if (res.data != null) {
                this.setState({ storeID: res.data["store_id"], branchID: res.data["branch_id"] })
              }
          }).catch((error) => {
            console.log("Error");
          }))
      } else {
        console.log("Error");
      }
    }

    let ctx = this.getUserLogged();
    if (ctx !== null) {
      if (this.state.ctx === null) {
        if (window.location.pathname != ctx.base_url) {
          ctx.base_url = window.location.pathname;
        }
        this.setIsUserLogged(ctx);
      } else {
        if (window.location.pathname !== this.state.ctx.base_url) {
          ctx.base_url = window.location.pathname;
          this.setIsUserLogged(ctx);
        }
      }
    } else {
      console.log("NO HAY NADA");
    }
  }

  getUserLogged() {
    const ctxString = localStorage.getItem('ctx');
    const ctx = JSON.parse(ctxString);
    return ctx
  }

  setIsUserLogged(ctx) {
    localStorage.setItem('ctx', JSON.stringify(ctx));
    this.setState({ctx: ctx})
  }
  
  onLoggedUser(user) {
    let bu = '/stores'
    if (user.store_id !== null && user.store_id !== 0) {
      if (user.branch_id !== null && user.branch_id !== 0) {
        bu = `/stores/${user.store_id}/branches/${user.branch_id}`
      } else {
        bu = `/stores/${user.store_id}`
      }
    }
    let ctx = {base_url: bu, user: user, hubURL: this.state.hubURL, store_id: user.store_id, branch_id: user.branch_id}
    this.setIsUserLogged(ctx);
  }

  logout() {
    localStorage.clear();
    window.location.href = '/';
  }

  render () {
    if (this.state.storeLookupData != null) {
      return <ConsultaSaldo hubURL={this.state.hubURL} storeID={this.state.storeLookupData.store_id} 
        backgroundURL={this.state.storeLookupData.background_url} storeLogoURL={this.state.storeLookupData.store_logo_url}
        showPoints={this.state.storeLookupData.show_points} showCash={this.state.storeLookupData.show_cash} 
        primaryColor={this.state.storeLookupData.store_primary_color} 
        letterColor={this.state.storeLookupData.store_letter_color}
        currencySymbol={this.state.storeLookupData.store_currency_symbol} /> 
    } else if (this.state.storeCreateClientData != null) {
      return <CrearCliente 
        storeName={this.state.storeCreateClientData.store_name} requestAddress={this.state.storeCreateClientData.request_address} 
        requestPhone={this.state.storeCreateClientData.request_phone} requestFacebook={this.state.storeCreateClientData.request_facebook}
        hubURL={this.state.hubURL} storeID={this.state.storeCreateClientData.store_id} 
        customFields={this.state.storeCreateClientData.custom_fields}
        bannerImage={this.state.storeCreateClientData.background_url} storeLogoURL={this.state.storeCreateClientData.store_logo_url}
        primaryColor={this.state.storeCreateClientData.store_primary_color} letterColor={this.state.storeCreateClientData.store_letter_color}
        termsAndConditions={this.state.storeCreateClientData.terms_and_conditions} 
        termsAndConditionsUrl={this.state.storeCreateClientData.terms_and_conditions_url} /> 
    } else if (this.state.showError == true) {
      return <Error />
    } else if (this.state.storeID != null && this.state.branchID != null) {
      return <BrowserRouter><BranchMenu storeID={this.state.storeID} branchID={this.state.branchID} /></BrowserRouter>
    } else if (this.state.ctx !== null) {
      return <SessionProvider value={this.state.ctx}><TemplateRouted logout={this.logout} /></SessionProvider>
    } else {
      return <Login hubURL={this.state.hubURL} onLoggedUser={this.onLoggedUser}/>
    }
  }
}

export default App;