import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { trackPromise } from 'react-promise-tracker';
import { Modal, Button } from 'react-bootstrap';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ConsultaSaldo extends Component {

    constructor() {
        super()
        this.state = {
            identification: '',
            showModal: false,
            client: null,
        }

        this.onLookupButtonClicked = this.onLookupButtonClicked.bind(this)
        this.onIdentificationChanged = this.onIdentificationChanged.bind(this)
    }

    onLookupButtonClicked(e) {
        e.preventDefault()

        let lookupURL = `${this.props.hubURL}/stores/${this.props.storeID}/clients/${this.state.identification}`

        trackPromise(
          axios.get(lookupURL, {
              headers: {
                'Access-Control-Allow-Origin': '*',
              }
          })
          .then((res) => {
              if (res.data != null) {
                  if(res.data["error"] != null) {
                    this.setState({ open: true, message: 'Los datos ingresados no son válidos.', })                        
                  } else {
                    this.setState({ client: res.data, identification: '', open: false, showModal: true, })
                  }
              } else {
                this.setState({ open: true, message: 'Los datos ingresados no son válidos.', })                        
              }
          }).catch((error) => {
              console.log(error)
              this.setState({ open: true, message: 'Los datos ingresados no son válidos.', })
          })
        );
    }

    onIdentificationChanged(e) {
        this.setState({identification: e.target.value})
    }

    render() {
        let modalTitle = "Consulta de Saldo"
        let modalBody
        let imageURL = "url("+`${this.props.backgroundURL}`+")"

        let cashDiv, pointsDiv
        
        if (this.state.client != null) {
            if(this.props.showPoints === 1) {
                cashDiv = <div className="row justify-content-center" style={{paddingTop: "16px"}}>
                    <h4><span class="badge badge-pill badge-primary">{new Intl.NumberFormat('es', {minimumFractionDigits: 0, maximumFractionDigits: 2, }).format(this.state.client.points)} puntos</span></h4>
                </div>
            }
    
            if(this.props.showCash === 1) {
                pointsDiv = <div className="row justify-content-center" style={{paddingTop: "16px"}}>
                    <h4><span class="badge badge-pill badge-success">{this.props.currencySymbol} {new Intl.NumberFormat('es', {minimumFractionDigits: 0, maximumFractionDigits: 2, }).format(this.state.client.cash)}</span></h4>
                </div>
            }

            modalTitle = "Consulta de Saldo - " + this.state.client.name + " " + this.state.client.last_name
            modalBody = <div className="card-body">
                <div className="row">
                    <div class="col-sm-6">
                        <p className="mb-1"><small><span className="font-weight-bold">Número de Documento:</span> {this.state.client.identification}</small></p>
                        <p className="mb-1"><small><span className="font-weight-bold">Fecha Nacimiento:</span> {this.state.client.born_date}</small></p>
                    </div>
                </div>
                {cashDiv}
                {pointsDiv}
            </div>
        }
        return (
                <div style={{backgroundImage: imageURL, backgroundSize: "cover", width: "100%", height: "100%", position: "absolute"}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "100vh"}}>
                    
                        <div className="card o-hidden border-0 shadow-lg" style={{padding: "32px"}}>
                            <div className="card-body p-0">
                                <div className="row justify-content-center">
                                <img src={`${this.props.storeLogoURL}`} style={{ padding: "15px 0px", maxHeight: "130px", maxWidth:"50%" }} />
                                </div>
                                <div className="row justify-content-center">
                                    <div className="text-center">
                                        <p className="text-gray-900" style={{fontWeight: "bold", textTransform: "uppercase", fontSize: "18px"}}>Consulta tu saldo</p>
                                    </div>
                                    <div className="text-center">
                                        <p className="text-gray-600" style={{fontSize: "14px"}}>Por favor ingresá tu número de documento o tu número de tarjeta.</p>
                                    </div>
                                </div>
                                <div className="row justify-content-center" style={{marginBottom: "20px"}}>
                                    <form className="user">
                                    <div className="form-group">
                                        <input className="form-control form-control-user" placeholder="Nro. Documento o Nro. de Tarjeta" value={this.state.identification} onChange={this.onIdentificationChanged} />
                                    </div>
                                    <button style={{fontWeight: "bold", textTransform: "uppercase", backgroundColor: this.props.primaryColor, borderColor: this.props.primaryColor, color: this.props.letterColor}} className="btn btn-user btn-block" type="submit" onClick={this.onLookupButtonClicked}>Consultar</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    
                </div>
              <Snackbar open={this.state.open}>
                <Alert severity="error">
                  {this.state.message}
                </Alert>
              </Snackbar>
              <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false, client: null, identification: ''}) } size="lg">
                    <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalBody}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({showModal: false, client: null, identification: ''}) }>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
          </div>
        );
    }
}
export default ConsultaSaldo;