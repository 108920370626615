import React, { Component } from 'react';
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { Modal, Button } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { TableHeader } from 'semantic-ui-react';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Giftcards extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            giftcards: [],
            lookupValue: null,
            giftcardTransactions: null,
            showTransactionsModal: false,
            showDownloadModal: false,
            showLoadModal: false,
            loadAmount: 0,
            downloadAmount: 0,
            open: false,
            finishOperation: false,
            openOperationModal: false,
            message: '',
            branch_id: null,
            branch_selected: '',
            lookupClient: null,
            filtered_transactions: null,
            page: 0,
            rowsPerPage: 5,
        }

        this.getNumber = this.getNumber.bind(this)
        this.onLookupFormSubmitted = this.onLookupFormSubmitted.bind(this)
        this.onChangeLookupGiftcard = this.onChangeLookupGiftcard.bind(this)
        this.onChangeDownloadAmount = this.onChangeDownloadAmount.bind(this)
        this.onChangeLoadAmount = this.onChangeLoadAmount.bind(this)
        this.onDischargeSubmit = this.onDischargeSubmit.bind(this)
        this.onChargeSubmit = this.onChargeSubmit.bind(this)
        this.onChangeBranchSearch = this.onChangeBranchSearch.bind(this)
        this.onChangeLookupClient = this.onChangeLookupClient.bind(this)
        this.onFilterFormSubmitted = this.onFilterFormSubmitted.bind(this)
        this.exportData = this.exportData.bind(this)
    }

    exportData() {
        var wb = XLSX.utils.book_new();
        wb.Props = {
                Title: "Giftcards",
                Subject: "Fidely Backoffice",
                Author: "Fidely",
                CreatedDate: new Date()
        };
        
        wb.SheetNames.push("Giftcards");
        var ws_data = [['ID', 'Nro. Tarjeta', 'Puntos', 'Pesos']];
    
        this.state.giftcards.map((g) => (
            ws_data.push([g.id, g.card_number, g.points, g.cash])
        ))

        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        wb.Sheets["Giftcards"] = ws;

        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
        }
        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'giftcards.xlsx');
    }

    onChangeBranchSearch(e) { 
        this.setState({
            branch_selected: e.target.value,
        }) 
    }

    onChangeLookupClient(e) {
        this.setState({ lookupClient: e.target.value })
    }

    onFilterFormSubmitted(e) {
        e.preventDefault();
        var tx = this.state.giftcardTransactions;

        if (this.state.branch_selected !== '') {
            tx = tx.filter(t => t.branch_id == this.state.branch_selected);
        }

        if (this.state.lookupClient !== null) {
            tx = tx.filter(t => t.user_identification == this.state.lookupClient || t.user_card_number == this.state.lookupClient);
        }

        this.setState({ filtered_transactions: tx })
    }

    
    onChangeLookupGiftcard(e) {
        this.setState({ lookupValue: e.target.value })
    }

    onChangeDownloadAmount(e) {
        this.setState({ downloadAmount: this.getNumber(e.target.value) })
    }

    onChangeLoadAmount(e) {
        this.setState({ loadAmount: this.getNumber(e.target.value) })
    }
    
    getNumber(v) {
        let number = v.replace('.', '');
        let parts = number.split(",");

        if(/^[0-9]*$/.test(parts[0])) {
            if (parts.length === 2) {
                if (/^[0-9]*$/.test(parts[1])) {
                    return parts[0] + "," + parts[1].substring(0,2);
                } else {
                    return '';
                }
            } else if (parts.length === 1) {
                return parts[0];
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    onLookupFormSubmitted(e) {
        e.preventDefault()

        if (this.state.lookupValue !== null) {
            const { match: { params } } = this.props;
        
            let url = `${this.context.hubURL}/stores/${params.storeId}/giftcards/${this.state.lookupValue}`
            
            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                    .then(res => { 
                        var arr = [];
                        arr.push(res.data);
                        this.setState({ giftcards: arr }); 
                    })
                    .catch(function (error) { console.log(error); })
            );
        }
    }

    getTransactions(url, callback) {
        this.runPromise(url, callback);
    }

    getBranches(storeId, callback) {
        let url = `${this.context.hubURL}/stores/${storeId}/branches`;
        this.runPromise(url, callback);
    }

    runPromise(url, callback) {
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                .then(res => { callback(res.data); })
                .catch(function (error) { callback(null) })
        );
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        let url = `${this.context.hubURL}/stores/${params.storeId}/giftcards`
        
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                .then(res => { this.setState({ giftcards: res.data, finishOperation: false, openOperationModal: false }); })
                .catch(function (error) { console.log(error); })
        );
    }

    onSelectedGiftcard(g, action) {
        if(action === "transactions") {

            const { match: { params } } = this.props;
            if (params.storeId !== undefined) {
                let url = `${this.context.hubURL}/stores/${params.storeId}/giftcards/${g.card_number}/transactions`
                this.getTransactions(url, transactions => {
                    this.getBranches(params.storeId, branches => {
                        this.setState({ giftcardTransactions: transactions, filtered_transactions: transactions, 
                            giftcard: g, showTransactionsModal: true, branches: branches["branches"] });
                    });
                });
            }
        } else if(action === "load") {
            this.setState({ giftcard: g, showLoadModal: true });
        } else if(action === "download") {
            this.setState({ giftcard: g, showDownloadModal: true });
        }
    }

    onDischargeSubmit(e) {
        e.preventDefault();
        if (parseFloat(this.state.downloadAmount) > this.state.giftcard.cash) {
            alert("El monto de descarga no puede superar el límite disponibles.");
            this.setState({downloadAmount: 0});
        } else {
            const obj = {
                "card_number": this.state.giftcard.card_number,
                "amount": this.state.downloadAmount + "",
            };
    
            var url = `${this.context.hubURL}/stores/${this.state.giftcard.store_id}/giftcards/${this.state.giftcard.card_number}/discharge`;
            trackPromise(
                axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*' }})
                .then((res) => { 
                    var message = "Se descargó la giftcard correctamente."
                    this.setState({downloadAmount: 0, showDownloadModal: false, giftcard: null, message: message, openOperationModal: true, open: false })
                })
                .catch((error) => { console.log(error) })
            );
        }
    }

    onChargeSubmit(e) {
        e.preventDefault();

        const obj = {
            "card_number": this.state.giftcard.card_number,
            "amount": this.state.loadAmount  + "",
        };

        var url = `${this.context.hubURL}/stores/${this.state.giftcard.store_id}/giftcards/${this.state.giftcard.card_number}/charge`;
        trackPromise(
            axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*' }})
            .then((res) => { 
                var message = "Se cargó la giftcard correctamente."
                this.setState({loadAmount: 0, showLoadModal: false, giftcard: null, message: message, openOperationModal: true, open: false })
            })
            .catch((error) => { console.log(error) })
        );
    }

    handleChangePage(event, newPage) {
        this.setState({ page: newPage });
      }
      
      handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0});
      }


    render() {
        const { match: { params } } = this.props;

        if (this.state.finishOperation === true) {
            this.componentDidMount()
        }

        let content, modalTransactionsBody, modalTransactions, modalDownloadBody, modalLoadBody, rowGiftcardInfo, buscador, branchesSelect
        
        if(this.state.giftcard != null) {
            rowGiftcardInfo = <div className="row">
                <div class="col-sm-12">
                    <p className="mb-1"><small><span className="font-weight-bold">Nro. Tarjeta:</span> {this.state.giftcard.card_number}</small></p>
                    <p className="mb-1"><small><span className="font-weight-bold">Puntos:</span> {new Intl.NumberFormat('es', {minimumFractionDigits: 0, maximumFractionDigits: 2, }).format(this.state.giftcard.points)}</small></p>
                    <p className="mb-1"><small><span className="font-weight-bold">Puntos ($):</span> {new Intl.NumberFormat('es', {minimumFractionDigits: 0, maximumFractionDigits: 2, }).format(this.state.giftcard.cash)}</small></p>
                </div>
            </div>
            modalLoadBody = <div className="card-body">
                {rowGiftcardInfo}
                <br />
                <p>Introduce el monto en <b>pesos</b>.</p>
                <form onSubmit={this.onChargeSubmit}>
                    <div className="form-row align-items-center">
                        <div className="form-group col-sm-12">
                            <input className="form-control" type="text" id="inputLoadAmount" value={this.state.loadAmount}
                            placeholder="Monto Carga" onChange={this.onChangeLoadAmount} />
                        </div>
                        <button type="submit" className="btn btn-primary">Cargar</button>
                    </div>
                </form>
            </div>
            modalDownloadBody = <div className="card-body">
                {rowGiftcardInfo}
                <br />
                <p>Introduce el monto en <b>pesos</b>.</p>
                <form onSubmit={this.onDischargeSubmit}>
                    <div className="form-row align-items-center">
                        <div className="form-group col-sm-12">
                            <input className="form-control" type="text" id="inputDownloadAmount" value={this.state.downloadAmount}
                            placeholder="Monto Descarga" onChange={this.onChangeDownloadAmount} />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">Descargar</button>
                </form>
            </div>
            modalTransactions = <div className="row"><p>Esta giftcard aún no tiene transacciones.</p></div>
            if(this.state.giftcardTransactions != null) {
                if(this.state.branches != null) {
                    branchesSelect = <div className="form-group col-md-3">
                        <select id="selectBranch" className="form-control" value={this.state.branch_id} onChange={this.onChangeBranchSearch}>
                        <option value="">--- Sucursal ---</option>
                            { this.state.branches.map((branch) => (
                                <option value={branch.id}>{branch.name}</option>
                            )) 
                            }
                        </select>
                    </div>
                }
                buscador = <div className="card shadow mb-4" style={{padding: "0"}}>
                <div className="card-header py-3">
                    <div className="row">
                        <h6 className="m-0 font-weight-bold text-primary">Filtrar Transacciones</h6>
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={this.onFilterFormSubmitted}>
                        <div className="form-row align-items-center">
                            {branchesSelect}
                            <div className="form-group col-md-3">
                                <input className="form-control" type="text" id="inputLookupClient" 
                                    placeholder="Nro. Documento o Tarjeta" onChange={this.onChangeLookupClient} />
                            </div>
                            <div className="form-group col-md-3 text-center">
                                <button type="submit" className="btn btn-primary">Buscar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            modalTransactions = <div className="row">
                {buscador}
                <div className="row">
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader>
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Fecha y hora</TableCell>
                        <TableCell align="center">Comercio</TableCell>
                        <TableCell align="center">Título</TableCell>
                        <TableCell align="center">Monto Final</TableCell>
                        <TableCell align="center">Monto Original</TableCell>
                        <TableCell align="center">Descuento</TableCell>
                        <TableCell align="center">Nro. Ticket</TableCell>
                        <TableCell align="center">Cliente</TableCell>
                        <TableCell align="center">Tarjeta</TableCell>
                    </TableHeader>
                    <TableBody>
                    {(this.state.rowsPerPage > 0 ? this.state.filtered_transactions.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage) : this.state.filtered_transactions).map((t, index) => (
                        <TableRow hover key={t.id}>
                            <TableCell align="center">{t.id}</TableCell>
                            <TableCell align="center">{new Date(t.date).toLocaleString('es')}</TableCell>
                            <TableCell align="center">{t.branch_name}</TableCell>
                            <TableCell align="center">{t.reason}</TableCell>
                            <TableCell align="center">{t.final_amount}</TableCell>
                            <TableCell align="center">{t.amount}</TableCell>
                            <TableCell align="center">{t.discount}</TableCell>
                            <TableCell align="center">{t.ticket_id}</TableCell>
                            <TableCell align="center">{t.user_name} {t.user_last_name} ({t.user_identification})</TableCell>
                            <TableCell align="center">{t.card_number}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination count={this.state.filtered_transactions.length} rowsPerPage={this.state.rowsPerPage} labelRowsPerPage="Items por página"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]} onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                            onChangePage={this.handleChangePage.bind(this)} page={this.state.page} />
                        </TableRow>
                    </TableFooter>
                </Table>
                    </div> 
                </div>
            }
            modalTransactionsBody = <div className="card-body">
                {rowGiftcardInfo}
                {modalTransactions}
            </div>
        }

        if(this.state.giftcards != null) {
            content = <div className="card-body">
                <div className="row">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Número de tarjeta</th>
                                <th scope="col">Saldo Puntos</th>
                                <th scope="col">Saldo Pesos</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.giftcards.map((c) => (
                            <tr>
                                <td>{c.id}</td>
                                <th scope="row">{c.card_number}</th>
                                <td>{new Intl.NumberFormat('es', {minimumFractionDigits: 0, maximumFractionDigits: 2, }).format(c.points)}</td>
                                <td>{new Intl.NumberFormat('es', {minimumFractionDigits: 0, maximumFractionDigits: 2, }).format(c.cash)}</td>
                                <td>
                                <a onClick={this.onSelectedGiftcard.bind(this, c, "load")} className="btn btn-sm btn-success"><span className="icon text-white-50"><i class="fas fa-plus-square"></i></span></a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a onClick={this.onSelectedGiftcard.bind(this, c, "download")} className="btn btn-sm btn-success"><span className="icon text-white-50"><i class="fas fa-minus-square"></i></span></a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a onClick={this.onSelectedGiftcard.bind(this, c, "transactions")} className="btn btn-sm btn-success"><span className="icon text-white-50"><i class="fas fa-credit-card"></i></span></a>
                                </td>
                            </tr> ))}
                        </tbody>
                    </table>
                </div>
            </div>    
        } else {
            content = <p>No hay giftcards en esta marca.</p>
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-10 text-left">
                        <h3 className="h3 mb-4 text-gray-800">Giftcards</h3>
                    </div>
                    <div className="col-md-1 text-left">
                        <Link to={`/stores/${params.storeId}/giftcards/upload`} className="btn btn-primary"><span className="icon text-white-50"><i className="fas fa-cloud-upload-alt"></i></span></Link>
                    </div>
                    <div className="col-md-1 text-right">
                        <a onClick={this.exportData} className="btn btn-secondary"><span className="icon text-white-50"><i className="fas fa-cloud-download-alt"></i></span></a>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <h6 className="m-0 font-weight-bold text-primary">Buscar Giftcard</h6>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.onLookupFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className="form-group col-md-10">
                                    <input className="form-control" type="text" id="inputLookupCard" 
                                    placeholder="Nro. Tarjeta" onChange={this.onChangeLookupGiftcard} />
                                </div>

                                <div className="form-group col-md-2 text-center">
                                    <button type="submit" className="btn btn-primary">Buscar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
                <Modal show={this.state.showTransactionsModal} onHide={() => this.setState({showTransactionsModal: false, giftcard: null, giftcardTransactions: null}) } size="xl">
                    <Modal.Header closeButton>
                    <Modal.Title>Giftcard - Transacciones</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalTransactionsBody}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({showTransactionsModal: false, giftcard: null, giftcardTransactions: null}) }>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showLoadModal} onHide={() => this.setState({showLoadModal: false, giftcard: null}) } size="sm">
                    <Modal.Header closeButton>
                    <Modal.Title>Cargar Giftcard</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalLoadBody}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({showLoadModal: false, giftcard: null}) }>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showDownloadModal} onHide={() => this.setState({showDownloadModal: false, giftcard: null}) } size="sm">
                    <Modal.Header closeButton>
                    <Modal.Title>Descargar Giftcard</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalDownloadBody}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({showDownloadModal: false, giftcard: null}) }>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.props.history.go(-1)}>
                    <Alert onClose={() => this.props.history.go(-1)} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openOperationModal} autoHideDuration={1000} onClose={() => this.setState({ finishOperation: true })}>
                    <Alert onClose={() => this.setState({ finishOperation: true })} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default Giftcards