import React, { Component } from 'react';
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class PointOfSales extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            pos: [],
            urlToRedirect: null,
            message: '',
            open: false,
        }
    }

    onDeleted(posId) {
        const { match: { params } } = this.props;

        let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/point_of_sales/${posId}/delete`
        trackPromise(
            axios.post(url)
                .then(res => {
                    this.setState({ open: true, message: 'Punto de venta eliminado.' });
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/point_of_sales`
        
        trackPromise(
            axios.get(url, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then(res => {
                    this.setState({ pos: res.data, open: false });
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    customRedirect(urlToRedirect, posName) {
        sessionStorage.setItem("pos_name", posName);
        this.setState({ urlToRedirect: urlToRedirect });
    }

    render() {
        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }

        const { match: { params } } = this.props;
        
        let url = ""
        let content = ""

        if(this.state.pos != null) {
            content = <div className="card-body">
                <div className="row">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nro. Serie</th>
                                <th scope="col">Usuario</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.pos.filter(p => p.deleted === 0).map((p) => (
                            <tr>
                                <td>{p.id}</td>
                                <th scope="row">{p.serial_number}</th>
                                <td>{p.user}</td>
                                <td>
                                    <a onClick={this.customRedirect.bind(this, `/stores/${params.storeId}/branches/${params.branchId}/pos/${p.id}/transactions`, p.serial_number)} className="btn btn-sm btn-success"><span className="icon text-white-50"><i className="fas fa-credit-card"></i></span></a>
                                    &nbsp;&nbsp;&nbsp;
                                    <Link to={`/stores/${params.storeId}/branches/${params.branchId}/pos/${p.id}/edit`} className="btn btn-sm btn-primary"><span className="icon text-white-50"><i className="fas fa-pencil-alt"></i></span></Link>
                                    &nbsp;&nbsp;&nbsp;
                                    <a onClick={this.onDeleted.bind(this, p.id)} className="btn btn-sm btn-danger"><span className="icon text-white-50"><i className="fas fa-trash"></i></span></a>
                                </td>
                            </tr> ))}
                        </tbody>
                    </table>
                </div>
            </div>    
        } else {
            content = <p>No hay puntos de venta asignados a esta sucursal.</p>
        }

        if (params.storeId != null) {
            if (params.branchId != null) {
                url = `/stores/${params.storeId}/branches/${params.branchId}/pos/new`
            }
        }

        return(
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">Puntos de venta</h6>
                            </div>
                            <div className="col text-right my-auto">
                                <Link to={url} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card shadow mb-4">
                            {content}
                        </div>
                    </div>
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default PointOfSales