import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import YesNoSwitch from '../YesNoSwitch';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewClientTypeUpdate extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            name: '',
            description: '',
            clientTypes: null,
            client_type_id_from: -1,
            client_type_id_to: -1,
            min_stay: 0,
            obj_type: '',
            obj_amount: 0,
            obj_limit: '',
            obj_limit_value: 0,
            open: false,
            someChanges: false,
            urlToRedirect: null,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)

        this.onChangeClientTypeFrom = this.onChangeClientTypeFrom.bind(this)
        this.onChangeClientTypeTo = this.onChangeClientTypeTo.bind(this)
        this.onChangeMinStay = this.onChangeMinStay.bind(this)
        this.onChangeObjectiveType = this.onChangeObjectiveType.bind(this)
        this.onChangeObjectiveLimit = this.onChangeObjectiveLimit.bind(this)
        this.onChangeObjLimitValue = this.onChangeObjLimitValue.bind(this)
        this.onChangeObjAmount = this.onChangeObjAmount.bind(this)
        
        this.goToList = this.goToList.bind(this)
    }

    onChangeClientTypeFrom(e) {
        this.setState({ client_type_id_from: e.target.value, someChanges: true })
    }

    onChangeClientTypeTo(e) {
        this.setState({ client_type_id_to: e.target.value, someChanges: true })
    }

    onChangeObjectiveType(e) {
        this.setState({ obj_type: e.target.value, someChanges: true })
    }

    onChangeObjectiveLimit(e) {
        this.setState({ obj_limit: e.target.value, someChanges: true })
    }

    onChangeObjLimitValue(e) {
        this.setState({ obj_limit_value: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeObjAmount(e) {
        this.setState({ obj_amount: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    onChangeMinStay(e) {
        this.setState({ min_stay: parseInt(e.target.value) ? parseInt(e.target.value) : '', someChanges: true })
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        var url = `${this.context.hubURL}/stores/${params.storeId}/client_types`;
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' }})
                .then(res => {
                    this.setState({ clientTypes: res.data.filter(ct => ct.deleted === 0) });
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        }

        if (this.state.client_type_id_from == -1) return
        if (this.state.client_type_id_to == -1) return
        if (this.state.obj_type == "") return
        if (this.state.obj_limit == "") return

        const obj = {
            client_type_from: parseInt(this.state.client_type_id_from, 10),
            client_type_to: parseInt(this.state.client_type_id_to, 10),
            min_stay: this.state.min_stay,
            obj_type: this.state.obj_type,
            obj_amount: this.state.obj_amount,
            obj_limit: this.state.obj_limit,
            obj_limit_value: this.state.obj_limit_value,
        };

        var url = `${this.context.hubURL}/stores/${params.storeId}`
        url += `/client_types_updates`

        var message = 'Cambio de categoría agregada!';

        trackPromise(
            axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*' }})
            .then((res) => { this.setState({ open: true, message: message }) })
            .catch((error) => { console.log(error) })
        );
    }
    setOpen(value) {
        this.setState({ open: value })
    }

    render() {
        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }

        let clientTypeSelectFrom, clientTypeSelectTo 
        if (this.state.clientTypes == null) {
            clientTypeSelectFrom = ""
            clientTypeSelectTo = ""
        } else {
            clientTypeSelectFrom = <div className="form-group col-md-4">
                <label for="selectClientTypeFrom">Tipo Cliente Origen</label>
                <select id="selectClientTypeFrom" className="form-control" value={this.state.client_type_id_from} onChange={this.onChangeClientTypeFrom}>
                    <option value="-1">Seleccioná una opción</option>
                    { this.state.clientTypes.map((clientType) => (
                        <option value={clientType.id}>{clientType.name}</option>
                    )) 
                    }
                </select>
            </div>
            clientTypeSelectTo = <div className="form-group col-md-4">
            <label for="selectClientTypeTo">Tipo Cliente Destino</label>
            <select id="selectClientTypeTo" className="form-control" value={this.state.client_type_id_to} onChange={this.onChangeClientTypeTo}>
                <option value="-1">Seleccioná una opción</option>
                { this.state.clientTypes.map((clientType) => (
                    <option value={clientType.id}>{clientType.name}</option>
                )) 
                }
            </select>
        </div>
        }

        return (
            <div className="container">
                <form onSubmit={this.onFormSubmitted}>
                    <h3 className="h3 mb-4 text-gray-800">Nuevo Cambio de Categoría</h3>
                    <div className="row">
                        {clientTypeSelectFrom}
                        {clientTypeSelectTo}
                        <div className="form-group col-md-4 text-center">
                            <label htmlFor="inputName">Días Mínimos de permanencia</label>
                            <input className="form-control" type="text" id="inputName" placeholder="0" value={this.state.min_stay} onChange={this.onChangeMinStay} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor="inputObjective">Objetivo</label>
                            <select id="inputObjective" className="form-control" 
                            value={this.state.obj_type} onChange={this.onChangeObjectiveType}>
                                <option value="">Seleccioná una opción</option>
                                <option value="AMOUNT">Plata Gastada</option>
                                <option value="BALANCE">Saldo</option>
                            </select>
                        </div>
                        <div className="form-group col-md-3 text-center">
                                <label htmlFor="inputObjAmount">Monto</label>
                                <input className="form-control" type="text" id="inputObjAmount" placeholder="0" value={this.state.obj_amount} onChange={this.onChangeObjAmount} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor="inputObjectiveLimit">Plazo</label>
                            <select id="inputObjectiveLimit" className="form-control" 
                            value={this.state.obj_limit} onChange={this.onChangeObjectiveLimit}>
                                <option value="">Seleccioná una opción</option>
                                <option value="MONTH">Calendario</option>
                                <option value="DAYS">Días Corridos</option>
                            </select>
                        </div>
                        <div className="form-group col-md-3 text-center">
                            <label htmlFor="inputObjLimitValue">Plazo</label>
                            <input className="form-control" type="text" id="inputObjLimitValue" placeholder="0" value={this.state.obj_limit_value} onChange={this.onChangeObjLimitValue} />
                        </div>
                    </div>
                    <br />
                    
                    <br />
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Confirmar</button>
                    </div>
                </form>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.goToList()}>
                    <Alert onClose={() => this.goToList()} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        )
    }

    goToList() {
        const { match: { params } } = this.props;
        var url = `/stores/${params.storeId}`
        
        this.setState({ urlToRedirect: url + `/client_types_updates` })
    }

}

export default NewClientTypeUpdate