import React, { Component } from 'react';
import SessionContext from '../../SessionContext';

class CardClientTypeUpdate extends Component {
  static contextType = SessionContext
  
  constructor() {
        super()

        this.onDeleted = this.onDeleted.bind(this)
    }

  onDeleted() {
  }


  render() {
    return (
        <div class="row no-gutters">
            <div className="card shadow" style={{marginBottom: "16px", width: "100%"}}>
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-10 text-left my-auto">
                            <h6 className="m-0 font-weight-bold text-primary">{this.props.clientTypeUpdate.client_type_from_name} a {this.props.clientTypeUpdate.client_type_to_name}</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div class="col-6 text-left"><p className="mb-1"><small>Minima permanencia: {this.props.clientTypeUpdate.min_stay}</small></p></div>
                    </div>
                    <br />
                    <hr className="my-4" />
                </div>
            </div>
        </div>
    );
  }
}
export default CardClientTypeUpdate;