import React, {Component} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import NavBar from './components/NavBar';
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import Breadcrumbs from './components/Breadcrumbs';
import routes from "./routes";
import { SessionProvider, SessionConsumer } from './SessionContext'

class TemplateRouted extends Component {

    render() {
      return (
        <SessionConsumer>
          {(props) => {
            return (
              <SessionProvider value={props}>
                <BrowserRouter>
                  <div id="wrapper">
                    <NavBar logout={this.props.logout} />
                    <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content">
                        <TopBar value={props} />
                        <div className="container-fluid">
                          <Redirect from="/" to={props.base_url} />
                          <Switch>
                            {routes.map(({ path, name, Component }, key) => (
                              <Route exact path={path} key={key}
                                render={props => {
                                  const crumbs = routes
                                    .filter(({ path }) => props.match.path.includes(path))
                                    .map(({ path, ...rest }) => ({
                                      path: Object.keys(props.match.params).length
                                        ? Object.keys(props.match.params).reduce(
                                            (path, param) =>
                                              path.replace(`:${param}`, props.match.params[param]),
                                            path
                                          )
                                        : path,
                                      ...rest
                                    }));
  
                                  crumbs.map(({ name, path }) => console.log({ name, path }));
                                  return (
                                    <div className="p-8">
                                      <Breadcrumbs crumbs={crumbs} />
                                      <br />
                                      <Component {...props} />
                                    </div>
                                  );
                                }}
                              />
                            ))}
                          </Switch>
                        </div>
                      </div>
                      <Footer />
                    </div>
                  </div>
                </BrowserRouter>
              </SessionProvider>
            )
          }}
        </SessionConsumer>
      )
    }
  }

export default TemplateRouted;