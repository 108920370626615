import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import AccordionItem from '../AccordionItem';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Communications extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            communications: [],
            message: '',
            open: false,
        }

        this.onDeleted = this.onDeleted.bind(this)
    }
    
    onDeleted(deleteURL) {
        trackPromise(
            axios.post(deleteURL, null, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }
            })
            .then((res) => {
                this.setState({ open: true, message: 'Comunicación eliminada.' });
            }).catch((error) => {
                console.log(error)
            })
        );
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        let url = `${this.context.hubURL}/stores/${params.storeId}/communications`

        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                .then(res => { this.setState({ communications: res.data, open: false }); })
                .catch(function (error) { console.log(error); })
        );
    }

    createAccordionItem(communication) {
        const { match: { params } } = this.props;
        var title = ""
        
        if (communication.email_type === 'WELCOME') {
            title = "Bienvenida"
        } else if (communication.email_type === 'BIRTHDAY') {
            title = "Cumpleaños"
        } else if (communication.email_type === 'UPDATE_CATEGORY') {
            title = "Cambio de Categoría"
        } else if (communication.email_type === 'TRANSACTION') {
            title = "Transacción"
        } else if (communication.email_type === 'INACTIVE_USER') {
            title = "Usuarios Inactivos"
        }

        var h2Id = "heading" + communication.id;
        var ariaControls = "collapse" + communication.id;
        var dataBsTarget = "#" + ariaControls;

        return (
            <div class="accordion-item">
                <h2 class="accordion-header" id={h2Id}>
                    <div className="row">
                        <div class="col-md-10">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={dataBsTarget} aria-expanded="false" aria-controls={ariaControls}>
                        <p>
                            {title}
                            <br />
                            <br />
                            <b>{communication.email_subject}</b>
                        </p>
                        </button>
                        </div>
                        <div class="col-md-1">
                        <Link to={`/stores/${params.storeId}/communications/${communication.id}/edit`} className="btn btn-sm btn-success"><span className="icon text-white-50"><i className="fas fa-edit"></i></span></Link>
                        </div>
                        <div class="col-md-1">
                        <a onClick={this.onDeleted.bind(this, `${this.context.hubURL}/stores/${params.storeId}/communications/${communication.id}/delete`)} data-tag={communication.id} className="btn btn-sm btn-danger"><span className="icon text-white-50"><i className="fas fa-trash"></i></span></a>
                        </div>
                    </div>
                </h2>
                <div id={ariaControls} class="accordion-collapse collapse" aria-labelledby={h2Id} data-bs-parent="communications">
                    <div class="accordion-body">
                        <div dangerouslySetInnerHTML={{__html: communication.email_content}} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { match: { params } } = this.props;
        
        let content, newButton
        
        if (this.state.communications == null || this.state.communications.length == 0) {
            content = "No hay comunicaciones creadas."
        } else {
            content = <div className="container">
                <div class="accordion" id="communications">
                    { this.state.communications.map((communication) => (
                        this.createAccordionItem(communication)
                    )) }
                </div>
            </div>
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col text-left my-auto">
                        <h3 className="h3 mb-4 text-gray-800">Comunicaciones</h3>
                    </div>
                    <div className="col text-right my-auto">
                        <Link to={`/stores/${params.storeId}/communications/new`} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default Communications