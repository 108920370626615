import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import CardStore from './CardStore';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement } from 'chart.js';
  
import { Pie, Bar, Line } from 'react-chartjs-2';
        
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class StoreReport extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            report: null,
            sexNames: [],
            sexValues: [],
            sexColors: [],
            transactionsNames: [],
            transactionsQty: [], 
            transactionsSum: []
        }
    }

    componentDidMount() {
        ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement)
        const { match: { params } } = this.props;
        let url = `${this.context.hubURL}/stores/${params.storeId}/report`;

        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*', }})
                .then(res => {
                    let sexNames = [];
                    let sexValues = [];
                    let sexColors = [];
                    let sex = res.data["sex"];
                    for (let i = 0; i < sex.length; i++){
                        sexNames.push(sex[i].name);
                        sexValues.push(sex[i].quantity);
                        sexColors.push(sex[i].color)
                    }

                    let transactionsNames = [];
                    let transactionsQty = [];
                    let transactionsSum = [];
                    let transactions = res.data["transactions"];
                    for (let i = 0; i < transactions.length; i++){
                        transactionsNames.push(transactions[i].name);
                        transactionsQty.push(transactions[i].quantity);
                        transactionsSum.push(transactions[i].amount)
                    }

                    this.setState({ report: res.data, sexNames: sexNames, sexValues: sexValues, sexColors: sexColors, 
                        transactionsNames: transactionsNames, transactionsQty: transactionsQty, transactionsSum: transactionsSum });
                })
                .catch(function (error) { console.log(error); })
        );
    }

    render() {
        if(this.state.report == null) return (<div></div>)

        var topBranches;
        if (this.state.report.branches != null) {
            topBranches = <div class="col-md-4">
                <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary" >Top Sucursales</h6>
                    </div>
                    <div class="card-body" style={{padding: 0}}>
                        <table style={{marginBottom: 0}} class="table table-bordered dataTable" id="dataTable" cellspacing="0" role="grid" aria-describedby="dataTable_info">
                            <thead>
                                <tr>
                                    <th>Sucursal</th>
                                    <th>Cantidad</th>
                                    <th>Facturación</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.report.branches.map((b) => (
                                    <tr>
                                        <td>{b.name}</td>
                                        <td>{b.quantity}</td>
                                        <td>$ {b.amount}</td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }

        var topClients;
        if (this.state.report.clients != null) {
            topClients = <div class="col-md-4">
                <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary" >Top Clientes</h6>
                    </div>
                    <div class="card-body" style={{padding: 0}}>
                        <table style={{marginBottom: 0}} class="table table-bordered dataTable" id="dataTable" cellspacing="0" role="grid" aria-describedby="dataTable_info">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Cantidad</th>
                                    <th>Facturación</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.report.clients.map((c) => (
                                    <tr>
                                        <td>{c.name}</td>
                                        <td>{c.quantity}</td>
                                        <td>$ {c.amount}</td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }

        var transactionTypes;
        if (this.state.report.transaction_types != null) {
            transactionTypes = <div class="col-md-4">
                <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary" >Tipos de Transacciones</h6>
                    </div>
                    <div class="card-body" style={{padding: 0}}>
                        <table style={{marginBottom: 0}} class="table table-bordered dataTable" id="dataTable" cellspacing="0" role="grid" aria-describedby="dataTable_info">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Cantidad</th>
                                    <th>Facturación</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.report.transaction_types.map((tt) => (
                                    <tr>
                                        <td>{tt.name}</td>
                                        <td>{tt.quantity}</td>
                                        <td>$ {tt.amount}</td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-left">
                        <h3 className="h3 mb-4 text-gray-800">Reporte</h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase">Cantidad Usuarios</div>
                                    <div className="text-lg font-weight-light text-gray-800">{this.state.report.clients_total}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase">Cantidad Giftcards</div>
                                    <div className="text-lg font-weight-light text-gray-800">{this.state.report.giftcards_total}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3"></div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="col-md-3">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="text-xs font-weight-bold text-success text-uppercase">Facturación Mensual</div>
                                    <div className="text-lg font-weight-light text-gray-800">$ {this.state.report.monthly_total}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card border-left-danger shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="text-xs font-weight-bold text-danger text-uppercase">Ticket Promedio</div>
                                    <div className="text-lg font-weight-light text-gray-800">$ {this.state.report.monthly_avg}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="text-xs font-weight-bold text-info text-uppercase">Descuentos Otorgados</div>
                                    <div className="text-lg font-weight-light text-gray-800">$ {this.state.report.monthly_discount}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card border-left-secondary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="text-xs font-weight-bold text-secondary text-uppercase">Puntos Otorgados</div>
                                    <div className="text-lg font-weight-light text-gray-800">{this.state.report.monthly_points}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                        <div className="card border-left-dark shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="text-xs font-weight-bold text-dark text-uppercase">Transacciones del Mes</div>
                                    <div className="text-lg font-weight-light text-gray-800">{this.state.report.monthly_transactions}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="text-xs font-weight-bold text-success text-uppercase">Usuarios Activos</div>
                                    <div className="text-lg font-weight-light text-gray-800">{this.state.report.monthly_active_clients}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3"></div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-4">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary" >Género de Clientes</h6>
                            </div>
                            <div class="card-body">
                                <Pie data={{
                                    labels: this.state.sexNames,
                                    datasets: [
                                        {
                                            data: this.state.sexValues,
                                            backgroundColor: this.state.sexColors,
                                        },
                                    ],
                                }} />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary" >Transacciones Mensuales</h6>
                            </div>
                            <div class="card-body">
                            <Bar 
                                options={{
                                    plugins: { legend: { display: false, }}
                                }} 
                                data={{
                                    labels: this.state.transactionsNames,
                                    datasets: [{
                                        data: this.state.transactionsQty,
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                    }],
                                }} 
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary" >Facturación Mensual</h6>
                            </div>
                            <div class="card-body">
                                <Line 
                                    options={{
                                        plugins: { legend: { display: false, }}
                                    }} 
                                    data={{
                                        labels: this.state.transactionsNames,
                                        datasets: [{
                                            data: this.state.transactionsSum,
                                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        }],
                                    }} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    {topBranches}
                    {topClients}
                    {transactionTypes}
                </div>
            </div>
        )
    }
}
export default StoreReport