import React, { Component } from 'react';
import OrderItem from './OrderItem';

class OrdersTable extends Component {
 
    constructor() {
        super()
        this.state = {
            date: new Date().toLocaleDateString(),
            filterOrders: null,
            orders: null,
            filter: "All"
        }

        this.onOrderSelected = this.onOrderSelected.bind(this)
        this.filterOrders = this.filterOrders.bind(this)
    }

    componentDidMount() {
        this.setState({filterOrders: this.props.orders, orders: this.props.orders})
    }

    onOrderSelected(order) {
        this.props.onOrderSelected(order)
    }

    filterOrders(e) {
        if(e.target.value === 'all') {
            this.setState({ filterOrders: this.state.orders, filter: "All" })
        } else if(e.target.value === 'created') {
            this.setState({ filterOrders: this.state.orders.filter(o => o.status === 'Created'), filter: "Created" })
        } else if(e.target.value === 'ready') {
            this.setState({ filterOrders: this.state.orders.filter(o => o.status === 'Ready'), filter: "Ready" })
        }
    }

    render() {
        if (this.state.filterOrders != null) {
            let allActive, createdActive, readyActive
            switch (this.state.filter) {
                case "All":
                    allActive = "active"
                    createdActive = ""
                    readyActive = ""
                    break
                case "Created":
                    allActive = ""
                    createdActive = "active"
                    readyActive = ""
                    break
                case "Ready":
                    allActive = ""
                    readyActive = "active"
                    createdActive = ""
                    break
                default:
                    console.log(this.state.filter)
                    break
            }
            return (
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">Pedidos para el {this.state.date}</h6>
                            </div>
                            <div className="col text-right">
                                <div className="btn-group btn-group-toggle mb-2" date-toggle="buttonsFilters">
                                    <label className={`btn btn-outline-secondary ${allActive}`}>
                                        <input type="radio" id="allOption" name="a" value="all" checked={allActive === 'active'} onChange={this.filterOrders} /> Todos
                                    </label>
                                    <label className={`btn btn-outline-secondary ${createdActive}`}>
                                        <input type="radio" name="a" value="created" id="createdOption" checked={createdActive === 'active'} onChange={this.filterOrders} />Pendientes
                                    </label>
                                    <label className={`btn btn-outline-secondary ${readyActive}`}>
                                        <input type="radio" name="a" value="ready" id="readyOption" checked={readyActive === 'active'} onChange={this.filterOrders} /> Para Entregar
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Productos</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Retira?</th>
                                    <th scope="col">Medio de Pago</th>
                                    <th scope="col">Estado</th>
                                </tr>
                                </thead>
                                <tbody>
                                    { this.state.filterOrders.map((order) => (<OrderItem key={order.id} order={order} onOrderSelected={this.onOrderSelected} showMessage={this.props.showMessage} />)) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(<p>No hay pedidos</p>)
        }
    }
}

export default OrdersTable;