import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SessionContext from '../SessionContext';

class NavBar extends Component {

  static contextType = SessionContext

  render() {
    let home, stores, sales, products, users, clients, cards, transactions, reports

    if(this.context.user.is_admin === "1") {
      stores = <li className="nav-item"><Link to={`/stores`} className="nav-link"><i className="fas fa-building" /><span>Empresas</span></Link></li>
      users = <li className="nav-item"><Link to={`/users`} className="nav-link"><i className="fas fa-user" /><span>Usuarios</span></Link></li>

      //clients = <li className="nav-item"><Link to={`/clients`} className="nav-link"><i className="fas fa-users" /><span>Clientes</span></Link></li>
      //cards = <li className="nav-item"><Link to={`/cards`} className="nav-link"><i className="fas fa-credit-card" /><span>Tarjetas</span></Link></li>
      //transactions = <li className="nav-item"><Link to={`/transactions`} className="nav-link"><i className="fas fa-shopping-cart" /><span>Transacciones</span></Link></li>
      //reports = <li className="nav-item"><Link to={`/reports`} className="nav-link"><i className="fas fa-chart-line" /><span>Reportes</span></Link></li>

    } else {

      

      if(this.context.user.stores_permission === "1") {
        stores = <li className="nav-item"><Link to={`/stores`} className="nav-link"><i className="fas fa-building" /><span>Empresas</span></Link></li>
      }

      let bu = '/stores'
      if (this.context.user.store_id !== null && this.context.user.store_id !== 0) {
        if (this.context.user.branch_id !== null && this.context.user.branch_id !== 0) {
          bu = `/stores/${this.context.user.store_id}/branches/${this.context.user.branch_id}`
        } else {
          bu = `/stores/${this.context.user.store_id}`
        }
      }

      home = <li className="nav-item"><Link className="nav-link" to={`${bu}`}><i className="fas fa-home" /><span>Inicio</span></Link></li>

      
      /*

      if(this.context.user.sales_permission === "1") {
        if (this.context.user.store_id !== null && this.context.user.store_id !== 0) {
          if (this.context.user.branch_id !== null && this.context.user.branch_id !== 0) {
            sales = <li className="nav-item"><Link to={`/stores/${this.context.user.store_id}/branches/${this.context.user.branch_id}/sales`} className="nav-link"><i className="fas fa-hamburger" /><span>Promociones</span></Link></li>
          } else {
            sales = <li className="nav-item"><Link to={`/stores/${this.context.user.store_id}/branches`} className="nav-link"><i className="fas fa-percent" /><span>Promociones</span></Link></li>
          }
        }
      }
      if(this.context.user.products_permission === "1") {
        if (this.context.user.store_id !== null && this.context.user.store_id !== 0) {
          if (this.context.user.branch_id !== null && this.context.user.branch_id !== 0) {
            products = <li className="nav-item"><Link to={`/stores/${this.context.user.store_id}/branches/${this.context.user.branch_id}/categories`} className="nav-link"><i className="fas fa-hamburger" /><span>Productos</span></Link></li>
          } else {
            products = <li className="nav-item"><Link to={`/stores/${this.context.user.store_id}/branches`} className="nav-link"><i className="fas fa-hamburger" /><span>Productos</span></Link></li>
          }
        }
      }
      
      if(this.context.user.orders_permission === "1") {
        if (this.context.user.store_id !== null && this.context.user.store_id !== 0) {
          if (this.context.user.branch_id !== null && this.context.user.branch_id !== 0) {
            home = <li className="nav-item"><Link className="nav-link" to={`/stores/${this.context.user.store_id}/branches/${this.context.user.branch_id}/home`}><i className="fas fa-home" /><span>Home</span></Link></li>
          }
        }
      }

      */
    }

    return (
      <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">

        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
          <div className="sidebar-brand-icon rotate-n-15"><i className="fas fa-chart-line"/></div>
          <div className="sidebar-brand-text mx-3">Fidely Backoffice</div>
        </a>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Menú</div>

        {home}

        {users}

        {sales}

        {products}

        {stores}

        {clients}

        {cards}

        {transactions}

        {reports}

        <li className="nav-item"><Link className="nav-link" to="" onClick={this.props.logout}><i className="fas fa-sign-out-alt" /><span>Salir</span></Link></li>

        <hr className="sidebar-divider d-none d-md-block" />

      </ul>
    );
  }
}
export default NavBar;