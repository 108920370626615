import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewCategory extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            id: 0,
            category_name: '',
            base64: '',
            file: '',
            url: '',
            open: false,
            someChanges: false,
            message: ''
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeCategoryName = this.onChangeCategoryName.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.storeId !== undefined && params.categoryId !== undefined) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/categories/${params.categoryId}`

            if (params.branchId !== undefined) {
                url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/categories/${params.categoryId}`
            }

            trackPromise(
                axios.get(url, {
                    headers: { 'Access-Control-Allow-Origin': '*' }
                })
                .then(res => {
                    this.setState({ 
                        id: res.data.category.id,
                        category_name: res.data.category.name,
                        url: res.data.category.image_path 
                    });
                })
                .catch(function (error) {
                    console.log(error);
                })
            );
        }
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: URL.createObjectURL(file),
                    base64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        } else {
            var image = ''
            if (this.state.base64 !== '') {
                image = this.state.base64;
            } else {
                image = this.state.url;
            }

            const object = {
                name: this.state.category_name,
                image_path: image
            };

            let message = 'Categoría agregada!';
            let url = `${this.context.hubURL}/store/${params.storeId}/categories`;
            if (params.branchId !== undefined) {
                url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/categories`;
            }
            if (this.state.id !== 0) {
                message = 'Categoría actualizada!';
                url = `${this.context.hubURL}/stores/${params.storeId}/categories/${this.state.id}/update`;
                if (params.branchId !== undefined) {
                    url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/categories/${this.state.id}/update`;
                }
            }

            trackPromise(
                axios.post(url, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then((res) => {
                    this.setState({ open: true, message: message })
                }).catch((error) => {
                    console.log(error)
                })
            );
        }
    }

    onChangeCategoryName(e) {
        this.setState({ category_name: e.target.value, someChanges: true })
    }

    render() {
        let previewDiv, defined_class;
        if (this.state.file) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.file} alt='' /></div>
            defined_class = "col-md-9"
        } else if (this.state.url !== '') {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.url} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }

        return (
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <h6 className="m-0 font-weight-bold text-primary">Nueva categoría</h6>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className="form-group col-md-6">
                                <label for="inputName">Nombre Categoría</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Nombre categoría" 
                                    value={this.state.category_name} onChange={this.onChangeCategoryName} />
                                </div>

                                <div className="form-group col-md-6">
                                    <div className="row align-items-center">
                                        {previewDiv}
                                        <div className={defined_class}>
                                            <label for="inputFile">Logo (Max. 10MB)</label>
                                            <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Confirmar</button>
                            </div>
                        </form>
                    </div>
                </div>

                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.props.history.go(-1)}>
                    <Alert onClose={() => this.props.history.go(-1)} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }

}

export default NewCategory