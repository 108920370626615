import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomFieldsUtils from "../../utils/customFieldsUtils";

class CustomFieldsForm extends Component {

  render() {
    const {CustomFieldTypes} = new CustomFieldsUtils()

    const {
      custom_field_name,
      custom_field_type,
      custom_multiple_choice_field,
      custom_fields,
      onChangeCustomFieldName,
      onChangeCustomFieldType,
      onChangeCustomMultipleChoiceField,
      addCustomField,
      deleteCustomField,
      disableAddCustomField,
      invalidMultipleChoice,
      reachedMaxCustomFields,
    } = this.props;

    return (
      <>
      <div className="col-md-6">
        <div className="mb-3">
          <h6>Campo customizado</h6>
        </div>
        <div className="form-group col-md-6">
          <label>Nombre del campo</label>
          <input
            type="text"
            className="form-control"
            id="inputCustomName"
            value={custom_field_name}
            onChange={onChangeCustomFieldName}
          />
        </div>
        <div className="form-group col-md-6">
          <label for="selectType">Tipo de campo</label>
          <select 
            id="selectType" 
            className="form-control" 
            disabled={!custom_field_name} 
            readOnly={custom_field_type} 
            value={custom_field_type} 
            onChange={onChangeCustomFieldType}
          >
            <option value="NULL">-- Tipo ---</option>
            <option value={CustomFieldTypes.TEXT}>TEXTO</option>
            <option value={CustomFieldTypes.MULTIPLE_CHOICE}>MULTIPLE CHOICE</option>                                        
            <option value={CustomFieldTypes.DROPDOWN}>DESPLEGABLE</option>
          </select>
          {!custom_field_name && (
            <small className="form-text text-muted">Ingrese el nombre del campo antes de modificar el tipo.</small>
          )}
        </div>
        {
          (custom_field_type === CustomFieldTypes.MULTIPLE_CHOICE || custom_field_type === CustomFieldTypes.DROPDOWN) &&
            (<div className="form-group col-md-12">
              <input
                type="text"
                className="form-control"
                id="inputMultipleChoice"
                value={custom_multiple_choice_field}
                onChange={onChangeCustomMultipleChoiceField}
              />
              <small className="form-text text-muted">Ingresar las opciones separadas por comas, sin espacios</small>
            </div>)
        }
        <div className="form-group d-flex flex-column col-md-6 mt-3">
          <button 
            className="btn btn-outline-primary btn-sm"
            onClick={addCustomField}
            disabled={disableAddCustomField}
          >
            Agregar nuevo campo
          </button>
          {disableAddCustomField ?
            <small className="form-text text-muted">
              Ingresa nombre y tipo de campo
            </small>
            : invalidMultipleChoice ?
            <small className="form-text text-muted">
              <p className="text-danger">Ingresa las opciones correctamente</p>
            </small>
            : reachedMaxCustomFields &&
            <small className="form-text text-muted">
              <p className="text-danger">Solo puedes ingresar 4 campos</p>
            </small>
          }
        </div>
      </div>
      {
        custom_fields?.length > 0 &&
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <h6>Campos Existentes</h6>
              </div>
              <ul className="list-group list-group-flush">
                {custom_fields.map((field, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    <div>
                      <p className="mb-0">
                        <strong>{`Nombre:`}</strong> {field.name} - <strong>{`Tipo:`}</strong> {field.type}
                      </p>
                      {(field.type === CustomFieldTypes.MULTIPLE_CHOICE || field.type === CustomFieldTypes.DROPDOWN) && (
                        <div className="mt-1">
                          <strong>Opciones:</strong>
                          <p className="mb-0">
                            {field.multiple_choice_field}
                          </p>
                        </div>
                      )}
                    </div>
                    <button className="btn btn-danger btn-sm" onClick={(e) => deleteCustomField(e, index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
      }
      </>
    )
  }
}

export default CustomFieldsForm;
