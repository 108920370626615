import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import CardBranch from './CardBranch';
import UserBranchModal from './UserBranchModal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { NavLink, Redirect } from 'react-router-dom';
import Error from '../Error';
import { QRCode } from 'react-qrcode-logo';




function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Branches extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            branches: null,
            store: null,
            branchSelected: null,
            showModal: false,
            message: '',
            open: false,
        }

        this.onQRBranchClose = this.onQRBranchClose.bind(this)
        this.onQRBranchSelected = this.onQRBranchSelected.bind(this)
        this.onDeleted = this.onDeleted.bind(this)
    }

    onDeleted() {
        this.setState({ open: true, message: 'Sucursal eliminada.' });
    }

    onQRBranchSelected(branch) {
        this.setState({branchSelected: branch, showModal: true})
    }

    onQRBranchClose() {
        this.setState({branchSelected: null, showModal: false})
    }

    componentDidMount() {
        sessionStorage.removeItem("branch_name");
        sessionStorage.removeItem("lot_name");
        sessionStorage.removeItem("cupon_check_name");
        sessionStorage.removeItem("pos_name");

        const { match: { params } } = this.props;
        
        trackPromise(
            axios.get(`${this.context.hubURL}/stores/${params.storeId}/branches`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then(res => {
                    this.setState({ branches: res.data["branches"], store: res.data["store"], open: false });
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    render() {
        if(this.context.user.is_admin === "0") {
            if (this.context.user.branches_permission === "0" || this.context.user.branches_permission === 0) {
                if (this.context.user.branch_id !== null && this.context.user.branch_id !== 0) {
                    return <Redirect to={`/stores/${this.context.user.store_id}/branches/${this.context.user.branch_id}`} />
                } else {
                    return <Error message="No tienes acceso a esta sección." />
                }
            }
        }
        

        let content, storeName, newBranchButton, modalTitle, modalContent

        if (this.state.branches == null) {
            content = "La lista de sucursales está vacía."
            storeName = ""
        } else {
            storeName = ` - ${this.state.store.name}`
            content = <div className="container">
            { this.state.branches.map((branch) => (<CardBranch key={branch.id} branch={branch} onQRBranchSelected={this.onQRBranchSelected} onDeleted={this.onDeleted} />)) }
            </div>
        }

        if (this.state.store != null && this.context.user.branches_permission == 1) {
            newBranchButton = <Link to={`/stores/${this.state.store.id}/branches/new`} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
        }

        if(this.state.branchSelected != null) {
            modalTitle = this.state.branchSelected.name + " - Código QR";
              
            modalContent = <QRCode value={`/stores/${this.state.store.id}/branches/${this.state.branchSelected.id}`} />
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col text-left my-auto">
                        <h3 className="h3 mb-4 text-gray-800">Sucursales {storeName}</h3>
                    </div>
                    <div className="col text-right my-auto">
                        {newBranchButton}
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false, branchSelected: null}) } size="xl">
                    <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalContent}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({showModal: false, branchSelected: null}) }>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default Branches