import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

class Category extends Component {
    static contextType = SessionContext
    
    constructor() { 
            super()

            this.onDeleted = this.onDeleted.bind(this)
        }

    onDeleted() {
        trackPromise(
            axios.post(`${this.context.hubURL}/stores/${this.props.category.store_id}/branches/${this.props.category.branch_id}/categories/${this.props.category.id}/delete`, null, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }
            })
            .then((res) => {
                this.props.onDeleted()
            }).catch((error) => {
                console.log(error)
            })
        );
    }

    render() {
        let url = `/stores/${this.props.category.store_id}/categories/${this.props.category.id}/products`
        let editURL = `/stores/${this.props.category.store_id}/categories/${this.props.category.id}`

        if (this.props.category.branch_id != null) {
            url = `/stores/${this.props.category.store_id}/branches/${this.props.category.branch_id}/categories/${this.props.category.id}/products`
            editURL = `/stores/${this.props.category.store_id}/branches/${this.props.category.branch_id}/categories/${this.props.category.id}`
        }

        return (
                <div className="card">
                    <Link to={url}>
                        <div className="view overlay">
                            <img className="card-img-top" src={`${this.props.category.image_path}`} alt={this.props.category.name} style={{height: "100%", width: "100%", objectFit: "cover"}} />
                        </div>
                    </Link>

                    <div className="card-body">
                        <Link to={url}>
                            <h4 className="card-title font-weight-bold text-gray-800">{this.props.category.name}</h4>
                        </Link>
                        <div className="row">
                            <div className="col text-right">
                                <Link to={editURL}><a className="btn btn-warning btn-circle btn-sm" style={{marginRight: ".75rem", color: "#FFFFFF"}}><i className="fas fa-pencil-alt" style={{color: "#FFFFFF"}} /></a></Link>
                                <button type="button" onClick={this.onDeleted} className="btn btn-danger btn-circle btn-sm"><i className="fas fa-trash" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            
        );
    }
}

export default Category;