import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { TableHeader } from 'semantic-ui-react';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TransactionsTitle from './TransactionsTitle';
import ExcelUtils from '../../utils/ExcelUtils';

class TransactionsContent extends Component {

  constructor(){
    super()
    this.state = {
      filtered_transactions: null,
      branch_selected: null,
      transaction_type: null,
      lookupClient: null,
      date_from: null,
      date_to: null,
      client_name: null,
      page: 0,
      rowsPerPage: 5,
    }
    this.onChangeBranchSearch = this.onChangeBranchSearch.bind(this)
    this.onChangeTransactionTypeSearch = this.onChangeTransactionTypeSearch.bind(this)
    this.onChangeLookupClient = this.onChangeLookupClient.bind(this)
    this.onFilterFormSubmitted = this.onFilterFormSubmitted.bind(this)
    this.onChangeClientName = this.onChangeClientName.bind(this)
    this.onChangeDateFrom = this.onChangeDateFrom.bind(this)
    this.onChangeDateTo = this.onChangeDateTo.bind(this)
    this.cleanSearchSection = this.cleanSearchSection.bind(this)
  }

onChangeBranchSearch(selectedOption) { 
    this.setState({ branch_selected: selectedOption }) 
}

onChangeTransactionTypeSearch(selectedOption) { 
    this.setState({ transaction_type: selectedOption }) 
}

onChangeLookupClient(e) {
    this.setState({ lookupClient: e.target.value })
}

onChangeClientName(selectedOption) {
    this.setState({ client_name: selectedOption })
}

onChangeDateFrom(date) {
    this.setState({ date_from: date })
}

onChangeDateTo(date) {
    this.setState({ date_to: date })
}

onFilterFormSubmitted(e) {
    e.preventDefault();
    var tx = this.props.transactions;
    if (this.state.branch_selected !== null) {
        tx = tx.filter(t => t.branch_id == this.state.branch_selected.value);
    }

    if (this.state.transaction_type !== null) {
        tx = tx.filter(t => t.type == this.state.transaction_type.value);
    }

    if (this.state.lookupClient !== null) {
        tx = tx.filter(t => t.user_identification == this.state.lookupClient || t.user_card_number == this.state.lookupClient || t.card_number == this.state.lookupClient);
    }

    if (this.state.date_from !== null) {
        tx = tx.filter(t => {
            const transactionDate = new Date(t.date)
            if (this.state.date_to !== null) {
                return transactionDate >= this.state.date_from && transactionDate <= this.state.date_to
            }
            return transactionDate >= this.state.date_from 
        });
    } else if (this.state.date_to !== null) {
        tx = tx.filter(t => {
            const transactionDate = new Date(t.date)
            return transactionDate <= this.state.date_to
        });
    }

    if (this.state.client_name !== null) {
        tx = tx.filter(t => this.state.client_name.value == `${t.user_name} ${t.user_last_name}`);
    }

    this.setState({ filtered_transactions: tx })
}

cleanSearchSection(e) {
    e.preventDefault()
    this.setState({
        branch_selected: null,
        transaction_type: null,
        lookupClient: null,
        date_from: null,
        date_to: null,
        client_name: null,
        filtered_transactions: this.props.transactions,
    })
}

handleChangePage(event, newPage) {
  this.setState({ page: newPage });
}

handleChangeRowsPerPage(event) {
  this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0});
}

  render(){
    if (this.state.filtered_transactions == null) {
      this.state.filtered_transactions = this.props.transactions
    }
    let branchesSelect, clientsSelect, searchSection, datePicker;

    if (this.props.branches !== null) {
      branchesSelect = <div className="form-group col-md-3">
        <Select
          is="selectBranch"
          className="basic-single"
          classNamePrefix="select"
          placeholder="--- Sucursal ---"
          isSearchable={false}
          value={this.state.branch_selected} 
          onChange={this.onChangeBranchSearch}
          options={this.props.branches.map((branch) => (
              {value: branch.id, label: branch.name}
          ))}
        />
      </div>
    }
    if (this.props.clients !== null) {
      console.log(this.props.clients)
      clientsSelect = <div className="form-group col-md-3">
        <Select 
          isSearchable={true}
          id="selectClient" 
          className="basic-single"
          classNamePrefix="select"
          placeholder="--- Cliente ---"
          value={this.state.client_name} 
          onChange={this.onChangeClientName}
          options={this.props.clients.map((client) => (
              {value: client, label: client}
          ))}
        />
      </div>
    }
    datePicker = <div className="form-group col-md-5 d-flex">
      <ReactDatePicker 
        showIcon
        todayButton="Hoy"
        placeholderText="🗓️ --- Fecha desde ---"
        selected={this.state.date_from} 
        onChange={this.onChangeDateFrom}
        dateFormat="dd/MM/yyyy"
        maxDate={this.state.date_to !== null ? this.state.date_to : new Date()}
        className="mr-3"
      />
      <ReactDatePicker 
        showIcon
        todayButton="Hoy"
        placeholderText="🗓️ --- Fecha hasta ---"
        selected={this.state.date_to} 
        onChange={this.onChangeDateTo}
        dateFormat="dd/MM/yyyy"
        minDate={this.state.date_from}
        maxDate={new Date()}
      />
    </div>

    let transactionTypes = [
      {value: "PURCHASE", label: "Venta"},
      {value: "GIFTCARD_DOWNLOAD", label: "Descarga"},
      {value: "GIFTCARD_LOAD", label: "Carga"},
      {value: "SWAP", label: "Canje"},
      {value: "ANNULMENT", label: "Anulación"},
    ]
          searchSection = <div className="card shadow mb-4 d-flex " style={{padding: "0"}}>
              <div className="card-header py-3">
                  <div className="row">
                      <h6 className="m-0 font-weight-bold text-primary">Filtrar Transacciones</h6>
                  </div>
              </div>
              <div className="card-body">
                  <form onSubmit={this.onFilterFormSubmitted}>
                      <div className="form-row justify-content-between">
                          {branchesSelect}
                          <div className="form-group col-md-3">
                              <Select
                                  id="selectTransactionType" 
                                  className="basic-single" 
                                  classNamePrefix="select"
                                  placeholder="--- Tipo Transacción ---"
                                  isSearchable={false}
                                  value={this.state.transaction_type} 
                                  onChange={this.onChangeTransactionTypeSearch}
                                  options={transactionTypes}
                              />
                          </div>
                          {clientsSelect}
                          <div className="form-group col-md-3">
                              <input className="form-control" type="text" id="inputLookupClient" 
                                  placeholder="Nro. Documento o Tarjeta" onChange={this.onChangeLookupClient} />
                          </div>
                          {datePicker}
                      </div>
                        <div className="form-group text-center d-flex justify-content-end align-items-end">
                          <button type="submit" className="btn btn-primary mr-2">Buscar</button>
                          <button type="submit" className="btn btn-secondary mt-2" onClick={this.cleanSearchSection}>Limpiar</button>
                        </div>
                  </form>
              </div>
          </div>    
    let {title} = this.props
    let excelUtils = new ExcelUtils()
    return <>
    <TransactionsTitle title={title} exportDataFunc={() => excelUtils.exportData(title, this.state.filtered_transactions)}/>
    <div className="row">
      {searchSection}
      <div className="row">
        <Table stickyHeader aria-label="sticky table">
          <TableHeader>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">Fecha y hora</TableCell>
            <TableCell align="center">Comercio</TableCell>
            <TableCell align="center">Título</TableCell>
            <TableCell align="center">Monto Final</TableCell>
            <TableCell align="center">Monto Original</TableCell>
            <TableCell align="center">Descuento</TableCell>
            <TableCell align="center">Nro. Ticket</TableCell>
            <TableCell align="center">Cliente</TableCell>
            <TableCell align="center">Tarjeta</TableCell>
          </TableHeader>
          <TableBody>
            {(this.state.rowsPerPage > 0 ? this.state.filtered_transactions.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage) : this.state.filtered_transactions).map((t, index) => (
            <TableRow hover key={t.id}>
                <TableCell align="center">{t.id}</TableCell>
                <TableCell align="center">{new Date(t.date).toLocaleString('es')}</TableCell>
                <TableCell align="center">{t.branch_name}</TableCell>
                <TableCell align="center">{t.reason}</TableCell>
                <TableCell align="center">{t.final_amount}</TableCell>
                <TableCell align="center">{t.amount}</TableCell>
                <TableCell align="center">{t.discount}</TableCell>
                <TableCell align="center">{t.ticket_id}</TableCell>
                <TableCell align="center">{t.user_name} {t.user_last_name} ({t.user_identification})</TableCell>
                <TableCell align="center">{t.card_number}</TableCell>
            </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
                <TablePagination count={this.state.filtered_transactions.length} rowsPerPage={this.state.rowsPerPage} labelRowsPerPage="Items por página"
                rowsPerPageOptions={[5, 10, 25, 50, 100]} onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                onChangePage={this.handleChangePage.bind(this)} page={this.state.page} />
            </TableRow>
          </TableFooter>
        </Table>
      </div> 
    </div>
    </>
  }


}

export default TransactionsContent