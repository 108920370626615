import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import CardClientTypeUpdate from './CardClientTypeUpdate';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ClientTypeUpdate extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            clientTypeUpdates: [],
            message: '',
            open: false,
            isBranch: false,
        }

        this.onDeleted = this.onDeleted.bind(this)
    }

    onDeleted() {
        this.setState({ open: true, message: 'Tipo de cliente eliminado.' });
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        let url = `${this.context.hubURL}/stores/${params.storeId}/client_types_updates`
        
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                .then(res => { this.setState({ clientTypeUpdates: res.data, open: false }); })
                .catch(function (error) { console.log(error); })
        );
    }

    render() {
        const { match: { params } } = this.props;
        
        let content, newButton
        
        if (this.state.clientTypeUpdates == null || this.state.clientTypeUpdates.length == 0) {
            content = "No hay cambios de categoría creados."
        } else {
            content = <div className="container">
                { this.state.clientTypeUpdates.map((clientTypeUpdate) => (
                    <CardClientTypeUpdate  key={clientTypeUpdate.id} clientTypeUpdate={clientTypeUpdate} onDeleted={this.onDeleted} /> 
                )) 
                }
            </div>
        }

        newButton = <div className="col text-right my-auto">
                <Link to={`/stores/${params.storeId}/client_types_updates/new`} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
            </div>

        return(
            <div className="container">
                <div className="row">
                    <div className="col text-left my-auto">
                        <h3 className="h3 mb-4 text-gray-800">Cambios de Categoría</h3>
                    </div>
                    {newButton}
                </div>
                <div className="row">
                    {content}
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default ClientTypeUpdate