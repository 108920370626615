import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

class OrderItem extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()

        this.state = {
            clicked: false
        }

        this.statusChanged = this.statusChanged.bind(this)
    }

    statusChanged(e) {
        e.preventDefault()
        let status = e.target.getAttribute('data-tag')

        const obj = {
            status: status,
        };

        trackPromise(
            axios.post(`${this.context.hubURL}/orders/${this.props.order.id}/status`, obj, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    this.props.order.status = status
                    this.props.showMessage('Estado del pedido modificado')
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    render() {
        if(this.state.clicked) {
            return <Redirect to={`/order/${this.props.order.id}`} />
        }

        let statusName, statusClass
        
        switch(this.props.order.status) {
            case 'Delivered':
                statusName = "Entregado"
                statusClass = "btn-success"
                break
            case 'Taken':
                statusName = "Retirado"
                statusClass = "btn-success"
                break
            case 'Canceled':
                statusName = "Cancelado"
                statusClass = "btn-secondary"
                break
            case 'Ready':
                statusName = "Listo para retirar"    
                statusClass = "btn-primary"
                break
            case 'InProgress':
                statusName = "En Preparación"
                statusClass = "btn-primary"
                break
            case 'Confirmed':
                statusName = "Confirmado"
                statusClass = "btn-warning"
                break
            case 'Received':
                statusName = "Recibido"
                statusClass = "btn-warning"
                break
            case "Created":
                statusName = "Pendiente"
                statusClass = "btn-danger"
                break
            case "Delivering":
                statusName = "En camino"
                statusClass = "btn-success"
                break
            default:
                statusName = this.props.order.status
                statusClass = "btn-danger"
                break
        }
        return (
            <tr style={{cursor: "pointer"}}>
                <th scope="row" onClick={() => this.setState({clicked: true})}>{this.props.order.id}</th>
                <td onClick={() => this.setState({clicked: true})}>{
                    this.props.order.items.map((item) => {
                        if (item.product != null) {
                            return item.quantity + " " + item.product.name
                        } else if (item.sale != null) {
                            return item.quantity + " " + item.sale.title
                        }
                        return ""
                    })
                    .reduce((prev, curr) => [prev, ' - ', curr])
                }</td>
                <td onClick={() => this.setState({clicked: true})}>{this.props.order.total_price}</td>
                <td onClick={() => this.setState({clicked: true})}>{
                    this.props.order.type === "TakeAway" ? ( "SI" ) : ( "NO" )
                }</td>
                <td onClick={() => this.setState({clicked: true})}>{
                    this.props.order.payment_method === "CASH" ? ( "Efectivo" ) : ( "Tarjeta" )
                }</td>
                <td>
                    <div className="btn-group">
                        <button type="button" className={`btn ${statusClass}`}>{statusName}</button>
                        <button type="button" className={`btn ${statusClass}  dropdown-toggle dropdown-toggle-split`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu">
                            <a className="dropdown-item" href="#" onClick={this.statusChanged} data-tag="Created">Pendiente</a>
                            <a className="dropdown-item" href="#" onClick={this.statusChanged} data-tag="Received">Recibido</a>
                            <a className="dropdown-item" href="#" onClick={this.statusChanged} data-tag="Confirmed">Confirmado</a>
                            <a className="dropdown-item" href="#" onClick={this.statusChanged} data-tag="InProgress">En Preparación</a>
                            <a className="dropdown-item" href="#" onClick={this.statusChanged} data-tag="Ready">Listo para retirar</a>
                            <a className="dropdown-item" href="#" onClick={this.statusChanged} data-tag="Delivering">En camino</a>
                            <a className="dropdown-item" href="#" onClick={this.statusChanged} data-tag="Delivered">Entregado</a>
                            <a className="dropdown-item" href="#" onClick={this.statusChanged} data-tag="Taken">Retirado</a>
                            <a className="dropdown-item" href="#" onClick={this.statusChanged} data-tag="Canceled">Cancelado</a>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }
}
export default OrderItem;