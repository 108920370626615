import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DatePicker from 'react-datepicker';
import { addMonths, format } from 'date-fns';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

import "react-datepicker/dist/react-datepicker.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewSale extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            title: '',
            file: '',
            base64: '',
            description: '',
            normal_price: 0,
            sale_price: 0,
            date_from: '',
            date_to: '',
            open: false,
            data: null,
            startDate: new Date()
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
        this.onChangeTitle = this.onChangeTitle.bind(this)
        this.onChangeDescription = this.onChangeDescription.bind(this)
        this.onChangeNormalPrice = this.onChangeNormalPrice.bind(this)
        this.onChangeSalePrice = this.onChangeSalePrice.bind(this)
        this.onChangeDateFrom = this.onChangeDateFrom.bind(this)
        this.onChangeDateTo = this.onChangeDateTo.bind(this)
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 7) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                file: URL.createObjectURL(file),
                base64: reader.result
                });
            }
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        const productObject = {
            title: this.state.title,
            image_path: this.state.base64,
            description: this.state.description,
            normal_price: this.state.normal_price,
            sale_price: this.state.sale_price,
            date_from: format(this.state.date_from, "yyyy-MM-dd"),
            date_to: format(this.state.date_to, "yyyy-MM-dd"),
        };

        trackPromise(
            axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/sales`, productObject, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    this.setState({ open: true })
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    onChangeTitle(e) {
        this.setState({ title: e.target.value })
    }
    
    onChangeDescription(e) {
        this.setState({ description: e.target.value })
    }
    
    onChangeNormalPrice(e) {
        this.setState({ normal_price: e.target.value })
    }

    onChangeSalePrice(e) {
        this.setState({ sale_price: e.target.value })
    }

    onChangeDateFrom(e) {
        this.setState({ date_from: e })
    }

    onChangeDateTo(e) {
        this.setState({ date_to: e })
    }

    render() {
        let previewDiv, defined_class;
        if (this.state.file) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.file} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }

        return (
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">Nueva Promocion</h6>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className="form-group col-md-6">
                                    <label for="inputName">Nombre Promoción</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Nombre promoción" 
                                    value={this.state.title} onChange={this.onChangeTitle} />
                                </div>

                                <div className="form-group col-md-6">
                                    <div className="row align-items-center">
                                        {previewDiv}
                                        <div className={defined_class}>
                                            <label for="inputFile">Imágen (Max. 1MB)</label>
                                            <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label for="inputDescription">Descripción</label>
                                <input className="form-control" type="text" 
                                id="inputDescription" placeholder="Descripción" 
                                value={this.state.description} onChange={this.onChangeDescription} />
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label for="inputNormalPrice">Precio sin promoción</label>
                                    <input className="form-control" type="number" 
                                    id="inputNormalPrice" placeholder="Precio normal" 
                                    value={this.state.normal_price} onChange={this.onChangeNormalPrice} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputSalePrice">Precio promoción</label>
                                    <input className="form-control" type="number" 
                                    id="inputSalePrice" placeholder="Precio" 
                                    value={this.state.sale_price} onChange={this.onChangeSalePrice} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label for="inputDateFrom">Fecha Inicio</label>
                                    <br />
                                    <DatePicker id="inputDateFrom" className="form-control" dateFormat="dd/MM/yyyy" selected={this.state.date_from} onChange={this.onChangeDateFrom} minDate={new Date()} maxDate={addMonths(new Date(), 12)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputDateTo">Fecha Finalización</label>
                                    <br />
                                    <DatePicker id="inputDateTo" className="form-control" dateFormat="dd/MM/yyyy" selected={this.state.date_to} onChange={this.onChangeDateTo} minDate={new Date()} maxDate={addMonths(new Date(), 12)} />
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Confirmar</button>
                            </div>
                        </form>
                        <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.props.history.go(-1)}>
                            <Alert onClose={() => this.props.history.go(-1)} severity="success">
                                Promoción agregada correctamente!
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
            </div>

        )
    }

}

export default NewSale