import React, { Component } from 'react';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import CardBranch from './CardBranch';
import Error from '../Error';


class Branch extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            store: null,
            branch: null,
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.storeId !== undefined) {
            if (params.branchId !== undefined) {
                trackPromise(
                    axios.get(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}`, {
                        headers: { 'Access-Control-Allow-Origin': '*', }
                    })
                        .then(res => {
                            this.setState({ store: res.data["store"], branch: res.data["branch"] });
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                );
            }
        }
    }

    render() {
        if (this.state.store === null && this.state.branch === null) {
            return (<div></div>)
        }

        if(this.context.user.is_admin === "0") {
            if (this.context.user.branch_id !== this.state.branch.id) {
                return <Error message="No tienes acceso a esta sección." />
            }
        }
        
        return(
            <div className="container">
                <div className="row">
                    <div className="col text-left my-auto">
                        <h3 className="h3 mb-4 text-gray-800">{this.state.store.name} - {this.state.branch.name}</h3>
                    </div>
                </div>
                <div className="row">
                    <CardBranch key={this.state.branch.id} branch={this.state.branch} />
                </div>
            </div>
        )
    }
}

export default Branch