import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ crumbs }) => {
  if (crumbs.length <= 1) {
    return null;
  }

  let branchName = "";
  if(sessionStorage.getItem("branch_name") !== null) {
    branchName = sessionStorage.getItem("branch_name");
  }

  let storeName = "";
  if(sessionStorage.getItem("store_name") !== null) {
    storeName = sessionStorage.getItem("store_name");
  }

  let lotName = "";
  if(sessionStorage.getItem("lot_name") !== null) {
    lotName = sessionStorage.getItem("lot_name");
  }

  let pollName = "";
  if(sessionStorage.getItem("poll_name") !== null) {
    pollName = sessionStorage.getItem("poll_name");
  }

  let cuponChekcName = "";
  if(sessionStorage.getItem("cupon_check_name") !== null) {
    cuponChekcName = sessionStorage.getItem("cupon_check_name");
  }

  let posName = "";
  if(sessionStorage.getItem("pos_name") !== null) {
    posName = sessionStorage.getItem("pos_name");
  }

  let newCrumbs = crumbs.map(element => {
    let n = element.name;
    if(n.includes("$STORE_NAME")) {
      n = n.replace("$STORE_NAME", storeName);
    }

    if(n.includes("$BRANCH_NAME")) {
      n = n.replace("$BRANCH_NAME", branchName);
    }

    if(n.includes("$LOT_NAME")) {
      n = n.replace("$LOT_NAME", lotName);
    }

    if(n.includes("$POLL_NAME")) {
      n = n.replace("$POLL_NAME", pollName);
    }

    if(n.includes("$CUPON_CHECK_NAME")) {
      n = n.replace("$CUPON_CHECK_NAME", cuponChekcName);
    }

    if(n.includes("$POS_NAME")) {
      n = n.replace("$POS_NAME", posName);
    }

    return {name: n, path: element.path}
  });

  return (
    <div className="mb-1">
      {newCrumbs.map(({ name, path }, key) =>
        key + 1 === newCrumbs.length ? (
          <span key={key} className="text-primary">
            {name}
          </span>
        ) : (
          <Link key={key} className="font-weight-bold mr-1" style={{color: "#858796"}} to={path}>
            {name}&nbsp;/&nbsp;
          </Link>
        )
      )}
    </div>
  );
};

export default Breadcrumbs;