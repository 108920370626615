import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Product from './Product';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Products extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            category: null,
            products: null,
            message: '',
            open: false
        }

        this.onDeleted = this.onDeleted.bind(this)
    }

    onDeleted() {
        this.setState({ open: true, message: 'Producto eliminado.' });
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        let url = `${this.context.hubURL}/stores/${params.storeId}/categories/${params.categoryId}/products`
        if (params.branchId !== undefined) {
            url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/categories/${params.categoryId}/products`
        }

        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' } })
            .then(res => {
                this.setState({ category: res.data["category"], products: res.data["products"], open: false });
            })
            .catch(function (error) {
                console.log(error);
            })
        );
    }

    render() {
        const { match: { params } } = this.props;
        
        let content
        let newURL = `/stores/${params.storeId}/categories/${params.categoryId}/products/new`
        if (params.branchId !== undefined) {
            newURL = `/stores/${params.storeId}/branches/${params.branchId}/categories/${params.categoryId}/products/new`
        }

        if (this.state.products == null) {
            return (
                <div className="container-fluid">
                    <br />
                    <div className="row align-items-center">
                        <div className="col">
                            <h1>Productos</h1>
                        </div>
                        <div className="col-md-auto">
                            <Link to={newURL} className="btn btn-success" style={{ margin: "0 auto"}}><i className="fas fa-plus" /></Link>
                        </div>
                    </div>
                    
                    <br />

                    <p>Esta categoría no tiene productos.</p>
                </div>
            )
        } else {
            content = <div className="card-columns">
            { this.state.products.map((product) => (<Product key={product.id} product={product} fromStore={params.branchId === undefined} onDeleted={this.onDeleted} />)) }
            </div>
        }

        return(
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-2">
                                <img src={`${this.state.category.image_path}`} alt={`${this.state.category.name}`} className="card-img" style={{height: "100%", width: "100%", objectFit: "cover"}} />
                            </div>
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">{this.state.category.name}</h6>
                            </div>
                            <div className="col text-right my-auto">
                                <Link to={newURL} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {content}
                    </div>
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default Products