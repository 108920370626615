import React, { Component } from 'react';
import axios from 'axios'
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import "react-datepicker/dist/react-datepicker.css";
import TransactionsContent from '../../common/Transactions/TransactionsContent';

class StoreTransactions extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            transactions: null,
            clients: null,
            branches: null
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.storeId !== undefined) {
            this.getTransactions(params.storeId, transactions => {
                let clientsList = new Set()
                transactions.map(e => e.user_name !== null & e.user_last_name !== null && clientsList.add(`${e.user_name} ${e.user_last_name}`))
                this.setState({clients: clientsList})
                this.getBranches(params.storeId, branches => {
                    this.setState({ transactions: transactions, filtered_transactions: transactions, branches: branches["branches"] });
                });
            });
        }
    }

    getTransactions(storeId, callback) {
        let url = `${this.context.hubURL}/stores/${storeId}/transactions`;
        this.runPromise(url, callback);
    }

    getBranches(storeId, callback) {
        let url = `${this.context.hubURL}/stores/${storeId}/branches`;
        this.runPromise(url, callback);
    }

    runPromise(url, callback) {
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                .then(res => { callback(res.data); })
                .catch(function (error) { callback(null) })
        );
    }


    render() {
        const { match: { params } } = this.props;

        let title;
        let transactionsContent;
        let storeName = "";
        if(sessionStorage.getItem("store_name") !== null) {
            storeName = sessionStorage.getItem("store_name");
        }

        title = storeName + " - Transacciones";
        if(this.state.transactions != null) {
            transactionsContent = <TransactionsContent 
                transactions={this.state.transactions} 
                branches={this.state.branches}
                clients={Array.from(this.state.clients)}
                title={title}
            />
        } else {
            transactionsContent = <p>Esta marca aún no tiene transacciones.</p>
        }

        return(
            <div className="container">
                {transactionsContent}
            </div>
        )
    }
}

export default StoreTransactions