import React, { Component } from 'react';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { Link } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';

class Product extends Component {
    static contextType = SessionContext
    
    constructor(props) {
        super(props)

        var url = `stores/${this.props.product.store_id}/branches/${this.props.product.branch_id}/categories/${this.props.product.category_id}/products/${this.props.product.id}`
        if (this.props.fromStore) {
            url = `stores/${this.props.product.store_id}/categories/${this.props.product.category_id}/products/${this.props.product.id}`
        }

        this.state = {
            product: this.props.product,
            url: url
        }

        this.onFeaturedChanged = this.onFeaturedChanged.bind(this)
        this.onAvailableChanged = this.onAvailableChanged.bind(this)
        this.onDeleted = this.onDeleted.bind(this)
    }

    onAvailableChanged(e) {
        let isAvailable
        if(e.target.value === 'true' && this.props.product.available === 0) {
            isAvailable = 1
        } else if(e.target.value === 'false' && this.props.product.available === 1) {
            isAvailable = 0
        } else {
            return
        }

        const obj = {
            available: isAvailable,
        };

        trackPromise(
            axios.post(`${this.context.hubURL}/${this.state.url}/available`, obj, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    let p = this.state.product 
                    p.available = isAvailable
                    this.setState({ product: p })
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    onFeaturedChanged(e) {
        let isFeatured
        if(e.target.value === 'true' && this.props.product.featured === 0) {
            isFeatured = 1
        } else if(e.target.value === 'false' && this.props.product.featured === 1) {
            isFeatured = 0
        } else {
            return
        }

        const obj = {
            featured: isFeatured,
        };

        trackPromise(
            axios.post(`${this.context.hubURL}/${this.state.url}/featured`, obj, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    let p = this.state.product 
                    p.featured = isFeatured
                    this.setState({ product: p })
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    onDeleted() {
        trackPromise(
            axios.post(`${this.context.hubURL}/${this.state.url}/delete`, null, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }
            })
            .then((res) => {
                this.props.onDeleted()
            }).catch((error) => {
                console.log(error)
            })
        );
    }

    render() {
        let availableProductClass, notAvailableProductClass, featuredProductClass, notFeaturedProductClass, op
        
        var editURL = `/stores/${this.props.product.store_id}/branches/${this.props.product.branch_id}/categories/${this.props.product.category_id}/products/${this.props.product.id}`
        if (this.props.fromStore) {
            editURL = `/stores/${this.props.product.store_id}/categories/${this.props.product.category_id}/products/${this.props.product.id}`
        }

        if (this.state.product.available === 1) {
            availableProductClass = "active"
            notAvailableProductClass = ""
            op = "100%"
        } else {
            availableProductClass = ""
            notAvailableProductClass = "active"
            op = "50%"
        }

        if (this.state.product.featured === 1) {
            featuredProductClass = "active"
            notFeaturedProductClass = ""
        } else {
            featuredProductClass = ""
            notFeaturedProductClass = "active"
        }

        return (
            <div className="card" style={{opacity: op}}>
                <div className="view overlay">
                    <img className="card-img-top" src={`${this.props.product.image_path}`} alt={`${this.props.product.name}`} style={{height: "100%", width: "100%", objectFit: "cover"}} />
                </div>

                <div className="card-body">
                    <h4 className="card-title font-weight-bold">{this.props.product.name}</h4>
                    <p className="mb-2">$ {this.props.product.price}</p>
                    <p className="card-text">{this.props.product.description}</p>
                    <hr className="my-4" />

                    <div className="btn-group btn-group-toggle mb-2" date-toggle="available_buttons">
                        <label className={`btn btn-outline-success ${availableProductClass}`}>
                            <input type="radio" id="availableProduct" name="a" value="true" checked={availableProductClass === 'active'} onChange={this.onAvailableChanged} /> Disponible
                        </label>
                        <label className={`btn btn-outline-success ${notAvailableProductClass}`}>
                            <input type="radio" id="notAvailableProduct" name="a" value="false" checked={notAvailableProductClass === 'active'} onChange={this.onAvailableChanged} /> No Disponible
                        </label>
                    </div>
                    <hr className="my-4" />
                    <div className="btn-group btn-group-toggle mb-2" date-toggle="featured_buttons">
                        <label className={`btn btn-outline-success ${featuredProductClass}`}>
                            <input type="radio" id="featuredProduct" name="a" value="true" checked={featuredProductClass === 'active'} onChange={this.onFeaturedChanged} /> Destacado
                        </label>
                        <label className={`btn btn-outline-success ${notFeaturedProductClass}`}>
                            <input type="radio" id="notFeaturedProduct" name="a" value="false" checked={notFeaturedProductClass === 'active'} onChange={this.onFeaturedChanged} /> No Destacado
                        </label>
                    </div>
                    <hr className="my-4" />
                    <div className="row">
                        <div className="col text-right">
                            <Link to={editURL}><a className="btn btn-warning btn-circle btn-sm" style={{marginRight: ".75rem", color: "#FFFFFF"}}><i className="fas fa-pencil-alt" style={{color: "#FFFFFF"}} /></a></Link>
                            <button type="button" onClick={this.onDeleted} className="btn btn-danger btn-circle btn-sm"><i className="fas fa-trash" /></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Product;