import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import DatePicker from 'react-datepicker';
import { addMonths, format } from 'date-fns';
import { Redirect } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewClient extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            id: 0,
            name: '',
            last_name: '',
            identification: '',
            born_date: '',
            email: '',
            sex: '',
            phone: '',
            phone_company: '',
            card_number: '',
            client_type_id: 0,
            clientTypes: null,
            open: false,
            someChanges: false,
            urlToRedirect: null,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        this.onChangeLastName = this.onChangeLastName.bind(this)
        this.onChangeIdentification = this.onChangeIdentification.bind(this)
        this.onChangeBornDate = this.onChangeBornDate.bind(this)
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangeSex = this.onChangeSex.bind(this)
        this.onChangePhone = this.onChangePhone.bind(this)
        this.onChangePhoneCompany = this.onChangePhoneCompany.bind(this)
        this.onChangeCardNumber = this.onChangeCardNumber.bind(this)
        this.onChangeClientType = this.onChangeClientType.bind(this)
        this.setClientTypes = this.setClientTypes.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        var url = `${this.context.hubURL}/stores/${params.storeId}/client_types`;
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' }})
                .then(res => {
                    this.setClientTypes(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    setClientTypes(data) {
        this.lookForClient(data.filter(ct => ct.deleted === 0));
    }

    lookForClient(clientTypes) {
        const { match: { params } } = this.props;

        if (params.clientID != null) {
            var url = `${this.context.hubURL}/stores/${params.storeId}/clients`;
            url = url + `/${params.clientID}`

            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' }})
                    .then(res => {
                        var bornDate = ""
                        if (res.data.born_date.includes('.')) {
                            bornDate = '';
                        } else if (res.data.born_date.includes('-')) {
                            let parts = res.data.born_date.split('-');
                            var timestamp = Date.parse(parts[1]+"-"+parts[2]+"-"+parts[0]+" 00:00:00");
                            if (isNaN(timestamp) == false) {
                                bornDate = new Date(timestamp);
                            }
                        } else {
                            let parts = res.data.born_date.split('/');
                            var timestamp = Date.parse(parts[2]+"-"+parts[1]+"-"+parts[0]+" 00:00:00");
                            if (isNaN(timestamp) == false) {
                                bornDate = new Date(timestamp);
                            }
                        }

                        this.setState({ 
                            name: res.data.name,
                            last_name: res.data.last_name,
                            identification: res.data.identification,
                            sex: res.data.sex,
                            email: res.data.email,
                            phone: res.data.phone,
                            client_type_id: res.data.client_type_id.toString(),
                            born_date: bornDate,
                            clientTypes: clientTypes,
                            card_number: res.data.card_number,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            );
        } else {
            this.setState({ clientTypes: clientTypes, });
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.goToClients();
            return
        }

        let clientTypeId = parseInt(this.state.client_type_id);
        let storeId = parseInt(params.storeId);

        var born_date = this.state.born_date;
        try {
            born_date = format(this.state.born_date, "yyyy-MM-dd")
          } catch(err) {
            console.log("Error cambiando la fecha");
          }
        

        const obj = {
            name: this.state.name,
            last_name: this.state.last_name,
            identification: this.state.identification,
            sex: this.state.sex,
            email: this.state.email,
            phone: this.state.phone,
            client_type_id: clientTypeId,
            store_id: storeId,
            born_date: born_date,
            card_number: this.state.card_number,
        };

        var url = `${this.context.hubURL}/stores/${params.storeId}/clients`;
        var message = 'Cliente agregado!';
        if (params.clientID != null) {
            url = url + `/${params.clientID}`
            message = 'Cliente editado!'
        }
        trackPromise(
            axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*' }})
            .then((res) => { this.setState({ open: true, message: message }) })
            .catch((error) => { console.log(error) })
        );
    }

    goToClients() {
        const { match: { params } } = this.props;
        this.setState({ urlToRedirect: `/stores/${params.storeId}/clients` });
    }

    onChangeName(e) {
        this.setState({ name: e.target.value, someChanges: true })
    }

    onChangeLastName(e) {
        this.setState({ last_name: e.target.value, someChanges: true })
    }

    onChangeIdentification(e) {
        this.setState({ identification: e.target.value, someChanges: true })
    }

    onChangeBornDate(e) {
        this.setState({ born_date: e, someChanges: true })
    }

    onChangeSex(e) {
        this.setState({ sex: e.target.value, someChanges: true })
    }

    onChangePhone(e) {
        this.setState({ phone: e.target.value, someChanges: true })
    }

    onChangePhoneCompany(e) {
        this.setState({ phone_company: e.target.value, someChanges: true })
    }

    onChangeEmail(e) {
        this.setState({ email: e.target.value, someChanges: true })
    }

    onChangeCardNumber(e) {
        this.setState({ card_number: e.target.value, someChanges: true })
    }

    onChangeClientType(e) {
        this.setState({ client_type_id: e.target.value, someChanges: true })
    }

    render() {
        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }

        let clientTypeSelect
        if (this.state.clientTypes == null) {
            clientTypeSelect = ""
        } else {
            clientTypeSelect = <div className="form-group col-md-4">
                <label for="selectClientType">Tipo Cliente</label>
                <select id="selectClientType" className="form-control" value={this.state.client_type_id} onChange={this.onChangeClientType}>
                    { this.state.clientTypes.map((clientType) => (
                        <option value={clientType.id}>{clientType.name}</option>
                    )) 
                    }
                </select>
            </div>
        }

        let minDate = format(addMonths(new Date(), -1200), "yyyy-MM-dd")
        let maxDate = format(new Date(), "yyyy-MM-dd")
        
        return (
            <div className="container-fluid">
                <h1 className="mt-4">Nuevo Cliente</h1>
                <br />
    
                <form onSubmit={this.onFormSubmitted}>
                    <div className="form-row align-items-center">
                        <div className="form-group col-md-4">
                            <label for="inputName">Nombre</label>
                            <input className="form-control" type="text" 
                            id="inputName" placeholder="Juan" 
                            value={this.state.name} onChange={this.onChangeName} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputLastName">Apellido</label>
                            <input className="form-control" type="text" 
                            id="inputLastName" placeholder="Perez" 
                            value={this.state.last_name} onChange={this.onChangeLastName} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputIdentification">Documento</label>
                            <input className="form-control" type="text" 
                            id="inputIdentification" placeholder="12345678" 
                            value={this.state.identification} onChange={this.onChangeIdentification} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label for="inputDateFrom">Fecha Nacimiento</label>
                            <br />
                            <DatePicker id="inputDateFrom" className="form-control" dateFormat="dd/MM/yyyy" selected={this.state.born_date} onChange={this.onChangeBornDate} maxDate={maxDate} minDate={minDate} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="selectSex">Sexo</label>
                            <select id="selectSex" className="form-control" value={this.state.sex} onChange={this.onChangeSex}>
                                <option value="M">Hombre</option>
                                <option value="F">Mujer</option>
                            </select>
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputEmail">E-mail</label>
                            <input className="form-control" type="email" 
                            id="inputEmail" placeholder="casilla@correo.com" 
                            value={this.state.email} onChange={this.onChangeEmail} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label for="inputPhone">Teléfono</label>
                            <input className="form-control" type="phone" 
                            id="inputPhone" placeholder="(XX) XXXX XXXX" 
                            value={this.state.phone} onChange={this.onChangePhone} />
                        </div>
                        {clientTypeSelect}
                        <div className="form-group col-md-4">
                            <label for="inputCardNumber">Nro. Tarjeta</label>
                            <input className="form-control" type="text" 
                            id="inputCardNumber" placeholder="XXXX XXXX XXXX XXXXX" 
                            value={this.state.card_number} onChange={this.onChangeCardNumber} />
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Confirmar</button>
                    </div>
                </form>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.goToClients()}>
                    <Alert onClose={() => this.goToClients()} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }

}

export default NewClient