import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import YesNoSwitch from '../YesNoSwitch';
import { Redirect } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewStore extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            id: 0,
            name: '',
            file: '',
            base64: '',
            url: '',
            primaryColor: '',
            secondaryColor: '',
            letterColor: '',
            portal_url: '',
            currency_symbol: '',
            tax_id: '',
            loyalty: 0,
            giftcard: 0,
            lot: 0,
            portal: 0,
            polls: 0,
            payments: 0,
            allow_sms: 0,
            allow_email: 0,
            allow_ticket_number: 0,
            allow_payment_method: 0,
            open: false,
            someChanges: false,
            message: '',
            openStores: false
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        this.onChangePrimaryColor = this.onChangePrimaryColor.bind(this)
        this.onChangeSecondaryColor = this.onChangeSecondaryColor.bind(this)
        this.onChangeLetterColor = this.onChangeLetterColor.bind(this)
        this.onChangePortalURL = this.onChangePortalURL.bind(this)
        this.onChangeCurrencySymbol = this.onChangeCurrencySymbol.bind(this)
        this.onChangeTaxID = this.onChangeTaxID.bind(this)
        this.onChangeLoyalty = this.onChangeLoyalty.bind(this)
        this.onChangeGiftcard = this.onChangeGiftcard.bind(this)
        this.onChangeLot = this.onChangeLot.bind(this)
        this.onChangePortal = this.onChangePortal.bind(this)
        this.onChangePayments = this.onChangePayments.bind(this)
        this.onChangePolls = this.onChangePolls.bind(this)
        this.onChangeAllowSMS = this.onChangeAllowSMS.bind(this)
        this.onChangeAllowEmail = this.onChangeAllowEmail.bind(this)
        this.onChangePaymentMethod = this.onChangePaymentMethod.bind(this)
        this.onChangeTicketNumber = this.onChangeTicketNumber.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.storeId !== undefined) {
            trackPromise(
                axios.get(`${this.context.hubURL}/stores/${params.storeId}`, {
                    headers: { 'Access-Control-Allow-Origin': '*' }
                })
                .then(res => {
                    this.setState({ 
                        id: res.data.id,
                        name: res.data.name,
                        url: res.data.logo_url,
                        primaryColor: res.data.primary_color,
                        secondaryColor: res.data.secondary_color,
                        letterColor: res.data.letter_color,
                        portal_url:  res.data.portal_url,
                        currency_symbol: res.data.currency_symbol,
                        tax_id: res.data.tax_id,
                        loyalty: res.data.loyalty,
                        giftcard: res.data.giftcard,
                        polls: res.data.polls,
                        portal: res.data.portal,
                        payments: res.data.payments,
                        allow_sms: res.data.allow_sms,
                        allow_email: res.data.allow_email,
                        allow_payment_method: res.data.allow_payment_method,
                        allow_ticket_number: res.data.allow_ticket_number,
                        lot: res.data.lot,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                })
            );
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        } else {
            var image = ''
            if (this.state.base64 !== '') {
                image = this.state.base64;
            } else {
                image = this.state.url;
            }

            const object = {
                name: this.state.name,
                logo_url: image,
                primary_color: this.state.primaryColor,
                secondary_color: this.state.secondaryColor,
                letter_color: this.state.letterColor,
                portal_url: this.state.portal_url,
                currency_symbol: this.state.currency_symbol,
                lot: this.state.lot.toString(),
                loyalty: this.state.loyalty.toString(),
                giftcard: this.state.giftcard.toString(),
                polls: this.state.polls.toString(),
                portal: this.state.portal.toString(),
                payments: this.state.payments.toString(),
                tax_id: this.state.tax_id,
                allow_email: this.state.allow_email.toString(),
                allow_sms: this.state.allow_sms.toString(),
                allow_ticket_number: this.state.allow_ticket_number.toString(),
                allow_payment_method: this.state.allow_payment_method.toString(),
            };

            if (this.state.id !== 0) {
                trackPromise(
                    axios.post(`${this.context.hubURL}/stores/${this.state.id}`, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                    .then((res) => {
                        this.setState({ open: true, message: 'Empresa actualizada!' })
                    }).catch((error) => {
                        console.log(error)
                    })
                );
            } else {
                trackPromise(
                    axios.post(`${this.context.hubURL}/stores`, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                    .then((res) => {
                        this.setState({ open: true, message: 'Empresa agregada!' })
                    }).catch((error) => {
                        console.log(error)
                    })
                );
            }
        }
    }

    onChangeName(e) {
        this.setState({ name: e.target.value, someChanges: true })
    }

    onChangePrimaryColor(e) {
        this.setState({ primaryColor: e.target.value, someChanges: true })
    }

    onChangeSecondaryColor(e) {
        this.setState({ secondaryColor: e.target.value, someChanges: true })
    }

    onChangeLetterColor(e) {
        this.setState({ letterColor: e.target.value, someChanges: true })
    }

    onChangePortalURL(e) {
        this.setState({ portal_url: e.target.value, someChanges: true })
    }

    onChangeCurrencySymbol(e) {
        this.setState({ currency_symbol: e.target.value, someChanges: true })
    }

    onChangeTaxID(e) {
        this.setState({ tax_id: e.target.value, someChanges: true })
    }

    onChangeLoyalty(e) {
        let v = e ? 1 : 0
        this.setState({ loyalty: v, someChanges: true })
    }

    onChangeGiftcard(e) {
        let v = e ? 1 : 0
        this.setState({ giftcard: v, someChanges: true })
    }

    onChangeLot(e) {
        let v = e ? 1 : 0
        this.setState({ lot: v, someChanges: true })
    }

    onChangePayments(e) {
        let v = e ? 1 : 0
        this.setState({ payments: v, someChanges: true })
    }

    onChangePortal(e) {
        let v = e ? 1 : 0
        this.setState({ portal: v, someChanges: true })
    }

    onChangePolls(e) {
        let v = e ? 1 : 0
        this.setState({ polls: v, someChanges: true })
    }


    onChangeAllowSMS(e) {
        let v = e ? 1 : 0
        this.setState({ allow_sms: v, someChanges: true })
    }

    onChangeAllowEmail(e) {
        let v = e ? 1 : 0
        this.setState({ allow_email: v, someChanges: true })
    }

    onChangePaymentMethod(e) {
        let v = e ? 1 : 0
        this.setState({ allow_payment_method: v, someChanges: true })
    }

    onChangeTicketNumber(e) {
        let v = e ? 1 : 0
        this.setState({ allow_ticket_number: v, someChanges: true })
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: URL.createObjectURL(file),
                    base64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    render() {
        if (this.state.openStores) {
            return <Redirect to="/stores" />
        }

        let previewDiv, defined_class;
        if (this.state.file) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.file} alt='' /></div>
            defined_class = "col-md-9"
        } else if (this.state.url !== '') {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.url} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }
        return (
            <div className="container-fluid">
                <h1 className="mt-4">Nuevo Empresa</h1>
                <br />
    
                <form onSubmit={this.onFormSubmitted}>
                    <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputName">Nombre Empresa</label>
                            <input className="form-control" type="text" 
                            id="inputName" placeholder="Fantasia" 
                            value={this.state.name} onChange={this.onChangeName} />
                        </div>

                        <div className="form-group col-md-6">
                            <div className="row align-items-center">
                                {previewDiv}
                                <div className={defined_class}>
                                    <label htmlFor="inputFile">Logo (Max. 10MB)</label>
                                    <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label for="inputPrimaryColor">Color principal</label>
                            <input className="form-control" type="color" id="inputPrimaryColor" 
                            value={this.state.primaryColor} onChange={this.onChangePrimaryColor} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputSecondaryColor">Color Secundario</label>
                            <input className="form-control" type="color" id="inputSecondaryColor"
                            value={this.state.secondaryColor} onChange={this.onChangeSecondaryColor} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputLetterColor">Color de Letra</label>
                            <input className="form-control" type="color" id="inputLetterColor"
                            value={this.state.letterColor} onChange={this.onChangeLetterColor} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label for="inputTaxID">Tax ID (CUIT)</label>
                            <input className="form-control" type="text" id="inputTaxID" 
                            placeholder="00-00000000-0"
                            value={this.state.tax_id} onChange={this.onChangeTaxID} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputCurrencySymbol">Moneda</label>
                            <input className="form-control" type="text" id="inputCurrencySymbol" 
                            placeholder="USD"
                            value={this.state.currency_symbol} onChange={this.onChangeCurrencySymbol} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputPortalURL">URL Gestión</label>
                            <input className="form-control" type="text" id="inputPortalURL" 
                            placeholder="http://store.com/portal"
                            value={this.state.portal_url} onChange={this.onChangePortalURL} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-2 text-center">
                            <h6>Fidelidad</h6>
                            <YesNoSwitch key="loyalty" onChange={this.onChangeLoyalty} on={this.state.loyalty === 1} />
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <h6>Giftcard</h6>
                            <YesNoSwitch key="giftcard" onChange={this.onChangeGiftcard} on={this.state.giftcard === 1} />
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <h6>Sorteos</h6>
                            <YesNoSwitch key="lot" onChange={this.onChangeLot} on={this.state.lot === 1} />
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <h6>Portal</h6>
                            <YesNoSwitch key="portal" onChange={this.onChangePortal} on={this.state.portal === 1} />
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <h6>Pagos</h6>
                            <YesNoSwitch key="giftcard" onChange={this.onChangePayments} on={this.state.payments === 1} />
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <h6>Encuestas</h6>
                            <YesNoSwitch key="lot" onChange={this.onChangePolls} on={this.state.polls === 1} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-3 text-center">
                            <h6>Soporte envío de SMS</h6>
                            <YesNoSwitch key="allow_sms" onChange={this.onChangeAllowSMS} on={this.state.allow_sms === 1} />
                        </div>
                        <div className="form-group col-md-3 text-center">
                            <h6>Soporte envío de email</h6>
                            <YesNoSwitch key="allow_email" onChange={this.onChangeAllowEmail} on={this.state.allow_email === 1} />
                        </div>
                        <div className="form-group col-md-3 text-center">
                            <h6>Solicitar Número Ticket</h6>
                            <YesNoSwitch key="allow_ticket_number" onChange={this.onChangeTicketNumber} on={this.state.allow_ticket_number === 1} />
                        </div>
                        <div className="form-group col-md-3 text-center">
                            <h6>Solicitar Medio de Pago</h6>
                            <YesNoSwitch key="allow_payment_method" onChange={this.onChangePaymentMethod} on={this.state.allow_payment_method === 1} />
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Confirmar</button>
                    </div>
                </form>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.setState({openStores: true})}>
                    <Alert onClose={() => this.setState({openStores: true})} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }

}

export default NewStore