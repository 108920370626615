import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class LotParticipants extends Component {

    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            participants: [],
            error: '',
            message: '',
            open: false,
        }

        this.onRaffleLot = this.onRaffleLot.bind(this)
        this.exportData = this.exportData.bind(this)
        this.loadParticipants = this.loadParticipants.bind(this)
    }

    exportData() {
        let lotName = "";
        if(sessionStorage.getItem("lot_name") !== null) {
            lotName = sessionStorage.getItem("lot_name");
        }

        var wb = XLSX.utils.book_new();
        wb.Props = {
                Title: "Base Sorteo - " + lotName,
                Subject: "Fidely Backoffice",
                Author: "Fidely",
                CreatedDate: new Date()
        };
        
        wb.SheetNames.push("Participantes");
        var ws_data = [['Nombre', 'Apellido', 'Documento', 'Fecha Participación']];
    
        this.state.participants.map((p) => (
            ws_data.push([p.name, p.last_name, p.identification, p.date])
        ))

        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        wb.Sheets["Participantes"] = ws;

        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
        }
        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'base_sorteo.xlsx');
    }

    onRaffleLot(e) {
        e.preventDefault()

        const { match: { params } } = this.props;

        if (params.branchId !== undefined) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/lots/${params.lotId}/raffle`;

            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                    .then(res => { 
                        let error = res.data["error"];
                        if (error != undefined && error != "") {
                            alert(error);
                        } else {
                            this.loadParticipants();
                        }
                    })
                    .catch(function (error) { console.log(error); })
            );
        }
    }

    componentDidMount() {
        this.loadParticipants();
    }

    loadParticipants() {
        const { match: { params } } = this.props;

        if (params.branchId !== undefined) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/lots/${params.lotId}/participants`;
        
            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                    .then(res => { 
                        this.setState({ participants: res.data, open: false }); 
                    })
                    .catch(function (error) { console.log(error); })
            );
        }
    }

    render() {
        const { match: { params } } = this.props;

        let content, title

        let lotName = "";
        if(sessionStorage.getItem("lot_name") !== null) {
            lotName = sessionStorage.getItem("lot_name");
        }

        title = lotName + " - Participantes";

        if(this.state.participants != null) {
            content = <div className="card-body">
                <div className="row">
                    { this.state.participants.filter(p => p.won === 1).map((p) => (
                        <div class="alert alert-success" role="alert" style={{marginRight: "10px"}}>
                            <b>{p.name} {p.last_name}</b> ganó el sorteo.
                        </div>
                    ))}
                </div>
                <div className="row">
                    <table className="table table-hover">
                        <thead>
                            <tr><th scope="col">Nombre y Apellido</th>
                                <th scope="col">Tipo Cliente</th>
                                <th scope="col">Fecha participación</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.participants.map((p) => (
                            <tr>
                                <th scope="row">{p.name} {p.last_name}</th>
                                <td>{p.identification}</td>
                                <td>{p.date}</td>
                            </tr> ))}
                        </tbody>
                    </table>
                </div>
            </div>    
        } else {
            content = <p>No hay participantes en este sorteo.</p>
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-10 text-left">
                        <h3 className="h3 mb-4 text-gray-800">{title}</h3>
                    </div>
                    <div className="col-md-1 text-right">
                        <a onClick={this.onRaffleLot} className="btn btn-sm btn-danger"><span className="icon text-white-50"><i className="fas fa-chart-line"></i></span><span className="text" style={{color: "white"}}>Sortear</span></a>
                    </div>
                    <div className="col-md-1 text-right">
                        <a onClick={this.exportData} className="btn btn-secondary"><span className="icon text-white-50"><i className="fas fa-cloud-download-alt"></i></span></a>
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default LotParticipants