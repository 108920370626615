import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Category from './Category';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Categories extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            categories: null,
            message: '',
            canSetAlias: false,
            alias: '',
            someChanges: false,
            open: false,
        }

        this.reloadData = this.reloadData.bind(this)
        this.onDeleted = this.onDeleted.bind(this)
        this.onChangeAlias = this.onChangeAlias.bind(this)
        this.onSetAliasFormSubmitted = this.onSetAliasFormSubmitted.bind(this)
    }

    onChangeAlias(e) {
        this.setState({ alias: e.target.value, someChanges: true })
    }

    onDeleted() {
        this.setState({ open: true, message: 'Categoría eliminada.' });
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        if (params.branchId !== undefined) {
            let catalogpageurl = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/getcatalogpage`;
            trackPromise(
                axios.get(catalogpageurl, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then(res => {
                    if (res.data == null) {
                        this.reloadData(true, "");
                    } else {
                        this.reloadData(true, res.data["pathname"])
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    this.reloadData(true, "");
                })
            );
        } else {
            this.reloadData(false, "");
        }

        
    }

    reloadData(canSetAlias, pathname) {
        const { match: { params } } = this.props;
        let url = `${this.context.hubURL}/store/${params.storeId}/categories`;
        if (params.branchId !== undefined) {
            url = `${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/categories`;
        }

        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' } })
            .then(res => {
                this.setState({ categories: res.data["categories"], open: false, alias: pathname, canSetAlias: canSetAlias });
            })
            .catch(function (error) {
                console.log(error);
            })
        );
    }
    
    onSetAliasFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault();
        
        if (this.state.someChanges && params.branchId !== undefined) {
            const object = {
                pathname: this.state.alias,
                storeId: params.storeId,
                branchId: params.branchId,
            };

            trackPromise(
                axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}/catalogpage`, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then((res) => {
                    this.setState({ open: true, message: 'Alias actualizado!', someChanges: false })
                }).catch((error) => {
                    console.log(error)
                })
            );
        }
    }

    render() {
        const { match: { params } } = this.props;
        
        let setAlias = <div className="card shadow mb-4">
            <div className="card-header py-3">
                <div className="row">
                    <h6 className="m-0 font-weight-bold text-primary">URL de Acceso</h6>
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={this.onSetAliasFormSubmitted}>
                    <div className="form-row align-items-center">
                    https://{window.location.hostname}/catalogo/
                    <div className="form-group col-md-8">
                        <input className="form-control" type="text" id="inputAlias" value={this.state.alias}
                            placeholder="marca-sucursal" onChange={this.onChangeAlias} />
                        </div>

                        <div className="form-group col-md-2 text-center">
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        if (this.state.canSetAlias === false) {
            setAlias = ""
        }

        let content
        let newURL = `/stores/${params.storeId}/categories/new`
        if (params.branchId !== undefined) {
            newURL = `/stores/${params.storeId}/branches/${params.branchId}/categories/new`
        }
        
        if (this.state.categories == null) {
            content = <p>No hay categorías creadas.</p>
        } else {
            content = <div className="card-columns">
            { this.state.categories.map((category) => (<Category key={category.id} category={category} onDeleted={this.onDeleted} />)) }
            </div>
        }

        return(
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">Categorías</h6>
                            </div>
                            <div className="col text-right my-auto">
                                <Link to={newURL} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                            </div>
                        </div>
                    </div> 
                    <div className="card-body">
                        {setAlias}
                        {content}
                    </div>
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount() }>
                    <Alert onClose={() => this.componentDidMount() } severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default Categories