
class DaysOfMonth {
  GetDaysOfMonth() {
    let daysOfMonth = []
    for (let i = 1; i <= 31; i++) {
        daysOfMonth.push(i)
    }
    return daysOfMonth
  }
}

export default DaysOfMonth