import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

class CardBranch extends Component {
    static contextType = SessionContext
    
    constructor() { 
        super()

        this.state = {
          urlToRedirect: null
        };

        this.onDeleted = this.onDeleted.bind(this)
        this.handleQRClick = this.handleQRClick.bind(this);
    }

handleQRClick() {
  this.props.onQRBranchSelected(this.props.branch);
}

  onDeleted() {
    trackPromise(
      axios.post(`${this.context.hubURL}/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/delete`, null, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          }
      })
      .then((res) => {
          this.props.onDeleted()
      }).catch((error) => {
          console.log(error)
      })
    );
  }

  customRedirect(urlToRedirect) {
    sessionStorage.setItem("branch_name", this.props.branch.name);
    this.setState({ urlToRedirect: urlToRedirect });
  }

  render() {
    if (this.state.urlToRedirect !== null) {
      return <Redirect to={this.state.urlToRedirect} />
    }

    let classInfo = "col-sm-6"
    let divImage = ""
    if (this.props.branch.logo_url != null && this.props.branch.logo_url != "") {
      classInfo = "col-sm-4"
      divImage = <div class="col-sm-2">
      <div className="view overlay">
        <img className="card-img-top" src={`${this.props.branch.logo_url}`} alt={this.props.branch.name} style={{height: "100%", width: "100%", objectFit: "cover"}} />
      </div>
    </div>
    }

    return (
        <div class="row no-gutters">
        <div className="card shadow" style={{marginBottom: "16px", width: "100%"}}>
            <div className="card-header">
                <div className="row">
                <div className="col-10 text-left my-auto">
                    <h6 className="m-0 font-weight-bold text-primary">{this.props.branch.name}</h6>
                </div>
                <div className="col-1 text-right">
                <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/edit`)} className="btn btn-sm btn-primary"><span className="icon text-white-50"><i className="fas fa-pencil-alt"></i></span></a>
                </div>
                <div className="col-1 text-left">
                <a onClick={this.onDeleted} className="btn btn-sm btn-danger"><span className="icon text-white-50"><i className="fas fa-trash"></i></span></a>
                </div>
                </div>
            </div>
            <div class="card-body">
                <div className="row">
                    {divImage}
                    <div class={classInfo}>
                        <p className="mb-1"><small>• Dirección: {this.props.branch.address}</small></p>
                        <p className="mb-1"><small>• Telefono: {this.props.branch.phone}</small></p>
                        <p className="mb-1"><small>• Email: {this.props.branch.email}</small></p>
                    </div>
                    <div className="col-sm-2">
                            <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/home`)} className="btn btn-sm btn-block btn-danger btn-icon-split"><span className="icon text-white-50"><i className="fas fa-chart-line"></i></span><span className="text" style={{color: "white"}}>Pedidos</span></a>
                            <br />
                            <br />
                            <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/sales`)} className="btn btn-sm btn-block btn-secondary btn-icon-split"><span className="icon text-white-50"><i className="fas fa-percent"></i></span><span className="text" style={{color: "white"}}>Promociones</span></a>
                            <br />
                            <br />
                            <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/transactions`)} className="btn btn-sm btn-block btn-primary btn-icon-split"><span className="icon text-white-50"><i className="fas fa-credit-card"></i></span><span className="text" style={{color: "white"}}>Transacciones</span></a>
                            <br />
                            <br />
                            <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/client_types`)} className="btn btn-sm btn-block btn-primary btn-icon-split"><span className="icon text-white-50"><i className="fas fa-users"></i></span><span className="text" style={{color: "white"}}>Tipos de Clientes</span></a>
                        </div>
                        <div className="col-sm-2">
                            <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/pos`)} className="btn btn-sm btn-block btn-info btn-icon-split"><span className="icon text-white-50"><i className="fas fa-mobile"></i></span><span className="text" style={{color: "white"}}>POS</span></a>
                            <br />
                            <br />
                            <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/users`)} className="btn btn-sm btn-block btn-warning btn-icon-split"><span className="icon text-white-50"><i className="fas fa-user"></i></span><span className="text" style={{color: "white"}}>Usuarios</span></a>
                            <br />
                            <br />
                            <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/categories`)} className="btn btn-sm btn-block btn-success btn-icon-split"><span className="icon text-white-50"><i className="fas fa-shopping-cart"></i></span><span className="text" style={{color: "white"}}>Catálogo</span></a>
                            <br />
                            <br />
                            <a onClick={this.handleQRClick} className="btn btn-sm btn-block btn-success btn-icon-split"><span className="icon text-white-50"><i className="fas fa-qrcode"></i></span><span className="text" style={{color: "white"}}>Código QR</span></a>
                        </div>
                        <div className="col-sm-2">
                          <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/lots`)} className="btn btn-sm btn-block btn-dark btn-icon-split"><span className="icon text-white-50"><i className="fas fa-ticket"></i></span><span className="text" style={{color: "white"}}>Sorteos</span></a>
                          <br />
                          <br />
                          <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/polls`)}  className="btn btn-sm btn-block btn-info btn-icon-split"><span className="icon text-white-50"><i className="fas fa-smile-o"></i></span><span className="text" style={{color: "white"}}>Encuestas</span></a>
                          <br />
                          <br />
                          <a onClick={this.customRedirect.bind(this, `/stores/${this.props.branch.store_id}/branches/${this.props.branch.id}/cupon_checks`)}  className="btn btn-sm btn-block btn-info btn-icon-split"><span className="icon text-white-50"><i className="fas fa-smile-o"></i></span><span className="text" style={{color: "white"}}>Cupon Check</span></a>
                      </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}
export default CardBranch;