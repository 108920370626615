import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Login extends Component {

    constructor() {
        super()
        this.state = {
            username: '',
            password: '',
        }

        this.onLoginButtonClicked = this.onLoginButtonClicked.bind(this)
        this.onUsernameChanged = this.onUsernameChanged.bind(this)
        this.onPasswordChanged = this.onPasswordChanged.bind(this)
    }

    componentWillMount() {
      sessionStorage.removeItem("store_name");
      sessionStorage.removeItem("branch_name");
      sessionStorage.removeItem("lot_name");
      sessionStorage.removeItem("cupon_check_name");
      sessionStorage.removeItem("pos_name");
    }

    onLoginButtonClicked(e) {
        e.preventDefault()

        const object = {
            username: this.state.username,
            password: this.state.password,
        };
        trackPromise(
          axios.post(`${this.props.hubURL}/backoffice/login`, object, {
              headers: {
                'Access-Control-Allow-Origin': '*',
              }
          })
          .then((res) => {
              if (res.data != null && res.data["user"] != null) {
                  this.props.onLoggedUser(res.data["user"])
              } else {
                this.setState({ open: true, message: 'Usuario o clave incorrectas.', username: '', password: '', })
              }
          }).catch((error) => {
              console.log(error)
          })
        );
    }

    onUsernameChanged(e) {
        this.setState({username: e.target.value})
    }

    onPasswordChanged(e) {
        this.setState({password: e.target.value})
    }

    render() {
        return (
            <div className="row justify-content-center">

            <div className="col-5">
      
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">Fidely Backoffice</h1>
                        </div>
                        <form className="user">
                          <div className="form-group">
                            <input className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Usuario" value={this.state.username} onChange={this.onUsernameChanged} />
                          </div>
                          <div className="form-group">
                            <input type="password" className="form-control form-control-user" id="exampleInputPassword" placeholder="Clave" value={this.state.password} onChange={this.onPasswordChanged} />
                          </div>
                          <button className="btn btn-primary btn-user btn-block" type="submit" onClick={this.onLoginButtonClicked}>Ingresar</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      
            </div>
      
              <Snackbar open={this.state.open}>
                <Alert severity="error">
                  {this.state.message}
                </Alert>
              </Snackbar>

          </div>
        );
    }
}
export default Login;