import React, { Component } from 'react';
import AccordionItem from '../AccordionItem';

class QuestionsAnswers extends Component {

    constructor() {
        super()
        this.state = {
            items: null
        }
    }

    componentDidMount() {
        if (this.props.items === null) {
            this.setState({ items: null });
        } else {
            var is = [];
            this.props.items.forEach( function(item, i, _) {

                var answersCount = 0
                
                var counts = new Map();
                
                if(item.answers === null) {
                    is[i] = {
                        question: item.question,
                        body: "No hay respuestas de esta pregunta."
                    }
                } else {
                    var details = "<p><b>Detalles de respuestas:</b> "
                    item.answers.forEach( function(as, j, _) {
                        var prefix = ", "
                        if (j == 0) prefix = ""
                        details += prefix + as.value
                        
                        answersCount += 1

                        counts.set(as.value, counts.get(as.value) ? counts.get(as.value) + 1 : 1);
                    });
                    details += "</p>"
                    

                    var mostPopular = "";
                    var maxAns = 0;
                    counts.forEach(function(value, key, map) {
                        if (value > maxAns) {
                            maxAns = value
                            mostPopular = key
                        }
                    });

                    var answersBody = "<p><b>Cantidad de respuestas:</b>" + answersCount + "</p>"
                    answersBody += "<p><b>Respuesta más popular:</b>" + mostPopular + " (" + maxAns + " respuestas)" + "</p>"
                    answersBody += "<p><b>Fecha con más respuestas:</b> </p>"
                    answersBody += details

                    is[i] = {
                        question: item.question,
                        body: answersBody
                    }    
                }

                
            });

            this.setState({ items: is });
        }
    }
    
    render() {
        if (this.state.items === null) {
            return (<div>Esta encuesta no tiene preguntas asociadas.</div>)
        } else {
            var parentId = "#" + this.props.id;
            return (<div class="accordion" id={this.props.id}>
                { this.state.items.map((item) => (
                    <AccordionItem id={item.question.id} title={item.question.title} body={item.body} parentId={parentId} />)
                ) }
            </div>);
        }
    }

}

export default QuestionsAnswers;