import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewProduct extends Component {
    static contextType = SessionContext
    
    constructor() {
        super()
        this.state = {
            id: 0,
            name: '',
            file: '',
            base64: '',
            description: '',
            price: '',
            url: '',
            featured: false,
            available: true,
            open: false,
            someChanges: false,
            message: '',
            data: null,
            category: null
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        this.onChangeDescription = this.onChangeDescription.bind(this)
        this.onChangePrice = this.onChangePrice.bind(this)
        this.onChangeAvailable = this.onChangeAvailable.bind(this)
        this.onChangeFeatured = this.onChangeFeatured.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.storeId !== undefined && params.categoryId !== undefined) {
            let url = `${this.context.hubURL}/stores/${params.storeId}`
            
            if (params.branchId !== undefined) {
                url = `${url}/branches/${params.branchId}`
            }

            if (params.categoryId !== undefined) {
                url = `${url}/categories/${params.categoryId}`
            }

            if (params.productId !== undefined && params.productId !== "new") {
                url = `${url}/products/${params.productId}`
            }

            trackPromise(
                axios.get(url, {
                    headers: { 'Access-Control-Allow-Origin': '*' }
                })
                .then(res => {
                    
                    let id = 0
                    let name = ''
                    let description = ''
                    let price = ''
                    let url = ''
                    let featured = false
                    let available = false

                    if (res.data.product !== undefined) {
                        id = res.data.product.id
                        name = res.data.product.name
                        description = res.data.product.description
                        price = res.data.product.price
                        featured = res.data.product.featured === 1
                        available = res.data.product.available === 1
                        url = res.data.product.image_path
                    }

                    this.setState({ 
                        data: res.data, 
                        category: res.data.category,
                        id: id,
                        name: name,
                        description: description,
                        price: price,
                        url: url,
                        featured: featured,
                        available: available,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                })
            );
        }
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: URL.createObjectURL(file),
                    base64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        } else {
            var image = ''
            if (this.state.base64 !== '') {
                image = this.state.base64;
            } else {
                image = this.state.url;
            }

            var featured, available

            if (this.state.featured) {
                featured = "1"
            } else {
                featured = "0"
            }

            if (this.state.available) {
                available = "1"
            } else {
                available = "0"
            }

            const object = {
                name: this.state.name,
                image_path: image,
                description: this.state.description,
                price: this.state.price + "",
                featured: featured,
                available: available
            };

            let message = 'Producto agregado!';
            let url = `${this.context.hubURL}/stores/${params.storeId}`
            
            if (params.branchId !== undefined) {
                url = `${url}/branches/${params.branchId}`
            }

            if (params.categoryId !== undefined) {
                url = `${url}/categories/${params.categoryId}`
            }

            url = `${url}/products`

            if (this.state.id !== 0) {
                message = 'Producto actualizado!';
                url = url = `${url}/${this.state.id}`;
            }

            trackPromise(
                axios.post(url, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then((res) => {
                    this.setState({ open: true, message: message })
                }).catch((error) => {
                    console.log(error)
                })
            );
        }
    }

    onChangeName(e) {
        this.setState({ name: e.target.value, someChanges: true })
    }
    
    onChangeDescription(e) {
        this.setState({ description: e.target.value, someChanges: true })
    }
    
    onChangePrice(e) {
        let number = e.target.value.replace('.', '');
        let parts = number.split(",");

        if(/^[0-9]*$/.test(parts[0])) {
            if (parts.length === 2) {
                if (/^[0-9]*$/.test(parts[1])) {
                    this.setState({ price: parts[0] + "," + parts[1].substring(0,2), someChanges: true })
                } else {
                    this.setState({ price: '' });
                }
            } else if (parts.length === 1) {
                this.setState({ price: parts[0], someChanges: true })
            } else {
                this.setState({ price: '' });
            }
        } else {
            this.setState({ price: '' });
        }
    }

    onChangeFeatured(e) {
        this.setState({ featured: e.target.checked, someChanges: true })
    }

    onChangeAvailable(e) {
        this.setState({ available: e.target.checked, someChanges: true })
    }

    render() {
        if (this.state.data == null) {
            return ("Cargando datos...")
        }

        let previewDiv, defined_class;
        if (this.state.file) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.file} alt='' /></div>
            defined_class = "col-md-9"
        } else if (this.state.url !== '') {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.url} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }

        return (
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-2">
                                <img src={`${this.state.category.image_path}`} alt="Categoría" className="card-img" style={{height: "100%", width: "100%", objectFit: "cover"}} />
                            </div>
                            <div className="col text-left my-auto">
                                <h6 className="m-0 font-weight-bold text-primary">{this.state.category.name} - Nuevo Producto</h6>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className="form-group col-md-6">
                                    <label for="inputName">Nombre Producto</label>
                                    <input className="form-control" type="text" 
                                    id="inputName" placeholder="Nombre producto" 
                                    value={this.state.name} onChange={this.onChangeName} />
                                </div>

                                <div className="form-group col-md-6">
                                    <div className="row align-items-center">
                                        {previewDiv}
                                        <div className={defined_class}>
                                            <label for="inputFile">Imágen (Max. 10MB)</label>
                                            <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label for="inputDescription">Descripción</label>
                                <input className="form-control" type="text" 
                                id="inputDescription" placeholder="Descripción" 
                                value={this.state.description} onChange={this.onChangeDescription} />
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label for="inputPrice">Precio</label>
                                    <input className="form-control" type="text" 
                                    id="inputPrice" placeholder="Precio" 
                                    value={this.state.price} onChange={this.onChangePrice} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="inlineAvailableCheckbox" checked={this.state.available} onClick={this.onChangeAvailable} />
                                    <label className="custom-control-label" for="inlineAvailableCheckbox">Disponible</label>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="inlineFeaturedCheckbox" checked={this.state.featured} onClick={this.onChangeFeatured} />
                                    <label className="custom-control-label" for="inlineFeaturedCheckbox">Destacado</label>
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Confirmar</button>
                            </div>
                        </form>
                    </div>
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.props.history.go(-1)}>
                    <Alert onClose={() => this.props.history.go(-1)} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }

}

export default NewProduct